import { Layer, Source } from 'react-map-gl';
import { SATELLITE_STATIC_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity } from 'utils';

export const MapboxSatelliteSource = () => {
  const mapTypes = useAppSelector(typesSelector);

  const showMapbox = mapTypes.mapboxSatelliteUpper.active;

  if (!showMapbox) {
    return null;
  }

  return (
    <Source url="mapbox://mapbox.satellite" type="raster">
      <Layer
        type="raster"
        beforeId={SATELLITE_STATIC_LAYER}
        paint={{
          'raster-opacity': getProperOpacity(mapTypes.mapboxSatelliteUpper),
        }}
      />
    </Source>
  );
};
