import { FC, useCallback, useState } from 'react';
import cn from 'classnames';
import { errorMessages } from 'constants/errors';
import { DEFAULT_FLY_TO_SETTINGS } from 'constants/map';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { useGeocoder } from 'hooks/useGeocoder';
import { ICoordinates, Option } from 'interfaces';
import { GeocoderFeature } from 'interfaces/geocoder';
import {
  centerCoordsSelector,
  coordsModeSelector,
} from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { geocoderActions } from 'store/slices/mapV2/mapReducer/toolsReducer/geocoderSlice';
import { CoordinateSystems } from 'types';
import { useDebouncedCallback } from 'use-debounce';

import { Autocomplete, AutocompleteProps } from 'components/ui/Autocomplete';
import { lngLatFromCoords, notify } from 'utils';

import { getOptionsFromGeocoderGeoJSONResponse } from './utils/common';
import {
  getCoordinatesOptions,
  getPossibleCoordinates,
} from './utils/coordinates';
import { getPossibleSquares, getSquareOptions } from './utils/square';
import { GeocoderOption } from './GeocoderOption';
import { PossibleCoordinatesOption, PossibleSquareOption } from './types';

type GeocoderProps = Pick<
  AutocompleteProps,
  'placeholder' | 'dropdownPosition'
> & {
  classNames?: { container?: string };
};

export const Geocoder: FC<GeocoderProps> = ({
  placeholder,
  dropdownPosition,
  classNames,
}) => {
  const { mapRef } = useMapRef();
  const centerCoords = useAppSelector(centerCoordsSelector);
  const coordsMode = useAppSelector(coordsModeSelector);
  const geocoder = useGeocoder();
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<Option<GeocoderFeature>[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const showEmptyDataPlaceholder = !!(
    !isLoading &&
    isFocused &&
    query &&
    !options.length
  );

  const handleGeocodeCoordinates = useCallback(
    async (
      query: string,
      centerCoords: ICoordinates,
      coordsMode: CoordinateSystems
    ): Promise<PossibleCoordinatesOption[]> => {
      try {
        const possibleCoordinates = getPossibleCoordinates(query);

        const isCoordinatesQuery = !!possibleCoordinates.length;

        if (isCoordinatesQuery) {
          const coordinatesOptions = await getCoordinatesOptions(
            possibleCoordinates,
            centerCoords,
            coordsMode
          );

          return coordinatesOptions;
        }

        return [];
      } catch (e) {
        return [];
      }
    },
    []
  );

  const handleGeocodeSquare = useCallback(
    async (
      query: string,
      centerCoords: ICoordinates,
      coordsMode: CoordinateSystems
    ): Promise<PossibleSquareOption[]> => {
      try {
        const possibleSquares = getPossibleSquares(query);

        const isSquareQuery = !!possibleSquares.length;

        if (isSquareQuery) {
          const squareOptions = await getSquareOptions(
            possibleSquares,
            centerCoords,
            coordsMode
          );

          return squareOptions;
        }

        return [];
      } catch (e) {
        return [];
      }
    },
    []
  );

  const handleGeocode = useCallback(
    async (
      query: string,
      centerCoords: ICoordinates,
      coordsMode: CoordinateSystems
    ) => {
      try {
        const coordinatesOptions = await handleGeocodeCoordinates(
          query,
          centerCoords,
          coordsMode
        );

        const squareOptions = await handleGeocodeSquare(
          query,
          centerCoords,
          coordsMode
        );

        const isCoordinatesQuery = !!coordinatesOptions.length;
        const isSquareQuery = !!squareOptions.length;

        if (isCoordinatesQuery || isSquareQuery) {
          setOptions([...coordinatesOptions, ...squareOptions]);
        } else {
          const { data } = await geocoder.forwardGeocode({
            q: query,
          });

          setOptions(getOptionsFromGeocoderGeoJSONResponse(data));
        }
      } catch (e) {
        console.error(e);
        notify.error(errorMessages.GEOCODER_ERROR);
      } finally {
        setIsLoading(false);
      }
    },
    [handleGeocodeCoordinates]
  );

  const handleGeocodeDebounced = useDebouncedCallback(handleGeocode, 500);

  const handleMoveToGeocodeFeature = (result: GeocoderFeature) => {
    setQuery('');
    setOptions([]);
    dispatch(geocoderActions.setGeocoderResult(result));

    mapRef.current?.flyTo({
      ...DEFAULT_FLY_TO_SETTINGS,
      center: result
        ? lngLatFromCoords(result.geometry.coordinates as [number, number])
        : undefined,
      zoom: mapRef.current.getZoom(),
    });
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleEnterPress = () => {
    const firstGeocodeFeature = options[0];

    if (firstGeocodeFeature) {
      handleMoveToGeocodeFeature(firstGeocodeFeature.value);
    }
  };

  const handleChange = async (value: string) => {
    setQuery(value);
    setIsLoading(true);
    handleGeocodeDebounced(value, centerCoords, coordsMode);
  };

  const handleOptionClick = (option: Option<GeocoderFeature>) =>
    handleMoveToGeocodeFeature(option.value);

  return (
    <div className={cn('relative w-full', classNames?.container)}>
      <Autocomplete<GeocoderFeature>
        value={query}
        placeholder={placeholder}
        options={options}
        dropdownPosition={dropdownPosition}
        searchIcon
        resetIcon
        dropdownOption={GeocoderOption}
        inputClassNames={{
          iconButton: '!w-5 !h-5 !min-h-[unset] [&>svg>path]:fill-tpg_base',
        }}
        dropdownClassnames={{ container: '!max-h-[unset]' }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onEnter={handleEnterPress}
        onChange={handleChange}
        onOptionClick={handleOptionClick}
      />
      {showEmptyDataPlaceholder && (
        <div
          className="absolute w-full px-3 py-[6px] rounded bg-ultrablack"
          style={{ [dropdownPosition === 'top' ? 'bottom' : 'top']: '100%' }}
        >
          <p className="text-tpg_base text-center text-[16px] font-['Proxima_Nova']">
            Данные не найдены
          </p>
        </div>
      )}
    </div>
  );
};
