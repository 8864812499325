import { Layer, Source } from 'react-map-gl';
import { elevationLayerConfig } from 'configs/map/common';
import { ELEVATION_STATIC_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { FillLayerSpecification, LineLayerSpecification } from 'mapbox-gl';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity } from 'utils';

export const ElevationSource = () => {
  const mapTypes = useAppSelector(typesSelector);

  const showElevation = mapTypes.elevation.active;

  const getLayerPaint = (item: (typeof elevationLayerConfig)[0]) => {
    switch (item.type) {
      case 'fill':
        return {
          'fill-opacity': getProperOpacity(mapTypes.elevation),
          ...item.paint,
        };
      case 'line':
        return {
          'line-opacity': getProperOpacity(mapTypes.elevation),
          ...item.paint,
        };
      default:
        return undefined;
    }
  };

  if (!showElevation) {
    return null;
  }

  return (
    <Source
      type="vector"
      id="mapbox-terrain"
      url="mapbox://mapbox.mapbox-terrain-v2"
    >
      {elevationLayerConfig.map((item, index) => {
        const paint = getLayerPaint(item);
        const layer: FillLayerSpecification | LineLayerSpecification = {
          ...item,
          type: item.type as 'fill' | 'line',
          paint: paint,
        };

        return (
          <Layer key={index} beforeId={ELEVATION_STATIC_LAYER} {...layer} />
        );
      })}
    </Source>
  );
};
