import { FC } from 'react';
import cn from 'classnames';

interface ParagraphedTextProps {
  text: string;
}

export const ParagraphedText: FC<ParagraphedTextProps> = ({ text }) => {
  const renderTextLine = (textLine: string, idx: number) => (
    <div
      key={idx}
      className={cn('p-0 pb-4 tpg-c1 text-tpg_title last:pb-0')}
      style={{ lineHeight: '20px' }}
    >
      {textLine}
    </div>
  );

  return (
    <>
      {text.split('\n').map((descLine, idx) => renderTextLine(descLine, idx))}
    </>
  );
};
