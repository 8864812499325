import { LineString, Point, Polygon } from '@turf/turf';
import { FeatureTypes } from 'constants/map';
import type {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  GeometryCollection,
} from 'geojson';
import { IGeospoofEntity } from 'interfaces';
import { GeoJSONFeature, LayerSpecification } from 'mapbox-gl';
import { ValueOf } from 'types';

import { EntityWithRelations } from './entities';

export type LayerPartialSpecification<T extends LayerSpecification> = Omit<
  T,
  'id' | 'source'
>;

export type TGeometry = Exclude<Geometry, GeometryCollection>;

export type CustomGeoJSONFeature<
  G extends Geometry = any,
  P extends GeoJsonProperties = any
> = Feature<G, P> & GeoJSONFeature;

export type GeoJSONCompletedFeature<P extends GeoJsonProperties = any> =
  CustomGeoJSONFeature<Geometry, P & { geometry: string }>;

/*
 * duplicate geometry because coordinates may be broken due to map.queryRenderedFeatures tile processing
 * non primitive fields of feature.properties transfrom to JSON in map.queryRenderedFeatures
 */
export type GeoJSONCompletedFeatureProperties = {
  geometry: string;
} & GeoJsonProperties;

export type TPosition = [number, number];

export type TGeospoofTabTitleUnion = 'Пользователи' | 'Группы' | 'История';

export type TGeospoofObjectKeysUnion = 'users' | 'groups' | 'history';

export type TGlobalFiltersUnion =
  | 'type'
  | 'object'
  | 'date'
  | 'createdAt'
  | 'status'
  | 'report'
  | 'client';

export type TDeleteModeUnion = 'object' | 'media';

export type TMapMediaPreviewUnion = 'features' | 'geospoof';

export type TMediaTypeUnion = 'video' | 'image';

export type TFeatureTypes = ValueOf<FeatureTypes>;

export type TCoordinatesPart = number | string | undefined;

export type TCoordinatesArray = Readonly<[TCoordinatesPart, TCoordinatesPart]>;

export type TCoordinatesObjectLatitude = {
  lat?: TCoordinatesPart;
  latitude?: TCoordinatesPart;
};

export type TCoordinatesObjectLongitude = {
  lng?: TCoordinatesPart;
  lon?: TCoordinatesPart;
  long?: TCoordinatesPart;
  longitude?: TCoordinatesPart;
};

export type TCoordinatesObject = TCoordinatesObjectLatitude &
  TCoordinatesObjectLongitude & {
    [key: string]: TCoordinatesPart;
  };

export type TUserLocationHistory = Pick<
  IGeospoofEntity,
  | 'coordinates'
  | 'machine'
  | 'user'
  | 'timestamp'
  | 'request_failed'
  | 'request_id'
> & {
  requested_user: IGeospoofEntity;
};

export type TFeatureSortUnion =
  | 'object_name'
  | 'object_type'
  | 'date'
  | 'created_at'
  | 'report';

export type TUsersSortUnion =
  | 'user'
  | 'id'
  | 'phone'
  | 'date'
  | 'created_at'
  | 'report';

export type TSortDirectionUnion = 'asc' | 'desc';

export type TAccessTypeUnion =
  | 'private'
  | 'public_readonly'
  | 'public_readwrite';

export type TAccessModeUnion = 'readonly' | 'readwrite';

export enum GeometryTypes {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
}

export enum CoordinateSystems {
  WGS = 'wgs',
  MGRS = 'mgrs',
  SK42 = 'sk42',
  USK2000 = 'usk2000',
}

export enum MeasureSystems {
  METERS = 'meters',
  KILOMETERS = 'kilometers',
}

export enum MapEntityTypes {
  OBJECT = 'object',
  LAYER = 'layer',
}

/* 
  Prevents unnecessary API calls by make sure how entity children were received before.
  Assume "full" is max available value and "partial" any different.
  "none" is when entity children were not received additionally before. 
*/
export enum EntityStorages {
  NONE = 'none',
  PARTIAL = 'partial',
  FULL = 'full',
}

export interface MapEntityInfo {
  type: MapEntityTypes;
}

export interface EntityState {
  storage: EntityStorages;
  draft?: boolean;
  active: boolean;
}

export interface MapEntity extends EntityWithRelations {
  info: MapEntityInfo;
  state: EntityState;
}

export type MapObject = {
  id: string;
  name: string;
  type?: string | null;
  status?: string | null;
  color?: string | null;
  opacity?: number | null;
  geometry: Point | LineString | Polygon;
};

export interface MapFeatureProperties {
  title: string;
  width: number;
  type?: string;
  lineColor?: string;
  fillColor?: string;
  opacity?: number;
}

export type MapFeaturePoint = Feature<Point, MapFeatureProperties>;
export type MapFeatureLine = Feature<LineString, MapFeatureProperties>;
export type MapFeaturePolygon = Feature<Polygon, MapFeatureProperties>;
export type MapFeature = MapFeaturePoint | MapFeatureLine | MapFeaturePolygon;

export enum ContextMenuTypes {
  DEFAULT = 'default',
  BOUNDARY_GRID = 'boundary_grid',
  IMAGERY = 'imagery',
}

export interface ContextMenu {
  type: ContextMenuTypes;
  longitude: number;
  latitude: number;
  relatedFeatureId?: string | number;
  props: Record<string, unknown>;
}

export type PointClusterGeoJSON = CustomGeoJSONFeature<
  Point,
  PointClusterProperties
>;

export type PointClusterProperties = {
  cluster: boolean;
  cluster_id: number;
  label: string;
  point_count: number;
  point_count_abbreviated: number;
};

export type BoundaryGridCellGeoJSON = CustomGeoJSONFeature<
  Geometry,
  BoundaryGridCellProperties
>;

export type ImageryBoundaryGeoJSON =
  GeoJSONCompletedFeature<ImageryBoundaryProperties>;

export interface ImageryBoundaryProperties {
  id: string | number;
  relatedFeatureId: string | number;
}

export type BoundaryGrid = FeatureCollection<
  Polygon,
  BoundaryGridCellProperties
> &
  BoundaryGridProperties;

export interface BoundaryGridProperties {
  relatedFeatureId: string | number;
  visible: boolean;
  showLabels: boolean;
}

export type BoundaryGridCell = Feature<Polygon, BoundaryGridCellProperties>;

export interface BoundaryGridCellProperties {
  id: string | number;
  relatedFeatureId: string | number;
  index: number;
  size: number;
  area: number;
  label: string;
  showLabel: boolean;
  filled: boolean;
}

export type CameraBounds = [number, number, number, number];
