import { IConverterResponse, ICoordinates } from 'interfaces';
import axios, { baseURL } from 'services/axios';
import { CoordinateSystems } from 'types';

import { handleAxiosResponse } from './apiUtils';

export function convertCoordinates<T = IConverterResponse>(
  from: CoordinateSystems,
  to: CoordinateSystems,
  payload: ICoordinates
) {
  return handleAxiosResponse<T>(
    axios.post(
      'utils-api/convert',
      {
        from,
        to,
        payload,
      },
      { baseURL }
    )
  );
}
