import { FC, ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import cn from 'classnames';
import { useEscapeDownControlled } from 'hooks/useEscapeDown';
import Tooltip from 'rc-tooltip';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';

import './style.scss';

export interface ClosableTooltipProps extends TooltipProps {
  overlay: ReactNode;
  keyboard?: boolean;
}

const ClosableTooltip: FC<ClosableTooltipProps> = ({
  overlay,
  keyboard,
  overlayClassName,
  children,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  const onEscapeDown = useCallback(() => setVisible(false), []);
  const [subscribeToEscapeDown, unsubscribeFromEscapeDown] =
    useEscapeDownControlled(onEscapeDown);

  const onVisibleChange = (isVisible: boolean) => {
    setVisible(isVisible);

    if (keyboard) {
      isVisible ? subscribeToEscapeDown() : unsubscribeFromEscapeDown();
    }
  };

  const handleOverlayClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setVisible(false);
  };

  return (
    <Tooltip
      visible={visible}
      onVisibleChange={onVisibleChange}
      showArrow={false}
      placement={'bottom'}
      overlayClassName={cn('astra-dropdown', overlayClassName)}
      overlay={<div onClick={handleOverlayClick}>{overlay}</div>}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default ClosableTooltip;
