import { Ammunitions } from 'constants/ammunition';
import {
  CREATE_DRAW_FEATURE_MODAL,
  MAP_TOOLS_INFO_POPUP,
} from 'constants/modals';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapDraw } from 'hooks/useMapDraw';
import { ReactComponent as ArtilleryIcon } from 'images/newIcons/artillery.svg';
import { ReactComponent as PenToolIcon } from 'images/newIcons/penTool.svg';
import { ReactComponent as PolygonIcon } from 'images/newIcons/polygon.svg';
import { ReactComponent as RadiusIcon } from 'images/newIcons/radius.svg';
import { showCustomCursorsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { drawActions } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice';
import { measurementActions } from 'store/slices/mapV2/mapReducer/toolsReducer/measurementSlice';
import { measurementSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/measurementSlice/selectors';
import { stateActions } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice';
import { drawToolSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice/selectors';
import { modalsActions } from 'store/slices/service/modalsSlice';
import {
  DrawEventActions,
  MapCreateTools,
  MapDrawModes,
  MapDrawTool,
  MapMeasureTools,
} from 'types';

import { getListOfRangeInMeters, isMobileDevice } from 'utils';

import { DropdownControl } from './DropdownControl';
import { ToggleControl } from './ToggleControl';

const ARTILLERY_OPTIONS = Ammunitions.map((ammunition) => ({
  label: ammunition.name,
  value: ammunition.name,
}));

const TOGGLE_CLASSNAME = 'w-5 h-5 [&_svg]:w-full [&_svg]:h-full';

export const MapToolsControl = () => {
  // temporary hide create_circle for mobile devices
  const isMobile = isMobileDevice();
  const showCustomCursors = useAppSelector(showCustomCursorsSelector);
  const drawTool = useAppSelector(drawToolSelector);
  const measurement = useAppSelector(measurementSelector);
  const {
    handleDrawClear,
    handleChangeDrawMode,
    handleDrawStop,
    handleDrawCancel,
  } = useMapDraw();

  const dispatch = useAppDispatch();

  const handleControlToggle = (
    prevMode: MapDrawTool,
    currentMode: MapDrawTool
  ) => {
    handleDrawClear();

    const isControlActive = currentMode === prevMode;
    const mode = isControlActive ? MapDrawModes.simple_select : currentMode;
    const action = isControlActive ? DrawEventActions.DRAW_CANCEL : undefined;
    const isCreateMode = !!MapCreateTools[mode as MapCreateTools];
    const isMeasuremode = !!MapMeasureTools[mode as MapMeasureTools];

    handleChangeDrawMode(mode, undefined, action);

    dispatch(stateActions.setDrawTool(mode));

    if (isControlActive) {
      dispatch(modalsActions.removeModal(CREATE_DRAW_FEATURE_MODAL));
    }

    if (isCreateMode) {
      dispatch(
        modalsActions.addModal({ id: MAP_TOOLS_INFO_POPUP, isOpen: true })
      );
      dispatch(drawActions.setDrawFeature(null));
    }

    if (isMeasuremode) {
      dispatch(measurementActions.setMeasurement(null));
      dispatch(modalsActions.removeModal(MAP_TOOLS_INFO_POPUP));
    }
  };

  const handleArtilleryChange = (changedArtillery: string | null) => {
    const artillery = Ammunitions.find(
      (ammo) => ammo.name === changedArtillery
    );

    if (!artillery) {
      return;
    }

    const measurementValue = {
      name: artillery.name,
      range: getListOfRangeInMeters(artillery.bulletRange),
      position:
        measurement?.type === MapMeasureTools.measure_artillery
          ? measurement.value.position
          : [0, 0],
    };

    dispatch(
      measurementActions.setMeasurement({
        type: MapMeasureTools.measure_artillery,
        value: measurementValue,
      })
    );
  };

  return (
    <div className="flex gap-3 items-center">
      <DropdownControl
        value={measurement?.value?.name ?? ''}
        options={ARTILLERY_OPTIONS}
        icon={<ArtilleryIcon />}
        isActive={drawTool === MapMeasureTools.measure_artillery}
        showCustomCursors={showCustomCursors}
        classNames={{
          container: 'z-10',
          toggle: TOGGLE_CLASSNAME,
        }}
        onToggle={() =>
          handleControlToggle(drawTool, MapMeasureTools.measure_artillery)
        }
        onSelect={handleArtilleryChange}
        onEscapeDown={handleDrawCancel}
        onEnterDown={handleDrawStop}
      />
      <ToggleControl
        icon={<PolygonIcon />}
        isActive={drawTool === MapCreateTools.create_polygon}
        className={TOGGLE_CLASSNAME}
        onToggle={() =>
          handleControlToggle(drawTool, MapCreateTools.create_polygon)
        }
        onEscapeDown={handleDrawCancel}
        onEnterDown={handleDrawStop}
      />
      <ToggleControl
        icon={<PenToolIcon />}
        isActive={drawTool === MapCreateTools.create_line_string}
        className={TOGGLE_CLASSNAME}
        onToggle={() =>
          handleControlToggle(drawTool, MapCreateTools.create_line_string)
        }
        onEscapeDown={handleDrawCancel}
        onEnterDown={handleDrawStop}
      />
      {!isMobile && (
        <ToggleControl
          icon={<RadiusIcon />}
          isActive={drawTool === MapCreateTools.create_circle}
          className={TOGGLE_CLASSNAME}
          onToggle={() =>
            handleControlToggle(drawTool, MapCreateTools.create_circle)
          }
          onEscapeDown={handleDrawCancel}
          onEnterDown={handleDrawStop}
        />
      )}
    </div>
  );
};
