import { FC, PropsWithChildren, ReactElement } from 'react';

import {
  ContextMenuTooltip,
  IContextMenuTooltipItem,
} from 'components/ContextMenuTooltip';

interface IContextMenuWrapper {
  contextMenu?: IContextMenuTooltipItem[];
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu';
}

export const ContextMenuWrapper: FC<PropsWithChildren<IContextMenuWrapper>> = ({
  contextMenu,
  trigger,
  children,
}) => {
  const hasContextMenu = !!contextMenu?.length;

  return (
    <>
      {hasContextMenu ? (
        <ContextMenuTooltip
          trigger={trigger || 'contextMenu'}
          items={contextMenu}
          keyboard
        >
          {children as ReactElement}
        </ContextMenuTooltip>
      ) : (
        children
      )}
    </>
  );
};
