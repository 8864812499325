import {
  RAILSTATION_LABELS_LAYER,
  RAILSTATION_POINTS_LAYER,
} from 'constants/map';
import { SymbolLayerSpecification, VectorSourceSpecification } from 'mapbox-gl';

import { labelLayout } from '../common';

const { REACT_APP_API_URL } = process.env;

const railstationsUrl = REACT_APP_API_URL + 'data/railstations/{z}/{x}/{y}.pbf';

export const RAILSTATIONS_MARKER = 'railstations-marker';
export const RAILSTATIONS_MARKER_SVG =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z" fill="#E3E6F1" stroke="black"/>\n' +
      '</svg>\n'
  );

export const railStationsSource: VectorSourceSpecification = {
  type: 'vector',
  id: 'railstations-vector-source',
  tiles: [railstationsUrl],
  minzoom: 0,
  maxzoom: 10,
};

export const railStationsMarkerLayer: SymbolLayerSpecification = {
  id: RAILSTATION_POINTS_LAYER,
  type: 'symbol',
  source: 'railstations-vector-source',
  'source-layer': 'source',
  layout: {
    'icon-image': RAILSTATIONS_MARKER,
    'icon-size': 1,
    'icon-allow-overlap': true,
  },
};

export const railStationsSymbolLayer: SymbolLayerSpecification = {
  id: RAILSTATION_LABELS_LAYER,
  type: 'symbol',
  source: 'railstations-vector-source',
  'source-layer': 'source',
  layout: {
    ...labelLayout,
    'text-field': ['get', 'name'],
    'text-size': 9,
  },
  paint: {
    'text-color': '#c0c0c0',
    'text-halo-color': '#232222',
    'text-halo-width': 10,
  },
};
