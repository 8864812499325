import { ReactComponent as LayersActive } from 'images/newIcons/layersActive.svg';
import { ReactComponent as LayersPassive } from 'images/newIcons/layersPassive.svg';
import { ReactComponent as LogisticActive } from 'images/newIcons/logisticActive.svg';
import { ReactComponent as LogisticPassive } from 'images/newIcons/logisticPassive.svg';
import { ReactComponent as ObjectsActive } from 'images/newIcons/objectsActive.svg';
import { ReactComponent as ObjectsPassive } from 'images/newIcons/objectsPassive.svg';
import { ReactComponent as Oper } from 'images/newIcons/oper.svg';
import { ReactComponent as Satellite } from 'images/newIcons/satellite.svg';
import { IMapTypes, ISelectOption, Option, TCircle } from 'interfaces';
import { CoordinateSystems, MeasureSystems } from 'types';

import { GEOSPOOF_COLOR } from './geospoof';
import { CIRCLE_POLYGON_VERTEX_COUNT } from './mapDraw';
import { mapRoutes } from './routes';

export const MAPBOX_API = 'https://api.mapbox.com';
export const MAP_BOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';

export const MAP_LAYER_NAME_LIMIT = 256;

export const MAP_MIN_ZOOM_LEVEL = 0;
export const MAP_MAX_ZOOM_LEVEL = 22;

/**
 * Sidebar sizes
 */
export const SIDEBAR_INITIAL_CONTAINER_WIDTH = 78;

export const SIDEBAR_CONTENT_WIDTH = 378;

/**
 * Restrictions on the number of characters that can be entered in the map search field
 */
export const SEARCH_VALUE_MAX_LIMIT = 1000;

/**
 * Limit of the coordinate range
 */
export const LATITUDE_MIN = -90;
export const LATITUDE_MAX = 90;
export const LONGITUDE_MIN = -180;
export const LONGITUDE_MAX = 180;

export const SK42_FULL_REGEX =
  /^([xX][:=])?-?(\d{1,7}),\s*([Yy][:=])?-?(\d{7,8})$/;

export const SK42_SHORT_REGEX = /^-?(\d{5}),\s*-?(\d{5})$/;

export const SK42_SQUARE_SHORT_REGEX = /^(Квадрат)\s*\d{4}$/i;
export const SK42_SQUARE_FULL_REGEX = /^(Квадрат)\s*\d{6}$/i;

export const WGS_FULL_REGEX =
  /^-?([0-8]?[0-9]|90)(\.[0-9]{1,}),\s*-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,})$/;

export const MGRS_FULL_REGEX =
  /^\d{1,2}[A-HJ-NP-Z]{3}(\d{10}|\d{8}|\d{6}|\d{4}|\d{2})$/im;

export const SK42_SQUARE_FULL_LENGTH = 6;

export const INTEGER_REGEX = /(?:(-)?(\d\.?)+)/gm;

export const DEFAULT_DATE_FORMAT = 'DD.MM.YY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_RU = 'DD.MM.YYYY';

export const mapObjectType: ISelectOption[] = [
  { label: 'Склад ВиВТ', value: 'militarystorage' },
  { label: 'Склад БК', value: 'ammunition' },
  { label: 'Склад ГСМ', value: 'lubricants' },
  { label: 'Стоянка техники', value: 'stand' },
  { label: 'Стоянка АТ', value: 'aviation' },
  { label: 'Расположение л/c', value: 'location' },
  { label: 'Позиция военной техники', value: 'militaryequipment' },
  { label: 'Позиция артиллерии', value: 'artillery' },
  { label: 'Позиция РЛС', value: 'radio' },
  { label: 'Позиция ПВО', value: 'antiair' },
  { label: 'Командный пункт', value: 'headquarters' },
  { label: 'Ж/Д-инфраструктура', value: 'railway' },
  { label: 'Объект ВПК', value: 'military' },
  { label: 'Пехотный окоп / фортификация', value: 'trench' },
  { label: 'Энергетическая инфраструктура', value: 'energy' },
  { label: 'Персоналии', value: 'personalities' },
  { label: 'Другое', value: 'other' },
];

export const opacityOptions: ISelectOption[] = [
  { label: '100%', value: 100 },
  { label: '75%', value: 75 },
  { label: '50%', value: 50 },
  { label: '25%', value: 25 },
  { label: '0%', value: 0 },
];

export const FEATURE_COLORS = [
  '#d32f2f',
  '#F44870',
  '#f58de4',
  '#7a1fa0',
  '#512da8',
  '#303f9f',
  '#2196f3',
  '#00bcd4',
  '#00796b',
  '#388e3c',
  '#dce775',
  '#f5eb8d',
  '#fbc02d',
  '#f57c00',
  '#ff5722',
  '#a0877e',
  '#5d4037',
  '#455a64',
  '#333333',
  '#ffffff',
];

export enum FeatureTypes {
  POINT = 'Point',
  LINE = 'LineString',
  POLYGON = 'Polygon',
}

export enum MapObjectTypes {
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon',
}

export type TTabTitle =
  | 'search'
  | 'layers'
  | 'types'
  | 'imagery'
  | 'operational'
  | 'logistic'
  | 'features';

export interface ISidebarTab {
  link: string;
  passiveIcon: JSX.Element;
  activeIcon: JSX.Element;
  title: TTabTitle;
}

export const mapSidebarTabs: ISidebarTab[] = [
  // {
  //   link: mapRoutes.SEARCH,
  //   passiveIcon: <Heatmap />,
  //   activeIcon: <Heatmap />,
  //   title: 'search',
  // },
  {
    link: mapRoutes.LAYERS,
    passiveIcon: <ObjectsPassive />,
    activeIcon: <ObjectsActive />,
    title: 'layers',
  },
  {
    link: mapRoutes.TYPES,
    passiveIcon: <LayersPassive />,
    activeIcon: <LayersActive />,
    title: 'types',
  },
  {
    link: mapRoutes.OPERATIONAL,
    passiveIcon: <Oper />,
    activeIcon: <Oper />,
    title: 'operational',
  },
  {
    link: mapRoutes.IMAGERY,
    passiveIcon: <Satellite />,
    activeIcon: <Satellite />,
    title: 'imagery',
  },
  {
    link: mapRoutes.LOGISTIC,
    passiveIcon: <LogisticPassive />,
    activeIcon: <LogisticActive />,
    title: 'logistic',
  },
];

export const mapLocale = {
  'AttributionControl.ToggleAttribution': 'Toggle attribution',
  'AttributionControl.MapFeedback': 'Map feedback',
  'FullscreenControl.Enter': 'Enter fullscreen',
  'FullscreenControl.Exit': 'Exit fullscreen',
  'GeolocateControl.FindMyLocation': 'Find my location',
  'GeolocateControl.LocationNotAvailable': 'Location not available',
  'LogoControl.Title': 'Mapbox logo',
  'Map.Title': 'Map',
  'NavigationControl.ResetBearing': 'Reset bearing to north',
  'NavigationControl.ZoomIn': 'Zoom in',
  'NavigationControl.ZoomOut': 'Zoom out',
  'ScrollZoomBlocker.CtrlMessage': 'Use ctrl + scroll to zoom the map',
  'ScrollZoomBlocker.CmdMessage': 'Use ⌘ + scroll to zoom the map',
  'TouchPanBlocker.Message': 'Use two fingers to move the map',
};

export const initialFeatureFiltersState = {
  polygons: true,
  lines: true,
  markers: true,
  all: true,
  global: {
    type: '',
    object: '',
    date: null,
    createdAt: null,
    status: '',
    report: null,
    client: null,
  },
};

export const initialMapTypesState: IMapTypes = {
  // подложки
  googleSatellite: {
    active: true,
    type: 'style',
  },
  mapboxMinimalism: {
    active: false,
    type: 'style',
  },
  streetMap: {
    active: false,
    type: 'style',
  },
  mapboxSatellite: {
    active: false,
    type: 'style',
  },
  // верхние слои
  infrastructure: {
    active: false,
    type: 'layer',
  },
  elevation: {
    active: false,
    type: 'layer',
  },
  fire: {
    active: false,
    type: 'layer',
    data: new Date().toISOString().split('T')[0],
  },
  railroads: {
    active: false,
    type: 'layer',
  },
  railStations: {
    active: false,
    type: 'layer',
  },
  demMap: {
    active: false,
    type: 'layer',
  },
  gcFrontline: {
    active: false,
    type: 'layer',
  },
  gcPlacemarks: {
    active: false,
    type: 'layer',
  },
  googleSatelliteUpper: {
    active: false,
    type: 'layer',
  },
  mapboxSatelliteUpper: {
    active: false,
    type: 'layer',
  },
};

export const initialMapAttributesState = {
  reports: [],
  clients: [],
};

export enum imageUploadTypes {
  TIF = 'tif',
  PNG = 'png',
  BATCH = 'batch',
}

export const imageTypeOptions: ISelectOption[] = [
  { label: '.tif', value: imageUploadTypes.TIF },
  { label: '.png + метаданные', value: imageUploadTypes.PNG },
  { label: 'множественная загрузка', value: imageUploadTypes.BATCH },
];

export const converterDirectionOptions: ISelectOption[] = [
  { label: 'WGS в СК-42', value: 'wgsTosk42' },
  { label: 'WGS в УСК2000', value: 'wgsTousk2000' },
  { label: 'WGS в MGRS', value: 'wgsTomgrs' },
  { label: 'СК-42 в WGS', value: 'sk42Towgs' },
  { label: 'СК-42 в УСК2000', value: 'sk42Tousk2000' },
  { label: 'СК-42 в MGRS', value: 'sk42Tomgrs' },
  { label: 'УСК2000 в WGS', value: 'usk2000Towgs' },
  { label: 'УСК2000 в СК-42', value: 'usk2000Tosk42' },
  { label: 'УСК2000 в MGRS', value: 'usk2000Tomgrs' },
  { label: 'MGRS в WGS', value: 'mgrsTowgs' },
  { label: 'MGRS в СК-42', value: 'mgrsTosk42' },
  { label: 'MGRS в УСК2000', value: 'mgrsTousk2000' },
];

export const DEFAULT_HEIGHT = 180;

export const relevanceTypes: ISelectOption[] = [
  { label: 'Другое значение', value: 'other' },
  { label: '1 день', value: '1day' },
  { label: '3 дня', value: '3days' },
  { label: '1 неделя', value: '1week' },
  { label: '1 месяц', value: '1month' },
  { label: '3 месяца', value: '3months' },
  { label: '6 месяцев', value: '6months' },
  { label: '1 год', value: '1year' },
  { label: 'с 24.02.2022', value: 'fromStart' },
];

export const circleOptions: TCircle = {
  steps: CIRCLE_POLYGON_VERTEX_COUNT,
  units: MeasureSystems.METERS,
  properties: {
    fillColor: GEOSPOOF_COLOR.activeFill,
    lineColor: GEOSPOOF_COLOR.activeLine,
    width: 2,
    opacity: 0.4,
  },
};

export const coordinateSystemOptions: Option<CoordinateSystems>[] = [
  { label: 'WGS', value: CoordinateSystems.WGS },
  { label: 'MGRS', value: CoordinateSystems.MGRS },
  { label: 'СК-42', value: CoordinateSystems.SK42 },
  { label: 'УСК2000', value: CoordinateSystems.USK2000 },
];

export const coordinateSystemTitleMap: Record<string, string> = {
  [CoordinateSystems.WGS]: 'WGS',
  [CoordinateSystems.MGRS]: 'MGRS',
  [CoordinateSystems.SK42]: 'СК-42',
  [CoordinateSystems.USK2000]: 'УСК2000',
};

export const MAPBOX = 'mapbox';
export const MAPBOX_MAP_CONTAINER = 'mapbox-container';

export const BOUNDARY_GRID_SOURCE = 'boundary-grid-source';
export const CIRCLE_SOURCE = 'circle-source';
export const POINT_SOURCE = 'point-source';
export const LINE_SOURCE = 'line-source';
export const POLYGON_SOURCE = 'polygon-source';
export const LABEL_SOURCE = 'label-source';

export const SATELLITE_STATIC_LAYER = 'satellite-static-layer';
export const SATELLITE_IMAGE_STATIC_LAYER = 'satellite-image-static-layer';
export const ELEVATION_STATIC_LAYER = 'elevation-static-layer';
export const LABEL_STATIC_LAYER = 'label-static-layer';
export const FIRE_STATIC_LAYER = 'fire-static-layer';
export const LOGISTIC_STATIC_LAYER = 'logistic-static-layer';
export const FRONTLINE_STATIC_LAYER = 'frontline-static-layer';
export const POLYGON_STATIC_LAYER = 'polygon-static-layer';
export const LINE_STATIC_LAYER = 'line-static-layer';
export const POINT_STATIC_LAYER = 'point-static-layer';

export const SELFHOSTED_DEM_RASTER_LAYER = 'selfhosted-dem-raster-layer';

export const GOOGLE_SATELLITE_LAYER = 'google-satellite-layer';
export const GOOGLE_SATELLITE_UPPER_LAYER = 'google-satellite-upper-layer';
export const GOOGLE_STREETS_LAYER = 'google-streets-layer';

export const ELEVATION_LANDCOVER_LAYER = 'elevation-landcover-layer';
export const ELEVATION_HILLSHADE_LAYER = 'elevation-hillshade-layer';
export const ELEVATION_CONTOUR_LAYER = 'elevation-contour-layer';

export const WATER_PIPELINE_LAYER = 'water_pipeline';
export const WATER_PIPELINE_CASE_LAYER = 'water_pipeline_case';
export const WATER_PIPELINE_LABEL_LAYER = 'water_pipeline_label';

export const HEATMAP_SOLAR_LAYER = 'heatmap_solar';
export const FIRE_LAYER = 'VIIRS_NOAA20_Thermal_Anomalies_375m_All';

export const PETROLEUM_SITE_LAYER = 'petroleum_site';
export const PETROLEUM_SITE_LABEL_LAYER = 'petroleum_site_label';
export const PETROLEUM_WELL_LAYER = 'petroleum_well';
export const PETROLEUM_WELL_LABEL_LAYER = 'petroleum_well_label';
export const PETROLEUM_PIPELINE_LAYER = 'petroleum_pipeline';
export const PETROLEUM_PIPELINE_CASE_LAYER = 'petroleum_pipeline_case';
export const PETROLEUM_PIPELINE_LABEL_LAYER = 'petroleum_pipeline_label';

export const ZHD_LINE_LAYER = 'zhd-line-layer';
export const ZHD_SYMBOL_LAYER = 'zhd-symbol-layer';

export const RAILSTATION_POINTS_LAYER = 'ralstations-points-layer';
export const RAILSTATION_LABELS_LAYER = 'ralstations-labels-layer';

export const POWER_LINE_CASE_LAYER = 'power_line_case';
export const POWER_LINE_REF_LAYER = 'power_line_ref';
export const POWER_LINE_LABEL_LAYER = 'power_line_label';
export const POWER_LINE_UNDERGROUND_1_LAYER = 'power_line_underground_1';
export const POWER_LINE_UNDERGROUND_2_LAYER = 'power_line_underground_2';
export const POWER_LINE_UNDERGROUND_3_LAYER = 'power_line_underground_3';
export const POWER_PLANT_LAYER = 'power_plant';
export const POWER_PLANT_OUTLINE_LAYER = 'power_plant_outline';
export const POWER_PLANT_LABEL_LAYER = 'power_plant_label';
export const POWER_PLANT_OUTLINE_CONSTRUCTION_LAYER =
  'power_plant_outline_construction';
export const POWER_SUBSTATION_LAYER = 'power_substation';
export const POWER_SUBSTATION_POINT_LAYER = 'power_substation_point';
export const POWER_SUBSTATION_LABEL_LAYER = 'power_substation_label';
export const POWER_SUBSTATION_REF_LABEL_LAYER = 'power_substation_ref_label';
export const POWER_SOLAR_PANEL_LAYER = 'power_solar_panel';
export const POWER_LINE_1_LAYER = 'power_line_1';
export const POWER_LINE_2_LAYER = 'power_line_2';
export const POWER_LINE_3_LAYER = 'power_line_3';
export const POWER_TRANSFORMER_LAYER = 'power_transformer';
export const POWER_COMPENSATOR_LAYER = 'power_compensator';
export const POWER_SWITCH_LAYER = 'power_switch';
export const POWER_TOWER_LAYER = 'power_tower';
export const POWER_POLE_LAYER = 'power_pole';
export const POWER_WIND_TURBINE_LAYER = 'power_wind_turbine';
export const POWER_WIND_TURBINE_POINT_LAYER = 'power_wind_turbine_point';
export const POWER_CONVERTER_POINT_LAYER = 'power_converter_point';

export const TELECOMS_LINE_LAYER = 'telecoms_line';
export const TELECOMS_LINE_LABEL = 'telecoms_line_label';
export const TELECOMS_MAST_LAYER = 'telecoms_mast';
export const TELECOMS_DATA_CENTER_LAYER = 'telecoms_data_center';
export const TELECOMS_DATA_CENTER_SYMBOL_LAYER = 'telecoms_data_center_symbol';

export const BOUNDARY_GRID_CELL_LAYER = 'boundary-grid-cell-layer';
export const BOUNDARY_GRID_CELL_NUMBER_LAYER =
  'boundary-grid-cell-number-layer';
export const IMAGERY_PREVIEW_FILL_LAYER = 'imagery-preview-fill-layer';
export const IMAGERY_PREVIEW_LINE_LAYER = 'imagery-preview-line-layer';
export const IMAGERY_BOUNDARY_LAYER = 'imagery-boundary-layer';
export const IMAGERY_SOURCE_PREFIX = 'imagery-source';

export const DOCUMENT_POINT = 'document-layer';
export const GEOCODER_FEATURE = 'geocoder-feature';
export const GEOCODER_FEATURE_MAIN_FILL = 'geocoder-feature-main-fill';

export const GC_PLACEMARK_LAYER = 'gc-placemarks-layer';
export const GC_FRONTLINE_LOWER_LAYER = 'gc-frontline-lower-layer';
export const GC_FRONTLINE_UPPER_LAYER = 'gc-frontline-upper-layer';

export const LAYERS_POINT = 'layers-point';
export const LAYERS_POINT_MAIN = 'layers-point-main';

export const LAYERS_LINE = 'layers-line';
export const LAYERS_LINE_MAIN_FILL = 'layers-line-main-fill';

export const LAYERS_POLYGON = 'layers-polygon';
export const LAYERS_POLYGON_MAIN_FILL = 'layers-polygon-main-fill';
export const LAYERS_POLYGON_MAIN_LINE = 'layers-polygon-main-line';

export const DRAW_POLYGON_INACTIVE_COLD_LAYER_ID =
  'gl-draw-polygon-fill-inactive.cold';
export const DRAW_POLYGON_INACTIVE_HOT_LAYER_ID =
  'gl-draw-polygon-fill-inactive.hot';
export const DRAW_POLYGON_ACTIVE_COLD_LAYER_ID =
  'gl-draw-polygon-fill-active.cold';
export const DRAW_POLYGON_ACTIVE_HOT_LAYER_ID =
  'gl-draw-polygon-fill-active.hot';

export const DEFAULT_FLY_TO_SETTINGS = {
  zoom: 10,
  essential: true,
  speed: 6,
};

export const OFFSET_TO_FIT_PREVIEW = 0.001;
