import {
  MapboxDrawCustomEvents,
  MapboxDrawEvents,
  MapboxDrawModes,
  VALID_POLYGON_MIN_VERTEX_COUNT,
} from 'constants/mapDraw';
import {
  DrawCustomModeOverride,
  DrawEventActions,
  DrawFeatureSubtypes,
  MapDrawModes,
} from 'types';

const DrawPolygonModeOverride = Object.assign(
  {},
  MapboxDrawModes.draw_polygon
) as DrawCustomModeOverride;

const _onSetup = DrawPolygonModeOverride.onSetup;
const _onStop = DrawPolygonModeOverride.onStop;
const _onMouseMove = DrawPolygonModeOverride.onMouseMove;
const _clickOnVertex = DrawPolygonModeOverride.clickOnVertex;
const _onClick = DrawPolygonModeOverride.onClick;
const _onTap = DrawPolygonModeOverride.onTap;

DrawPolygonModeOverride.onSetup = function (opts) {
  const setupData = _onSetup?.apply?.(this, [opts]);

  setupData.polygon.properties.subtype = DrawFeatureSubtypes.POLYGON;
  setupData.polygon.properties.vertexCount = 0;
  setupData.polygon.properties.hasMinimumVertexCount = false;

  return setupData;
};

DrawPolygonModeOverride.onStop = function (state) {
  _onStop?.apply?.(this, [state]);

  const featureTarget = state.polygon.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.STOP, { featureTarget });
};

DrawPolygonModeOverride.onMouseMove = function (state, e) {
  _onMouseMove?.apply?.(this, [state, e]);

  const featureTarget = state.polygon.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.MOUSE_MOVE, {
    ...e,
    featureTarget,
  });
};

DrawPolygonModeOverride.clickOnVertex = function (state, e) {
  _clickOnVertex.apply?.(this, [state, e]);

  const hasMinimumVertexCount =
    state.currentVertexPosition >= VALID_POLYGON_MIN_VERTEX_COUNT;

  !hasMinimumVertexCount &&
    this.map.fire(MapboxDrawEvents.MODE_CHANGE, {
      mode: MapDrawModes.simple_select,
      action: DrawEventActions.DRAW_CANCEL,
    });
};

DrawPolygonModeOverride.onClick = function (state, e) {
  if (e.originalEvent.button === 2) {
    return;
  }

  _onClick?.apply?.(this, [state, e]);

  state.polygon.properties = {
    ...state.polygon.properties,
    vertexCount: state.currentVertexPosition,
    hasMinimumVertexCount:
      state.currentVertexPosition >= VALID_POLYGON_MIN_VERTEX_COUNT,
  };

  const featureTarget = state.polygon.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.CLICK, {
    ...e,
    featureTarget,
  });
};

DrawPolygonModeOverride.onTap = function (state, e) {
  _onTap?.apply?.(this, [state, e]);

  state.polygon.properties = {
    ...state.polygon.properties,
    vertexCount: state.currentVertexPosition,
    hasMinimumVertexCount:
      state.currentVertexPosition >= VALID_POLYGON_MIN_VERTEX_COUNT,
  };

  const featureTarget = state.polygon.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.TAP, {
    ...e,
    featureTarget,
  });
};

export { DrawPolygonModeOverride };
