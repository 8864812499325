import { useCallback } from 'react';
import { IControl, MapInstance, useControl } from 'react-map-gl';
import { mapDraw } from 'configs/map/instances/mapDraw';
import { MapboxDrawEvents } from 'constants/mapDraw';
import { DrawEventActions, MapDrawModes, MapDrawTool } from 'types';

import { useMapRef } from './map';

/**
 * Hook for predictable map draw handling oriented to work with SINGLE drawn feature at the time.
 * mapbox-gl-draw lacks of typization so not all of functionality are described properly.
 * See https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md for details.
 */
export const useMapDraw = () => {
  const { mapRef } = useMapRef();

  const handleDrawClear = useCallback(() => {
    mapDraw.deleteAll();
  }, []);

  // mapDraw.changeMode does not fire events if methods are programmatically used.
  const handleChangeDrawMode = useCallback(
    (mode: MapDrawTool, options?: object, action?: DrawEventActions) => {
      mapDraw.changeMode(mode as string, options);
      mapRef.current?.fire(MapboxDrawEvents.MODE_CHANGE, {
        mode: mode,
        action: action,
      });
    },
    [mapRef]
  );

  const handleDrawStop = useCallback(() => {
    mapDraw.changeMode(MapDrawModes.simple_select);

    const hasDrawFeatures = !!mapDraw.getAll().features.length;

    mapRef.current?.fire(MapboxDrawEvents.MODE_CHANGE, {
      mode: MapDrawModes.simple_select,
      action: hasDrawFeatures
        ? DrawEventActions.DRAW_STOP
        : DrawEventActions.DRAW_CANCEL,
    });
  }, [mapRef]);

  const handleDrawCancel = useCallback(() => {
    mapDraw.deleteAll();
    mapDraw.changeMode(MapDrawModes.simple_select);
    mapRef.current?.fire(MapboxDrawEvents.MODE_CHANGE, {
      mode: MapDrawModes.simple_select,
      action: DrawEventActions.DRAW_CANCEL,
    });
  }, [mapRef]);

  /*
    Inconsistent behaviour if incomplete feautures there (e.g. line_string with one actual coordinate)
    as mapDraw does not fire draw.create if there such.
   */
  const handleDrawCreate = useCallback(() => {
    mapDraw.changeMode(MapDrawModes.simple_select);

    const hasDrawFeatures = !!mapDraw.getAll().features.length;

    if (hasDrawFeatures) {
      mapRef.current?.fire(MapboxDrawEvents.CREATE, {
        features: mapDraw.getAll().features,
        action: DrawEventActions.DRAW_CREATE,
      });
    }

    mapRef.current?.fire(MapboxDrawEvents.MODE_CHANGE, {
      mode: MapDrawModes.simple_select,
      action: hasDrawFeatures
        ? DrawEventActions.DRAW_CREATE
        : DrawEventActions.DRAW_CANCEL,
    });
  }, [mapRef]);

  return {
    mapDraw,
    handleDrawClear,
    handleChangeDrawMode,
    handleDrawStop,
    handleDrawCancel,
    handleDrawCreate,
  };
};

// TODO: specify correct type when type issue resolved. See https://github.com/mapbox/mapbox-gl-draw/issues/1257
export const useMapControl = () => {
  useControl(() => mapDraw as IControl<MapInstance>);
};
