import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import {
  MapboxDrawConstants,
  MapboxDrawCustomEvents,
  MapboxDrawLib,
  MapboxDrawModes,
} from 'constants/mapDraw';
import { DrawFeatureSubtypes, MeasureSystems } from 'types';
import {
  DrawCustomModeOverride,
  DrawModeCircleFeature,
  DrawModeCommonFeature,
} from 'types';

import { createSupplementaryPointsForCircle } from './utils';

const DirectSelectModeOverride = Object.assign(
  {},
  MapboxDrawModes.direct_select as DrawCustomModeOverride
);

const { createSupplementaryPoints, moveFeatures, constrainFeatureMovement } =
  MapboxDrawLib;

// based on https://github.com/iamanvesh/mapbox-gl-draw-circle
DirectSelectModeOverride.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  this.getSelected()
    .filter(
      (feature) => feature?.properties?.subtype === DrawFeatureSubtypes.CIRCLE
    )
    .map((circle) => circle?.properties?.center)
    .forEach((center) => {
      center[0] += delta.lng;
      center[1] += delta.lat;
    });

  state.dragMoveLocation = e.lngLat;

  const featureTarget = state.feature.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.DRAG_FEATURE, {
    ...e,
    featureTarget,
  });
};

DirectSelectModeOverride.dragVertex = function (state, e, delta) {
  if (state.feature.properties.subtype === DrawFeatureSubtypes.CIRCLE) {
    const center = state.feature.properties.center;
    const movedVertex = [e.lngLat.lng, e.lngLat.lat];
    const radiusInM = distance(point(center), point(movedVertex), {
      units: MeasureSystems.METERS,
    });
    const radiusInKm = radiusInM / 1000;
    const circleFeature = circle(center, radiusInKm);

    state.feature.incomingCoords(circleFeature.geometry.coordinates);
    state.feature.properties.radiusInM = radiusInM;
  } else {
    const selectedCoords = state.selectedCoordPaths.map((coord_path: any) =>
      state.feature.getCoordinate(coord_path)
    );

    const selectedCoordPoints = selectedCoords.map((coords: any) => ({
      type: MapboxDrawConstants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: MapboxDrawConstants.geojsonTypes.POINT,
        coordinates: coords,
      },
    }));

    const constrainedDelta = constrainFeatureMovement(
      selectedCoordPoints,
      delta
    );

    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i];
      state.feature.updateCoordinate(
        state.selectedCoordPaths[i],
        coord[0] + constrainedDelta.lng,
        coord[1] + constrainedDelta.lat
      );
    }
  }

  const featureTarget = state.feature.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.DRAG_VERTEX, {
    ...e,
    featureTarget,
  });
};

DirectSelectModeOverride.toDisplayFeatures = function (
  state,
  drawFeature: DrawModeCommonFeature | DrawModeCircleFeature,
  display
) {
  if (state.featureId === drawFeature.properties.id) {
    const supplementaryPointsOptions: any = {
      map: this.map,
      midpoints: true,
      selectedPaths: state.selectedCoordPaths,
    };

    const supplementaryPoints =
      drawFeature.properties.user_subtype === DrawFeatureSubtypes.CIRCLE
        ? createSupplementaryPointsForCircle(drawFeature)
        : createSupplementaryPoints(drawFeature, supplementaryPointsOptions);

    drawFeature.properties.active = MapboxDrawConstants.activeStates.ACTIVE;

    display(drawFeature);
    supplementaryPoints?.forEach(display);
  } else {
    drawFeature.properties.active = MapboxDrawConstants.activeStates.INACTIVE;
    display(drawFeature);
  }

  this.fireActionable(state);
};

export { DirectSelectModeOverride };
