import { useEffect } from 'react';
import {
  DrawCreateEvent,
  DrawModeChangeEvent,
  MapMouseEvent,
  MapTouchEvent,
} from '@mapbox/mapbox-gl-draw';
import { MapboxDrawCustomEvents, MapboxDrawEvents } from 'constants/mapDraw';
import {
  DrawCreateCustomEvent,
  DrawDragFeatureCustomEvent,
  DrawDragMoveCustomEvent,
  DrawDragVertexCustomEvent,
  DrawModeChangeCustomEvent,
  DrawMouseCustomEvent,
  DrawStopCustomEvent,
  DrawTouchCustomEvent,
} from 'interfaces';

import { useMapRef } from './map';

// missleading inconsistency as *Event.featureTarget actual fields properties differ from DrawFeature type
export interface UseMapboxDrawEventSubscriptionProps {
  onCreate?: (e: DrawCreateEvent | DrawCreateCustomEvent) => void;
  onStop?: (e: DrawStopCustomEvent) => void;
  onModeChange?: (e: DrawModeChangeEvent | DrawModeChangeCustomEvent) => void;
  onDragVertex?: (e: DrawDragVertexCustomEvent) => void;
  onDragFeature?: (e: DrawDragFeatureCustomEvent) => void;
  onDragMove?: (e: DrawDragMoveCustomEvent) => void;
  onMouseMove?: (e: MapMouseEvent | DrawMouseCustomEvent) => void;
  onClick?: (e: MapMouseEvent | DrawMouseCustomEvent) => void;
  onTap?: (e: MapTouchEvent | DrawTouchCustomEvent) => void;
}

// useControl and MapboxDraw are not reactive so we need to subscribe to event with useEffect
export const useMapDrawEventSubscription = ({
  onCreate,
  onStop,
  onModeChange,
  onDragVertex,
  onDragFeature,
  onDragMove,
  onMouseMove,
  onClick,
  onTap,
}: UseMapboxDrawEventSubscriptionProps) => {
  const { mapRef } = useMapRef();

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onCreate && map.on(MapboxDrawEvents.CREATE, onCreate);

      return () => {
        onCreate && map.off(MapboxDrawEvents.CREATE, onCreate);
      };
    }
  }, [onCreate]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onStop && map.on(MapboxDrawCustomEvents.STOP, onStop);

      return () => {
        onStop && map.off(MapboxDrawCustomEvents.STOP, onStop);
      };
    }
  }, [onStop]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onModeChange && map.on(MapboxDrawEvents.MODE_CHANGE, onModeChange);

      return () => {
        onModeChange && map.off(MapboxDrawEvents.MODE_CHANGE, onModeChange);
      };
    }
  }, [onModeChange]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onDragVertex && map.on(MapboxDrawCustomEvents.DRAG_VERTEX, onDragVertex);

      return () => {
        onDragVertex &&
          map.off(MapboxDrawCustomEvents.DRAG_VERTEX, onDragVertex);
      };
    }
  }, [onDragVertex]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onDragFeature &&
        map.on(MapboxDrawCustomEvents.DRAG_FEATURE, onDragFeature);

      return () => {
        onDragFeature &&
          map.off(MapboxDrawCustomEvents.DRAG_FEATURE, onDragFeature);
      };
    }
  }, [onDragFeature]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onDragMove && map.on(MapboxDrawCustomEvents.DRAG_MOVE, onDragMove);

      return () => {
        onDragMove && map.off(MapboxDrawCustomEvents.DRAG_MOVE, onDragMove);
      };
    }
  }, [onDragMove]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onMouseMove && map.on(MapboxDrawCustomEvents.MOUSE_MOVE, onMouseMove);

      return () => {
        onMouseMove && map.off(MapboxDrawCustomEvents.MOUSE_MOVE, onMouseMove);
      };
    }
  }, [onMouseMove]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onClick && map.on(MapboxDrawCustomEvents.CLICK, onClick);

      return () => {
        onClick && map.off(MapboxDrawCustomEvents.CLICK, onClick);
      };
    }
  }, [onClick]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onTap && map.on(MapboxDrawCustomEvents.TAP, onTap);

      return () => {
        onTap && map.off(MapboxDrawCustomEvents.TAP, onTap);
      };
    }
  }, [onTap]);
};
