import { FC, useEffect, useMemo, useState } from 'react';
import { getTags } from 'api/documents';
import { errorMessages } from 'constants/errors';
import { SearchTagRes, Tag, TagCategory } from 'interfaces/documents';
import { useDebouncedCallback } from 'use-debounce';

import { ContextMenuWrapper } from 'components/Tree/ContextMenuWrapper';
import { Loader, TextInput } from 'components/ui';
import EntityFieldsGroup from 'components/ui/EntityCard/EntityFieldsGroup';
import { notify } from 'utils';

import { DocumentFilters } from '../../../../routes/Documents';
import { TSetState } from '../../../../types';

import DocumentTag from './DocumentTag';

const ALL_TAGS_TITLE = 'все';

interface TagSidebarProps extends DocumentFilters {
  setTagIDs: TSetState<number[]>;
}

export const TagSidebar: FC<TagSidebarProps> = ({
  tagIDs,
  setTagIDs,
  searchQuery,
  activeEntityIDs,
}) => {
  const [tagQuery, setTagQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState(ALL_TAGS_TITLE);
  const [tags, setTags] = useState<SearchTagRes>();
  const [categoryTagLimits, setCategoryTagLimits] = useState<
    Record<string, number>
  >({});
  const [isLoading, setLoading] = useState(false);

  const categoryOptions = useMemo(
    () =>
      tags?.categories.map((category) => ({
        title: category.name,
        onClick: () => setActiveCategory(category.name),
      })) || [],
    [tags]
  );

  const filteredCategories = useMemo(() => {
    if (!tags) return [];
    if (activeCategory === ALL_TAGS_TITLE) return tags.categories;
    return tags.categories.filter(
      (category) => category.name === activeCategory
    );
  }, [activeCategory, tags]);

  const fetchTags = async (showLoader = true) => {
    showLoader && setLoading(true);
    await getTags({
      query: searchQuery,
      tagValue: tagQuery,
      categoryTagLimits: categoryTagLimits,
      tagIDs: tagIDs,
      activeEntityIDs: activeEntityIDs,
    })
      .then((res) => setTags(res))
      .catch((err) => {
        console.error(err);
        notify.error(errorMessages.GET_TAGS_ERROR);
      })
      .finally(() => setLoading(false));
  };

  const debouncedFetchTags = useDebouncedCallback(fetchTags, 300);

  const handleTagClick = (tag: Tag) => {
    setTagIDs((prevActiveTags) =>
      prevActiveTags.some((tagID) => tagID === tag.ID)
        ? prevActiveTags.filter((tagID) => tagID !== tag.ID)
        : [...prevActiveTags, tag.ID]
    );
  };

  const handleMoreClick = (category: TagCategory) => {
    const categoryName = category.name;
    setCategoryTagLimits((prevLimits) => ({
      ...prevLimits,
      [categoryName]: (prevLimits[categoryName] || 0) + 20,
    }));
  };

  const resetActiveTags = () => setTagIDs([]);

  useEffect(() => {
    debouncedFetchTags();
  }, [searchQuery, tagIDs, tagQuery, activeEntityIDs]);

  useEffect(() => {
    fetchTags(false);
  }, [categoryTagLimits]);

  return (
    <div
      className={`absolute top-[60px] right-0 flex flex-col items-start w-[474px] h-[calc(100%-60px)] bg-dark overflow-auto`}
    >
      <div className="bg-light w-full py-[8px] px-[20px] tpg-c2 flex justify-between h-8 items-center">
        <div className="flex">
          <span className="text-tpg_base">Облако тегов</span>
          <ContextMenuWrapper
            trigger={'click'}
            contextMenu={[
              {
                title: ALL_TAGS_TITLE,
                onClick: () => setActiveCategory(ALL_TAGS_TITLE),
              },
              ...categoryOptions,
            ]}
          >
            <div className="text-main_product hover:text-bright_product cursor-pointer">
              &nbsp; {activeCategory ? activeCategory : 'все'}
            </div>
          </ContextMenuWrapper>
        </div>
        {!!tagIDs.length && (
          <span
            className="tpg-c2 text-bright_product cursor-pointer"
            onClick={resetActiveTags}
          >
            Сбросить
          </span>
        )}
      </div>
      <div className="p-4 w-full">
        <TextInput
          value={tagQuery}
          onChange={setTagQuery}
          placeholder="Поиск по названию тега"
          searchIcon
        />
        <div className="pt-4">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : (
            filteredCategories.map((category, idx) => (
              <EntityFieldsGroup key={idx} title={category.name}>
                <div className="pb-4 flex flex-wrap gap-2">
                  {category.tags.map((tag) => (
                    <DocumentTag
                      key={`document-tag-${tag.ID}`}
                      tag={tag}
                      onClick={() => handleTagClick(tag)}
                      isActive={tagIDs.some((id) => id === tag.ID)}
                    />
                  ))}
                  {!!category.moreCount && (
                    <DocumentTag
                      tag={{
                        ID: 0,
                        value: 'Больше',
                        count: category.moreCount,
                      }}
                      onClick={() => handleMoreClick(category)}
                      isActive={false}
                      isMoreTag
                    />
                  )}
                </div>
                {tags && idx !== tags.categories.length - 1 && (
                  <div className="h-[2px] bg-bright rounded-[11px] mb-4" />
                )}
              </EntityFieldsGroup>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
