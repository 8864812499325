import { Option } from 'interfaces';
import {
  GeocoderFeature,
  GeocoderFeatureSubtype,
  GeocoderPlaceFeature,
  GeocodeSearchGeoJSONResponse,
} from 'interfaces/geocoder';

import { Coordinates } from '../types';

export const getGeocoderFeature = <T = GeocoderFeature>(
  subtype: GeocoderFeature['properties']['subtype'],
  coordinates: Coordinates,
  properties?: Partial<GeocoderFeature['properties']>,
  coordinatesOrder: 'latLng' | 'lngLat' = 'latLng'
) =>
  ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates:
        coordinatesOrder === 'latLng' ? coordinates : coordinates.reverse(),
    },
    properties: { ...properties, subtype: subtype },
  } as T);

export const getOptionsFromGeocoderGeoJSONResponse = (
  response: GeocodeSearchGeoJSONResponse
): Option<GeocoderPlaceFeature>[] =>
  response.features.map((feature) => ({
    id: feature.id,
    value: {
      ...feature,
      properties: {
        ...feature.properties,
        subtype: GeocoderFeatureSubtype.PLACE,
      },
    },
    label: feature.properties.name,
  })) ?? [];
