import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { searchEntities } from 'api/entities';
import { predefinedTemplates } from 'constants/entities';
import { errorMessages } from 'constants/errors';
import { useAppSelector } from 'hooks';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { DocumentNode } from 'types/documents';
import { EntityListResponse } from 'types/entities';
import { useDebouncedCallback } from 'use-debounce';

import { Loader, TextInput } from 'components/ui';
import { notify } from 'utils';

import DocumentsTree from './DocumentTree';

export interface DocumentSidebarProps {
  activeNodes: DocumentNode[];
  setActiveNodes: Dispatch<SetStateAction<DocumentNode[]>>;
  resetActiveDocuments: () => void;
}

export const DocumentSidebar: FC<DocumentSidebarProps> = ({
  activeNodes,
  setActiveNodes,
  resetActiveDocuments,
}) => {
  const directoryTemplateId = useAppSelector((state) =>
    predefinedTemplateIdSelector(state, predefinedTemplates.DOCUMENT_DIRECTORY)
  );
  const documentTemplateId = useAppSelector((state) =>
    predefinedTemplateIdSelector(state, predefinedTemplates.DOCUMENT)
  );

  const [res, setRes] = useState<EntityListResponse>();
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchDocuments = async (query: string) => {
    setIsLoading(true);
    try {
      if (!directoryTemplateId || !documentTemplateId) return;
      const response = await searchEntities({
        maxDepth: 2,
        templateIDs: [directoryTemplateId, documentTemplateId],
        query,
      });
      setRes(response);
    } catch (err) {
      notify.error(errorMessages.GET_DOCUMENTS_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchDocuments = useDebouncedCallback((query) => {
    fetchDocuments(query);
  }, 300);

  useEffect(() => {
    debouncedFetchDocuments(search);
  }, [
    search,
    debouncedFetchDocuments,
    directoryTemplateId,
    documentTemplateId,
  ]);

  return (
    <div
      className={`absolute w-[474px] !h-[calc(100vh-100px)] bg-dark top-[60px] flex flex-col items-start`}
    >
      <div className="flex flex-row justify-between items-center bg-light pl-4 pr-4 h-8 w-full tpg-c2">
        <span className="text-tpg_base">Документы</span>
        {activeNodes.length !== 0 && (
          <span
            className="tpg-c2 text-bright_product cursor-pointer"
            onClick={resetActiveDocuments}
          >
            Сбросить
          </span>
        )}
      </div>
      <div className="w-full h-full pb-12">
        <TextInput
          value={search}
          onChange={setSearch}
          searchIcon
          placeholder="Начните поиск по названию документа"
          classNames={{ container: 'p-4' }}
        />
        {!isLoading && res ? (
          <DocumentsTree
            data={res}
            activeNodes={activeNodes}
            setActiveNodes={setActiveNodes}
            search={search}
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
