import { Position } from '@turf/turf';
import { MapboxDrawConstants } from 'constants/mapDraw';
import { DrawFeatureSubtypes } from 'types';
import { DrawModeCircleFeature, DrawModeCommonFeature } from 'types';

export function createVertex(
  parentId: string | number,
  coordinates: Position,
  path: any,
  selected: boolean
) {
  return {
    type: MapboxDrawConstants.geojsonTypes.FEATURE,
    properties: {
      meta: MapboxDrawConstants.meta.VERTEX,
      parent: parentId,
      coord_path: path,
      active: selected
        ? MapboxDrawConstants.activeStates.ACTIVE
        : MapboxDrawConstants.activeStates.INACTIVE,
    },
    geometry: {
      type: MapboxDrawConstants.geojsonTypes.POINT,
      coordinates: coordinates,
    },
  };
}

export function createSupplementaryPointsForCircle(
  drawFeature: DrawModeCommonFeature | DrawModeCircleFeature
) {
  const { properties, geometry } = drawFeature;

  if (properties.user_subtype !== DrawFeatureSubtypes.CIRCLE) return null;

  const supplementaryPoints = [];
  const vertices = geometry.coordinates[0].slice(0, -1);

  for (
    let index = 0;
    index < vertices.length;
    index += Math.round(vertices.length / 4)
  ) {
    supplementaryPoints.push(
      createVertex(properties.id, vertices[index], `0.${index}`, false)
    );
  }

  return supplementaryPoints;
}
