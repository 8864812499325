import { FC, useState } from 'react';
import { bulkUpdateEntities } from 'api/entities';
import { mapEntityParams, predefinedTemplates } from 'constants/entities';
import { errorMessages } from 'constants/errors';
import { opacityOptions } from 'constants/map';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ISelectOption } from 'interfaces';
import { getMapEntityChildrenThunk } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import {
  entityCountersMapSelector,
  predefinedTemplateSelector,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { EntityStorages, PredefinedTemplate } from 'types';

import {
  Button,
  ColorPiker,
  DatePicker,
  Modal,
  Select,
  TextArea,
  TextInput,
} from 'components/ui';
import { formatDate, notify } from 'utils';
import { getSelectOptionsByMapEntityParam } from 'utils/entity';

export interface UpdateLayerChildrenDetailsProps {
  entityID: number;
  onClose: () => void;
}

const allowedParams: mapEntityParams[] = [
  mapEntityParams.NAME,
  mapEntityParams.TYPE,
  mapEntityParams.STATUS,
  mapEntityParams.DATE,
  mapEntityParams.DESCRIPTION,
  mapEntityParams.OPACITY,
  mapEntityParams.COLOR,
  mapEntityParams.SOURCE,
  mapEntityParams.RELIABILITY,
  mapEntityParams.RELEVANCE,
];

const specifyDateParamName = (p: mapEntityParams) =>
  p === mapEntityParams.DATE ? 'Дата обнаружения' : p;

const allowedParamsSelectOptions: ISelectOption<mapEntityParams>[] =
  allowedParams.map((p) => ({
    label: specifyDateParamName(p),
    value: p,
  }));

const UpdateLayerChildrenDetails: FC<UpdateLayerChildrenDetailsProps> = ({
  entityID,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const mapObjectTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_OBJECT)
  );
  const entitiesCountersMap = useAppSelector(entityCountersMapSelector);

  const [selectedParam, setSelectedParam] = useState(mapEntityParams.NAME);
  const [isLoading, setIsLoading] = useState(false);
  const [newValue, setNewValue] = useState('');

  const entityChildrenCount = entitiesCountersMap[entityID];

  const getObjectSlugInCorrectForm = () =>
    entityChildrenCount === 1 ? 'объекта' : 'объектов';

  const saveButtonLabel =
    `Изменить для ${entityChildrenCount} ` + getObjectSlugInCorrectForm();

  const successfulUpdateMessage =
    `Параметр ${specifyDateParamName(
      selectedParam
    )} изменен у ${entityChildrenCount} ` + getObjectSlugInCorrectForm();

  const isUpdateAllowed = () => {
    if (!selectedParam) {
      return false;
    }

    if (selectedParam === mapEntityParams.DESCRIPTION) {
      return true;
    }

    if (selectedParam === mapEntityParams.OPACITY) {
      return newValue !== '';
    }

    return !!newValue;
  };

  const getSelectOptions = (param: mapEntityParams): ISelectOption[] => {
    if (param == mapEntityParams.OPACITY) {
      return opacityOptions;
    }

    if (mapObjectTemplate) {
      return getSelectOptionsByMapEntityParam(param, mapObjectTemplate);
    }

    return [];
  };

  const handleSelectParam = (v: ISelectOption<mapEntityParams>['value']) => {
    if (v !== selectedParam) {
      setNewValue('');
    }
    setSelectedParam(v);
  };

  const getUpdatedParameterID = (
    param: mapEntityParams,
    mapObjectTemplate: PredefinedTemplate
  ) => {
    if (param === mapEntityParams.NAME) {
      return 0; // use ParameterID = 0 if you want to update entities title
    }
    const parameter = mapObjectTemplate.parameters.find(
      (param) => param.title === selectedParam
    );
    return parameter?.id;
  };

  const getNewValue = () => {
    if (selectedParam === mapEntityParams.DATE && newValue !== '') {
      return formatDate(new Date(newValue));
    }
    return newValue;
  };

  const handleUpdate = async () => {
    if (!mapObjectTemplate || !selectedParam) {
      return;
    }

    const parameterID = getUpdatedParameterID(selectedParam, mapObjectTemplate);

    if (parameterID === undefined) {
      return;
    }

    const payload = {
      parentEntityID: entityID,
      value: getNewValue(),
      updateTemplateIDs: [mapObjectTemplate.template.id],
      parameterID,
    };

    setIsLoading(true);
    await bulkUpdateEntities(payload)
      .then(() => {
        notify.success(successfulUpdateMessage);
        dispatch(
          getMapEntityChildrenThunk({
            parentEntityID: Number(entityID),
            maxDepth: 9999,
            storage: EntityStorages.FULL,
            keepState: true,
          })
        );
      })
      .catch(() => notify.error(errorMessages.ENTITIES_UPDATE_ERROR))
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  const getParamEditableField = (param: mapEntityParams) => {
    switch (param) {
      case mapEntityParams.NAME:
        return (
          <TextInput
            value={newValue}
            placeholder="Введите значение"
            autoFocus
            onChange={setNewValue}
            classNames={{ container: 'w-full' }}
          />
        );
      case mapEntityParams.DESCRIPTION:
        return (
          <TextArea
            value={newValue}
            placeholder="Введите описание"
            theme="light"
            onChange={setNewValue}
            rows={5}
          />
        );
      case mapEntityParams.DATE:
        return (
          <DatePicker
            selected={newValue ? new Date(newValue) : null}
            onChange={(v) => setNewValue(v ? v.toString() : '')}
          />
        );
      case mapEntityParams.COLOR:
        return <ColorPiker initialColor={newValue} onChange={setNewValue} />;
      case mapEntityParams.OPACITY:
      case mapEntityParams.TYPE:
      case mapEntityParams.STATUS:
      case mapEntityParams.SOURCE:
      case mapEntityParams.RELIABILITY:
      case mapEntityParams.RELEVANCE:
        return (
          <Select
            value={newValue}
            options={getSelectOptions(param)}
            onSelect={setNewValue}
            theme="light"
            withEmpty={false}
          />
        );
    }
  };

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12">
        <div className="flex flex-col gap-4 flex-1 w-full">
          <div className="tpg-h4 flex justify-center pb-4">
            Массовое изменение
          </div>
          <Select
            placeholder="Выберите параметр"
            options={allowedParamsSelectOptions}
            value={selectedParam}
            onSelect={handleSelectParam}
            theme="light"
            withSearch
            withEmpty={false}
          />
          {selectedParam && getParamEditableField(selectedParam)}
          <Button
            className="w-full"
            title={saveButtonLabel}
            onClick={handleUpdate}
            isLoading={isLoading}
            disabled={!isUpdateAllowed()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateLayerChildrenDetails;
