import { useMemo } from 'react';
import { mapEntityParams, predefinedTemplates } from 'constants/entities';
import { MAP_ENTITIES_LOADER } from 'constants/loaders';
import { MAP_TOOLS_INFO_POPUP } from 'constants/modals';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapDraw } from 'hooks/useMapDraw';
import { ReactComponent as CrossIcon } from 'images/newIcons/cross.svg';
import { ReactComponent as ObjectIcon } from 'images/newIcons/objectsPassive.svg';
import { drawFeatureSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice/selectors';
import { stateActions } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice';
import { drawToolSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice/selectors';
import { getMapInscribedEntities } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { predefinedTemplateSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { loaderSelector } from 'store/slices/service/loadersSlice/selectors';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { MapCreateTools, MapDrawModes } from 'types';

import { IconButton } from 'components/ui/IconButton';

import { getToolValues, TOOL_ICONS, TOOL_INFOS } from './utils';

export const MapToolsInfoPopup = () => {
  const objectTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_OBJECT)
  );
  const drawTool = useAppSelector(drawToolSelector);
  const drawFeature = useAppSelector(drawFeatureSelector);
  const isLoadingEntities = useAppSelector((state) =>
    loaderSelector(state, MAP_ENTITIES_LOADER)
  );

  const dispatch = useAppDispatch();
  const { handleDrawStop, handleDrawCancel, handleDrawCreate } = useMapDraw();

  const geometryParameterId = useMemo(
    () =>
      objectTemplate?.parameters.find(
        (parameter) => parameter.title === mapEntityParams.GEOMETRY
      )?.id,
    [objectTemplate]
  );

  const toolInfo = TOOL_INFOS[drawTool];
  const toolIcon = TOOL_ICONS[drawTool];
  const toolValues = getToolValues(drawTool, drawFeature);

  const canShowObjectives =
    drawTool === MapCreateTools.create_polygon ||
    drawTool === MapCreateTools.create_circle;

  const handleClose = () => {
    handleDrawCancel();

    dispatch(stateActions.setDrawTool(MapDrawModes.simple_select));
    dispatch(modalsActions.removeModal(MAP_TOOLS_INFO_POPUP));
  };

  const handleShowObjectives = () => {
    handleDrawStop();

    dispatch(
      getMapInscribedEntities({
        maxDepth: 9999,
        filterCriteria: [
          {
            parameterID: geometryParameterId ?? 0,
            values: [drawFeature?.geometry ?? ''],
          },
        ],
      })
    );
  };

  return (
    <div className="absolute w-[410px] top-6 right-6 z-10 bg-dark border-1 border-tpg_light rounded-[10px] overflow-hidden font-proxima_nova">
      <IconButton
        icon={<CrossIcon />}
        classNames={{ container: 'absolute top-4 right-4' }}
        onClick={handleClose}
      />
      <div className="p-6 flex flex-col gap-6">
        <div>
          <p className="tpg-b1 text-tpg_title">{toolInfo.title}</p>
          <p className="mt-1 tpg-c1 text-tpg_light">{toolInfo.description}</p>
        </div>
        {toolValues?.map((value) => (
          <div key={value.label}>
            <p className="tpg-h4 text-tpg_title">{value.value}</p>
            <p className="mt-1 tpg-c2 text-tpg_base">{value.label}</p>
          </div>
        ))}
      </div>
      <div className="sticky top-full py-2 px-4 flex gap-3 justify-end bg-light">
        {canShowObjectives && (
          <IconButton
            icon={<ObjectIcon className="w-4 h-4" />}
            title="Показать цели"
            disabled={
              !drawFeature ||
              !drawFeature.properties.hasMinimumVertexCount ||
              !geometryParameterId ||
              !!isLoadingEntities
            }
            onClick={handleShowObjectives}
          />
        )}
        <IconButton
          icon={toolIcon}
          title="Создать объект"
          disabled={
            !drawFeature || !drawFeature.properties.hasMinimumVertexCount
          }
          onClick={handleDrawCreate}
        />
      </div>
    </div>
  );
};
