import { ZHD_LINE_LAYER, ZHD_SYMBOL_LAYER } from 'constants/map';
import {
  LineLayerSpecification,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';

import { labelLayout } from '../common';

export const RAILROADS_URL = process.env.REACT_APP_RAILROADS_URL ?? '';

export const railroadsSource: VectorSourceSpecification = {
  type: 'vector',
  id: 'railroad-vector-source',
  url: RAILROADS_URL,
};

export const railroadsLineLayer: LineLayerSpecification = {
  id: ZHD_LINE_LAYER,
  type: 'line',
  source: 'railroad-vector-source',
  'source-layer': 'dissolvedv2',
  paint: {
    'line-width': 2.5,
    'line-color': '#D3D3D3',
  },
};

export const railroadsSymbolLayer: SymbolLayerSpecification = {
  id: ZHD_SYMBOL_LAYER,
  type: 'symbol',
  source: 'railroad-vector-source',
  'source-layer': 'dissolvedv2',
  layout: {
    ...labelLayout,
    'text-field': ['get', 'NAME'],
    'text-size': 9,
  },
  paint: {
    'text-color': '#c0c0c0',
    'text-halo-color': '#232222',
    'text-halo-width': 10,
  },
};
