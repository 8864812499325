import { ReactComponent as ArrowIcon } from 'images/newIcons/down.svg';

import { EntityNode } from './types';

export const renderNodeArrow = (node: EntityNode) => {
  if (node.data.isFolder) {
    return (
      <div className="[&>svg>path]:fill-tpg_base group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product m-auto self-center w-4 h-4">
        <ArrowIcon />
      </div>
    );
  }

  return null;
};
