import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';

interface StaticSourceProps {
  id: string;
}

export const StaticSource: FC<StaticSourceProps> = ({ id }) => (
  <Source type="geojson" data={{ type: 'FeatureCollection', features: [] }}>
    <Layer id={id} type="symbol" />
  </Source>
);
