import { combineReducers } from '@reduxjs/toolkit';

import { boundaryGridReducer } from './boundaryGridSlice';
import { documentCoordinatesReducer } from './documentCoordinateSlice';
import { drawReducer } from './drawSlice';
import { geocoderReducer } from './geocoderSlice';
import { measurementReducer } from './measurementSlice';
import { stateReducer } from './stateSlice';

export const toolsReducer = combineReducers({
  state: stateReducer,
  geocoder: geocoderReducer,
  draw: drawReducer,
  measurement: measurementReducer,
  boundaryGrid: boundaryGridReducer,
  documentCoordinates: documentCoordinatesReducer,
});
