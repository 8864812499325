import { FC } from 'react';
import { Control, Controller, FieldArrayWithId } from 'react-hook-form';
import { coordinateSystemTitleMap } from 'constants/map';
import { CoordinateSystems } from 'types';

import { TextInput } from 'components/ui';

import { FormData } from './types';

interface CommonFieldsProps {
  coordinateSystem: CoordinateSystems | null;
  control: Control<FormData, any>;
  fields: FieldArrayWithId<FormData, 'vertexes', 'id'>[];
}

export const CommonFormFields: FC<CommonFieldsProps> = ({
  coordinateSystem,
  control,
  fields,
}) => (
  <>
    {fields.map((field, index) => (
      <Controller
        key={field.id}
        control={control}
        name={`vertexes.${index}`}
        render={({ field }) => (
          <TextInput
            value={field.value.value}
            placeholder="Координата вершины"
            onChange={(e) => field.onChange({ value: e })}
          >
            {coordinateSystem && (
              <span className="tpg-c1 text-tpg_base">
                {coordinateSystemTitleMap[coordinateSystem] ?? ''}
              </span>
            )}
          </TextInput>
        )}
      />
    ))}
  </>
);
