const SQUARE_10_KM = 10000000;
const FLAT_10_KM = 10000;

export const getMeasurement = (number: number, squared = false) => {
  const KM10 = squared ? SQUARE_10_KM : FLAT_10_KM;

  return number < KM10
    ? { value: `${number.toFixed(2)}`, unit: `м${squared ? '²' : ''}` }
    : {
        value: `${(number / (squared ? 1000 * 1000 : 1000)).toFixed(2)}`,
        unit: ` км${squared ? '²' : ''}`,
      };
};
