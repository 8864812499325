import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDebouncedCallback } from 'use-debounce';

import { getWordDeclension, notify } from 'utils';

import { getDocuments } from '../../../api/documents';
import { errorMessages } from '../../../constants/errors';
import { NOTHING_WAS_FOUND_MESSAGE } from '../../../constants/routes';
import {
  DirectoryRes,
  DocumentRes,
  SearchDocumentsReq,
} from '../../../interfaces/documents';
import { DocumentFilters } from '../../../routes/Documents';
import { Loader } from '../../ui';

import { DropdownFolder } from './DropdownFolder';

interface DocumentListProps extends DocumentFilters {
  onDocumentClick: (d: DocumentRes) => void;
  className: string;
  width: number; // for dynamic width
}

const DOC_BATCH_SIZE = 20;

export const DocumentList: FC<DocumentListProps> = ({
  onDocumentClick,
  className,
  width,
  searchQuery,
  tagIDs,
  activeEntityIDs,
}) => {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(false);

  // directories are used to show documents in nested way (grouped by directories)
  // this is rendered when no filters or entityID filter is applied
  const [directories, setDirectories] = useState<DirectoryRes[]>([]);

  // documents are used to show documents in flat way
  // this is rendered when any filter (by tag or by query) is applied
  const [documents, setDocuments] = useState<DocumentRes[]>([]);

  const hasMore = useMemo(
    () => total > 0 && (documents.length < total || directories.length < total),
    [total, documents, directories]
  );

  const buildSearchReq = useCallback(
    (page: number): SearchDocumentsReq => ({
      tagIDs: tagIDs,
      activeEntityIDs: activeEntityIDs,
      query: searchQuery,
      limit: DOC_BATCH_SIZE,
      offset: page * DOC_BATCH_SIZE,
    }),
    [searchQuery, tagIDs, activeEntityIDs]
  );

  const loadDocuments = async (req: SearchDocumentsReq) => {
    if (!req.offset) {
      setPending(true);
    }

    try {
      const response = await getDocuments(req);
      setTotal(response.total);

      if (response.documents.length > 0) {
        setDocuments((prev) => [...prev, ...response.documents]);
      } else if (response.directories.length > 0) {
        setDirectories((prev) => [...prev, ...response.directories]);
      }
    } catch (error) {
      notify.error(errorMessages.GET_DOCUMENTS_ERROR);
    } finally {
      setPending(false);
    }
  };

  const debouncedLoadDocuments = useDebouncedCallback(loadDocuments, 300);

  const renderContent = () => {
    if (pending) return <Loader />;
    if (documents.length > 0) {
      return (
        <DropdownFolder
          title={`Найдено: ${total} ${getWordDeclension('документ', total)}`}
          documents={documents}
          onDocumentClick={onDocumentClick}
          hideArrow
        />
      );
    }
    if (directories.length > 0) {
      return directories.map((dir) => (
        <DropdownFolder
          key={dir.ID}
          title={dir.fullPath}
          documents={dir.documents}
          onDocumentClick={onDocumentClick}
        />
      ));
    }
    return NOTHING_WAS_FOUND_MESSAGE;
  };

  const onLoadMore = (p: number) =>
    !pending && p * DOC_BATCH_SIZE < total && setPage(p);

  useEffect(() => {
    setDirectories([]);
    setDocuments([]);
    setPage(0);
    debouncedLoadDocuments(buildSearchReq(0));
  }, [tagIDs, searchQuery, activeEntityIDs, buildSearchReq]);

  useEffect(() => {
    page > 0 && loadDocuments(buildSearchReq(page));
  }, [page, buildSearchReq]);

  return (
    <div className={className} style={{ maxWidth: width }}>
      <InfiniteScroll
        initialLoad={false}
        useWindow={false}
        className="h-full flex flex-col gap-[24px] p-[24px]"
        hasMore={hasMore}
        loadMore={onLoadMore}
      >
        {renderContent()}
      </InfiniteScroll>
    </div>
  );
};
