import { DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import {
  CIRCLE_POLYGON_VERTEX_COUNT,
  MapboxDrawCustomEvents,
} from 'constants/mapDraw';
import { DrawCircleModeState } from 'interfaces';
import { DrawFeatureSubtypes, MeasureSystems } from 'types';

const DrawCircleMode: DrawCustomMode = {
  onSetup: function (opts) {
    const polygon = this.newFeature({
      type: 'Feature',
      properties: {
        subtype: DrawFeatureSubtypes.CIRCLE,
        vertexCount: 0,
        hasMinimumVertexCount: false,
        center: [],
        radiusInM: 0,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    });

    this.clearSelectedFeatures();
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    this.addFeature(polygon);

    return {
      polygon,
    };
  },
  onClick: function (state: DrawCircleModeState, e) {
    if (e.originalEvent.button === 2) {
      return;
    }

    const feature = state.polygon;
    const currentCenter = feature.properties.center;

    feature.properties.vertexCount = CIRCLE_POLYGON_VERTEX_COUNT;
    feature.properties.hasMinimumVertexCount = true;

    if (currentCenter.length === 0) {
      return (state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat]);
    } else {
      return this.changeMode('simple_select', {
        featureIds: [state.polygon.id],
      });
    }
  },
  onTap: function (state: DrawCircleModeState, e) {
    const feature = state.polygon;
    const currentCenter = feature.properties.center;

    feature.properties.vertexCount = CIRCLE_POLYGON_VERTEX_COUNT;
    feature.properties.hasMinimumVertexCount = true;

    if (currentCenter.length === 0) {
      return (state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat]);
    } else {
      return this.changeMode('simple_select', {
        featureIds: [state.polygon.id],
      });
    }
  },
  onMouseMove: function (state: DrawCircleModeState, e) {
    const center = state.polygon.properties.center;

    if (center.length > 0) {
      const distanceInM = distance(
        point(center),
        point([e.lngLat.lng, e.lngLat.lat]),
        { units: MeasureSystems.METERS }
      );

      const distanceInKm = distanceInM / 1000;
      const circleFeature = circle(center, distanceInKm);

      state.polygon.incomingCoords(circleFeature.geometry.coordinates);
      state.polygon.properties.radiusInM = distanceInM;
    }

    const featureTarget = state.polygon.toGeoJSON();

    this.map.fire(MapboxDrawCustomEvents.MOUSE_MOVE, { ...e, featureTarget });
  },
  onTouchMove: function (state: DrawCircleModeState, e) {
    const center = state.polygon.properties.center;

    if (center.length > 0) {
      const distanceInM = distance(
        point(center),
        point([e.lngLat.lng, e.lngLat.lat]),
        { units: MeasureSystems.METERS }
      );

      const distanceInKm = distanceInM / 1000;
      const circleFeature = circle(center, distanceInKm);

      state.polygon.incomingCoords(circleFeature.geometry.coordinates);
      state.polygon.properties.radiusInM = distanceInM;
    }
  },
  onStop: function (state: DrawCircleModeState) {
    const featureTarget = state.polygon.toGeoJSON();

    if (state.polygon.isValid()) {
      const polygonGeoJson = state.polygon.toGeoJSON();

      this.map.fire('draw.create', {
        features: [polygonGeoJson],
      });
    } else {
      this.deleteFeature(state.polygon.id, { silent: true });
    }

    this.map.fire(MapboxDrawCustomEvents.STOP, { featureTarget });
  },
  toDisplayFeatures: function (state: DrawCircleModeState, geojson, display) {
    display(geojson);
  },
};

export { DrawCircleMode };
