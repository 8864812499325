import { FC, memo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useCSVReader } from 'react-papaparse';
import { convertCoordinates } from 'api/converter';
import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { convertValidationErrors } from 'constants/errors';
import { ReactComponent as ImportIcon } from 'images/newIcons/import.svg';
import { ICoordinates } from 'interfaces';
import _ from 'lodash';
import { CoordinateSystems } from 'types';

import { getCoordinatesFromDecimalString, notify } from 'utils';

const CsvConverter: FC = () => {
  const { CSVReader } = useCSVReader();
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const REQUEST_LIMIT = 10;

  async function convertCoordinate(wgs: number[] | null) {
    if (wgs) {
      try {
        setLoading(true);
        const params: ICoordinates = {
          b: wgs[0],
          l: wgs[1],
          h: DEFAULT_CONVERTER_HEIGHT,
        };

        const data = await convertCoordinates(
          CoordinateSystems.WGS,
          CoordinateSystems.SK42,
          params
        );

        if (data.to === CoordinateSystems.SK42) {
          const sk42: ICoordinates = {
            x: data.payload.x,
            y: data.payload.y,
            h: data.payload.h,
          };
          setCsvData((csvData) => [
            ...csvData,
            [`${wgs[0]}, ${wgs[1]}`, `X:${sk42['x']}, Y:${sk42['y']}`],
          ]);
        }
      } catch (e) {
        notify.error(convertValidationErrors.GET_RESULT_ERROR);
      } finally {
        setLoading(false);
      }
    }
  }

  const convertCoordinatesBulk = async (
    coordinatesToConvert: (number[] | null)[]
  ) => {
    const groupToConvert = _.chunk(coordinatesToConvert, REQUEST_LIMIT);

    for (const arr of groupToConvert) {
      await Promise.all(arr.map(convertCoordinate));
    }
  };

  const onUploadCsv = async (csvFile: any) => {
    const csv: string[][] = csvFile.data;
    const data = csv.reduce((acc, curr) => acc.concat(curr), []);
    const coordinatesToConvert = data.map(getCoordinatesFromDecimalString);
    await convertCoordinatesBulk(coordinatesToConvert);
  };

  return (
    <>
      <CSVReader
        onUploadAccepted={onUploadCsv}
        config={{ worker: true }}
        noDrag
      >
        {({ getRootProps, acceptedFile }: any) => (
          <div {...getRootProps()}>
            {acceptedFile ? (
              <div />
            ) : (
              <div className="icon-container">
                <ImportIcon />
              </div>
            )}
          </div>
        )}
      </CSVReader>
      {!isLoading && (
        <>
          <CSVLink
            filename={'coordinates.csv'}
            headers={['WGS', 'SK42']}
            data={csvData}
          >
            Скачать csv
          </CSVLink>
        </>
      )}
    </>
  );
};
export default memo(CsvConverter);
