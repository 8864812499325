import { createSlice } from '@reduxjs/toolkit';

import { TOOLS_REDUCER_NAMES } from '../constants';

import { DrawState, SetDrawFeatureAction } from './types';

const initialState: DrawState = {
  drawFeature: null,
};

const drawSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.DRAW,
  initialState: initialState,
  reducers: {
    setDrawFeature(state, action: SetDrawFeatureAction) {
      state.drawFeature = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: drawActions, reducer: drawReducer } = drawSlice;
