import { FC } from 'react';
import cn from 'classnames';
import { Option } from 'interfaces';

import { Select } from 'components/ui';

import { ToggleControl } from './ToggleControl';
import { Control } from './types';

interface DropdownControlProps extends Control {
  value: string;
  options: Option<string>[];
  showCustomCursors?: boolean;
  classNames?: { container?: string; toggle?: string };
  onSelect: (value: string) => void;
}

export const DropdownControl: FC<DropdownControlProps> = ({
  value,
  options,
  icon,
  isActive,
  showCustomCursors,
  classNames,
  onToggle,
  onSelect,
  onEscapeDown,
  onEnterDown,
}) => (
  <div className={cn(classNames?.container)}>
    <ToggleControl
      icon={icon}
      isActive={isActive}
      className={classNames?.toggle}
      onToggle={onToggle}
      onEscapeDown={onEscapeDown}
      onEnterDown={onEnterDown}
    />
    {isActive && (
      <div className="relative">
        <Select
          classNames={{
            container: '!absolute !w-[200px] top-4 right-full',
            body: cn(showCustomCursors && 'cursor-custom-default'),
            options: cn(
              showCustomCursors && '[&>li>div]:!cursor-custom-default'
            ),
          }}
          withSearch={true}
          options={options}
          value={value}
          onSelect={onSelect}
          theme="light"
          withEmpty={false}
        />
      </div>
    )}
  </div>
);
