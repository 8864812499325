import {
  ELEVATION_CONTOUR_LAYER,
  ELEVATION_HILLSHADE_LAYER,
  ELEVATION_LANDCOVER_LAYER,
} from 'constants/map';
import {
  CircleLayerSpecification,
  FillLayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
} from 'mapbox-gl';
import { LayerPartialSpecification } from 'types';

export const pointConfig: LayerPartialSpecification<SymbolLayerSpecification> =
  {
    type: 'symbol',
    layout: {},
    paint: {},
  };

export const polygonFillConfig: LayerPartialSpecification<FillLayerSpecification> =
  {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': ['get', 'fillColor'],
      'fill-opacity': ['get', 'opacity'],
    },
  };

export const lineConfig: LayerPartialSpecification<LineLayerSpecification> = {
  type: 'line',
  layout: {
    'line-cap': 'butt',
    'line-join': 'miter',
  },
  paint: {
    'line-color': ['get', 'lineColor'],
    'line-opacity': 0.7,
    'line-width': ['get', 'width'],
  },
};

export const elevationLayerConfig = [
  {
    id: ELEVATION_LANDCOVER_LAYER,
    source: 'mapbox-terrain',
    'source-layer': 'landcover',
    type: 'fill',
    paint: {
      'fill-color': 'rgba(141,201,166, 0.3)',
      'fill-outline-color': 'rgba(141,201,166, 0.3)',
    },
  },
  {
    id: ELEVATION_HILLSHADE_LAYER,
    source: 'mapbox-terrain',
    'source-layer': 'hillshade',
    type: 'fill',
    paint: {
      'fill-color': 'rgba(211,47,47, 0.3)',
      'fill-outline-color': 'rgba(211,47,47, 0.3)',
    },
  },
  {
    id: ELEVATION_CONTOUR_LAYER,
    source: 'mapbox-terrain',
    'source-layer': 'contour',
    type: 'line',
    paint: {
      'line-color': '#ffffff',
    },
  },
];

export const drawStyles = [
  // line type
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#8dbdf5',
      'line-width': 5,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#8dbdf5',
      'line-dasharray': [0.2, 2],
      'line-width': 5,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#8dbdf5',
      'line-width': 5,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#8dbdf5',
      'line-dasharray': [0.2, 2],
      'line-width': 5,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#8dbdf5',
      'line-width': 5,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#8dbdf5',
      'line-width': 5,
    },
  },
  // fill type
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': 'rgba(141, 189, 245, 0.7)',
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': 'rgba(141, 189, 245, 0.7)',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': 'rgba(141, 189, 245, 0.7)',
      'fill-outline-color': 'rgba(245, 235, 141, 0.7)',
    },
  },
  // circle type
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': 'rgba(245, 235, 141, 0.7)',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#3bb2d0',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];

export const labelLayout: SymbolLayerSpecification['layout'] = {
  'text-field': ['get', 'title'],
  'text-font': ['Open Sans Bold'],
  'text-size': 12,
  'text-offset': [0, 1.2],
  'text-anchor': 'top',
  'text-allow-overlap': false,
};

export const labelPaint: SymbolLayerSpecification['paint'] = {
  'text-color': '#E3E6F1',
  'text-halo-color': '#0A0C17',
  'text-halo-width': 2,
};

export const labelConfig: LayerPartialSpecification<SymbolLayerSpecification> =
  {
    type: 'symbol',
    layout: labelLayout,
    paint: labelPaint,
  };

export const clusterConfig: LayerPartialSpecification<CircleLayerSpecification> =
  {
    type: 'circle',
    paint: {
      'circle-color': '#1D53C3',
      'circle-stroke-width': 1,
      'circle-stroke-color': 'white',
      'circle-radius': 12,
    },
    filter: ['has', 'point_count'],
  };

export const clusterCounterConfig: LayerPartialSpecification<SymbolLayerSpecification> =
  {
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['Open Sans Semibold'],
      'text-size': 14,
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': 'white',
    },
  };

export const clusterLabelConfig: LayerPartialSpecification<SymbolLayerSpecification> =
  {
    type: 'symbol',
    paint: labelPaint,
    layout: {
      ...labelLayout,
      'text-field': ['get', 'label'],
    },
    filter: ['has', 'point_count'],
  };

export const gcFrontlineLayer = (
  lineColor = '#000000'
): LayerPartialSpecification<LineLayerSpecification> => ({
  type: 'line',
  paint: {
    'line-width': 4,
    'line-color': lineColor,
  },
});

export const gcPlacemarksLayer: LayerPartialSpecification<SymbolLayerSpecification> =
  {
    type: 'symbol',
    layout: {
      'icon-image': ['get', 'icon_url'],
      'icon-size': 0.75,
      'icon-allow-overlap': true,
      'symbol-sort-key': 2,
    },
  };
