import { SourceProps } from 'react-map-gl';
import { GOOGLE_SATELLITE_LAYER, GOOGLE_STREETS_LAYER } from 'constants/map';
import { RasterLayerSpecification, StyleSpecification } from 'mapbox-gl';

export const googleSatelliteSource: SourceProps = {
  type: 'raster',
  tiles: ['https://mt0.google.com/vt/lyrs=s&hl=ru&x={x}&y={y}&z={z}'],
  name: 'google-satellite-source',
  id: 'google-satellite-source',
  maxzoom: 20,
  tileSize: 256,
};
export const googleSatelliteLayer: RasterLayerSpecification = {
  id: GOOGLE_SATELLITE_LAYER,
  source: 'google-satellite-source',
  type: 'raster',
  minzoom: 0,
};
export const googleLabelSource: SourceProps = {
  type: 'raster',
  tiles: ['https://mt0.google.com/vt/lyrs=h&hl=ru&x={x}&y={y}&z={z}'],
  name: 'google-streets-source',
  id: 'google-streets-source',
  tileSize: 256,
};
export const googleLabelLayer: RasterLayerSpecification = {
  id: GOOGLE_STREETS_LAYER,
  source: 'google-streets-source',
  type: 'raster',
  minzoom: 0,
};
export const googleSatelliteStyle: StyleSpecification = {
  version: 8,
  sprite: `${window.location.origin}/images/sprites/map-icons-sprite`,
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {
    'google-satellite-source': googleSatelliteSource,
  },
  layers: [googleSatelliteLayer],
};
