import { convertValidationErrors } from 'constants/errors';
import { DEFAULT_HEIGHT } from 'constants/map';
import { BLHCoordinates, ICoordinates, XYHCoordinates } from 'interfaces';
import { CoordinateSystems } from 'types';

import {
  getCoordinatesFromDecimalString,
  getCoordinatesFromDegreeString,
  notify,
} from 'utils';
import { isNumber } from 'utils/validations/number';

import { convertCoordinates } from '../api/converter';
import { DEFAULT_CONVERTER_HEIGHT } from '../constants/converter';

export interface CustomerSpecificConverterResult {
  str: string;
  magicNumberX: number;
  magicNumberY: number;
}

export interface IDegreeCoordinate {
  degrees: number;
  minutes: number;
  seconds: number;
}

export const getMagicNumbers = (x: string, y: string, clientCode: string) => {
  const clientCodeDozen = +('' + clientCode[clientCode.length - 2]); // ДЕСЯТКИ НОМЕРА КЛИЕНТА
  const clientCodeUnits = +('' + clientCode)[clientCode.length - 1]; // ЕДИНИЦЫ НОМЕРА КЛИЕНТА

  const first6DigitsX = x.substring(0, 6);
  const first6DigitsY = y.substring(0, 6);

  const ax = +x[0] + +x[1] + +x[2] + +x[3] + +x[4] + +x[5];
  const bx = ax % 10; // bx это наш остаток от суммы первых 6 цифр
  let magicNumberX = clientCodeDozen - bx; //подставляем десятки клиента - последняя цифра для X
  while (magicNumberX < 0) {
    magicNumberX += 10;
  }

  const ay = +y[0] + +y[1] + +y[2] + +y[3] + +y[4] + +y[5];
  const by = ay % 10; // by это наш остаток от суммы первых 6 цифр
  let magicNumberY = clientCodeUnits - by; //подставляем единицы клиента = последняя цифра для Y

  while (magicNumberY < 0) {
    magicNumberY += 10;
  }
  return {
    magicNumberX,
    magicNumberY,
    resultX: +(first6DigitsX + magicNumberX),
    resultY: +(first6DigitsY + magicNumberY),
  };
};

/**
 * @param clientInputValue example:
 Расположение л/с
 wgs: 48.123456, 36.123456
 ск-42: X:5366540, Y:7342266
 * @param clientCode 0000....0099
 */

export const getCustomerSpecificConverterValue: (
  clientInputValue: string,
  clientCode: string
) => Promise<CustomerSpecificConverterResult[]> = async (
  clientInputValue: string,
  clientCode: string
) => {
  const transformInputWithMagicNumber = async (arr: RegExpMatchArray) => {
    const isDegree = localStorage.getItem('wgs_degree_active') === 'true';

    if (arr.length !== 4) {
      return {
        str: 'Неверный формат ввода!!!',
        magicNumberY: 0,
        magicNumberX: 0,
      };
    } else {
      const { magicNumberX, magicNumberY, resultX, resultY } = getMagicNumbers(
        arr[2],
        arr[3],
        clientCode
      );
      const resultWgs = await convertCoordinates(
        CoordinateSystems.SK42,
        CoordinateSystems.WGS,
        {
          x: +resultX,
          y: +resultY,
          h: DEFAULT_CONVERTER_HEIGHT,
        }
      );
      if (resultWgs && resultWgs.to === CoordinateSystems.WGS) {
        const title = arr[1].toLowerCase().includes(CoordinateSystems.WGS)
          ? ''
          : arr[1];
        return isDegree
          ? {
              str:
                `${title}` +
                `WGS: \`${convertWgsDecimalToDegree(
                  resultWgs.payload.b.toFixed(6)
                )}, ${convertWgsDecimalToDegree(
                  resultWgs.payload.l.toFixed(6)
                )}\n` +
                `СК-42: \`X=${resultX}, Y=${resultY}\`\n`,
              magicNumberX,
              magicNumberY,
            }
          : {
              str:
                `${title}` +
                `WGS: \`${resultWgs.payload.b.toFixed(
                  6
                )}, ${resultWgs.payload.l.toFixed(6)}\`\n` +
                `СК-42: \`X=${resultX}, Y=${resultY}\`\n`,
              magicNumberX,
              magicNumberY,
            };
      } else {
        throw new Error(
          'Не удалось выполнить обратную конвертацию СК-42 -> WGS!'
        );
      }
    }
  };

  // regexp group 0 - ES6 just captures whole string
  // regexp group 1 (title) -> text\n OR number plus some text before WGS OR nothing
  // regexp group 2 - X coord
  // regexp group 3 - Y coord
  const matchCoordinatesFormatRegexp = new RegExp(
    '(?<=Y:.+\\n|^)([\\s\\S]*?)wgs:?.+\\n(?:ск|сk|ck|cк|sk|sк)-?42:? X=(.+), Y=(.+)',
    'ig'
  );

  if (clientInputValue.includes('°')) {
    const lines = clientInputValue.split('\n');

    const resultLines = lines.map((line) => {
      if (line.startsWith('WGS')) {
        const separatedRow = line.split(' ');
        if (separatedRow.length > 2) {
          const lat = convertWgsDegreeToDecimal(separatedRow[1]).toFixed(6);
          const lon = convertWgsDegreeToDecimal(separatedRow[2]).toFixed(6);
          return `WGS: ${lat}, ${lon}`;
        }
      }
      return line;
    });
    const inputWithConvertedDegreeString = resultLines.join('\n');
    const iter = Array.from(
      inputWithConvertedDegreeString.matchAll(matchCoordinatesFormatRegexp)
    );
    return Promise.all(iter.map(transformInputWithMagicNumber));
  } else {
    const iter = Array.from(
      clientInputValue.matchAll(matchCoordinatesFormatRegexp)
    );
    return Promise.all(iter.map(transformInputWithMagicNumber));
  }
};

export const convertWsgToSk42 = async (wsgCoordinates: [number, number]) => {
  try {
    const data = await convertCoordinates(
      CoordinateSystems.WGS,
      CoordinateSystems.SK42,
      {
        b: wsgCoordinates[1],
        l: wsgCoordinates[0],
        h: DEFAULT_CONVERTER_HEIGHT,
      }
    );

    if (data.to === CoordinateSystems.SK42) {
      return {
        x: Math.round(data?.payload?.x),
        y: Math.round(data?.payload?.y),
        h: data?.payload?.h,
      };
    }
  } catch (error) {
    notify.error(convertValidationErrors.GET_RESULT_ERROR);
  }
};

export const parseDegreeCoordinate = (
  coordinate: string
): IDegreeCoordinate => {
  const degreeIndex = coordinate.indexOf('°');
  const minutesIndex = coordinate.indexOf('′');
  const secondsIndex = coordinate.indexOf('″');

  const degrees = parseInt(coordinate.substring(0, degreeIndex));
  const minutes = parseInt(coordinate.substring(degreeIndex + 1, minutesIndex));
  const seconds = parseFloat(
    coordinate.substring(minutesIndex + 1, secondsIndex)
  );

  return { degrees, minutes, seconds };
};

export const convertWgsDegreeToDecimal = (coordinate: string) => {
  const parsedCoordinate = parseDegreeCoordinate(coordinate);
  return (
    parsedCoordinate.degrees +
    parsedCoordinate.minutes / 60 +
    parsedCoordinate.seconds / 3600
  );
};

export const convertWgsDecimalToDegree = (coordinate: string) => {
  const degree = Math.floor(parseFloat(coordinate));
  const fractionalPart = parseFloat(coordinate) - degree;

  const minutes = 60 * fractionalPart;
  const seconds = 60 * (minutes - Math.floor(minutes));

  return `${degree}°${Math.floor(minutes)}′${seconds.toFixed(2)}″`;
};

export const getCoordinatesFromString = (coordinates: string) =>
  coordinates.includes('°')
    ? getCoordinatesFromDegreeString(coordinates)
    : getCoordinatesFromDecimalString(coordinates);

export const getPlaceholderForCoordinateSystem = (
  coordinateSystem: CoordinateSystems,
  isDegree: boolean
) => {
  switch (coordinateSystem) {
    case CoordinateSystems.WGS:
      return isDegree ? '50°26′35″ 30°30′16″' : '50.443151, 30.504524';
    case CoordinateSystems.SK42:
      return '5593226, 6322859';
    case CoordinateSystems.USK2000:
      return '5593226, 322859';
    case CoordinateSystems.MGRS:
      return '36U XB 57377 34202';
  }
};

export const prepareConvertRequestPayload = (
  from: CoordinateSystems,
  to: CoordinateSystems,
  coordinates: string
): BLHCoordinates | XYHCoordinates | undefined => {
  const latLonOpt = getCoordinatesFromString(coordinates);

  if (!latLonOpt) return;

  const [lat, lon] = latLonOpt;

  if (from !== CoordinateSystems.MGRS && (!isNumber(lat) || !isNumber(lon))) {
    notify.error(
      to === CoordinateSystems.WGS
        ? convertValidationErrors.UNCORRECT_TO_WGS
        : convertValidationErrors.UNCORRECT_TO_XYH
    );
    return;
  }

  const requestPayload: ICoordinates =
    to === CoordinateSystems.WGS
      ? { x: lat, y: lon, h: DEFAULT_HEIGHT }
      : { b: lat, l: lon, h: DEFAULT_HEIGHT };
  return requestPayload;
};
