import cn from 'classnames';
import { Option } from 'interfaces';
import { GeocoderFeature } from 'interfaces/geocoder';

import { AutocompleteDropdownOptionProps } from 'components/ui/Autocomplete/AutocompleteDropdownOption';

type GeocoderOptionProps = AutocompleteDropdownOptionProps<GeocoderFeature>;

const getTitles = (option: Option<GeocoderFeature>) =>
  option.value.properties.subtype === 'place'
    ? {
        title: option.label,
        subtitle: option.value.properties.display_name,
      }
    : { title: option.label };

export const GeocoderOption = ({
  option,
  onClick,
  className,
}: GeocoderOptionProps) => {
  const { title, subtitle } = getTitles(option);

  return (
    <li
      role="button"
      className={cn(
        'px-3 py-[6px] list-none bg-ultrablack text-tpg_base cursor-pointer hover:bg-dark',
        className
      )}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => onClick(option)}
    >
      <p className="text-[13px] font-['Proxima_Nova'] font-bold leading-5 line-clamp-1 overflow-ellipsis">
        {title}
      </p>
      <p className="text-[13px] font-['Proxima_Nova'] font-normal leading-5 line-clamp-1 overflow-ellipsis">
        {subtitle}
      </p>
    </li>
  );
};
