import {
  VALID_LINE_STRING_MIN_VERTEX_COUNT,
  VALID_POLYGON_MIN_VERTEX_COUNT,
} from 'constants/mapDraw';
import { Option } from 'interfaces';
import {
  CoordinateSystems,
  DrawFeatureSubtypes,
  MapCreateTools,
  MapDrawTool,
  MeasureSystems,
} from 'types';
import { array, number, object, string } from 'yup';

import { FormData, FormDynamicField } from './types';
import { validateVertex } from './utils';

export const drawToolSubtypeMap: Record<string, DrawFeatureSubtypes> = {
  [MapCreateTools.create_line_string]: DrawFeatureSubtypes.LINE_STRING,
  [MapCreateTools.create_polygon]: DrawFeatureSubtypes.POLYGON,
  [MapCreateTools.create_circle]: DrawFeatureSubtypes.CIRCLE,
};

export const drawSubtypeToolMap: Record<string, MapDrawTool> = {
  [DrawFeatureSubtypes.LINE_STRING]: MapCreateTools.create_line_string,
  [DrawFeatureSubtypes.POLYGON]: MapCreateTools.create_polygon,
  [DrawFeatureSubtypes.CIRCLE]: MapCreateTools.create_circle,
};

export const defaultValues: FormData = {
  type: null,
  coordinateSystem: CoordinateSystems.WGS,
  centerVertex: '',
  measureSystem: MeasureSystems.METERS,
  radius: 0,
  vertexes: [],
};

export const defaultFormDynamicField: FormDynamicField = { value: '' };

export const drawFeatureOptions: Option<string>[] = [
  { label: 'Линия', value: DrawFeatureSubtypes.LINE_STRING },
  { label: 'Полигон', value: DrawFeatureSubtypes.POLYGON },
  { label: 'Радиус', value: DrawFeatureSubtypes.CIRCLE },
];

export const measureUnitOptions: Option<string>[] = [
  { label: 'М', value: MeasureSystems.METERS },
  { label: 'КМ', value: MeasureSystems.KILOMETERS },
];

export const measureSystemTitleMap: Record<string, string> = {
  [MeasureSystems.METERS]: 'М',
  [MeasureSystems.KILOMETERS]: 'КМ',
};

export const validationSchema = object().shape({
  type: string().required(),
  coordinateSystem: string().required(),
  measureSystem: string().when('type', {
    is: DrawFeatureSubtypes.CIRCLE,
    then: (schema) => schema.required(),
  }),
  centerVertex: string().when('type', {
    is: DrawFeatureSubtypes.CIRCLE,
    then: (schema) => schema.test('vertex', validateVertex),
  }),
  radius: number().when('type', {
    is: DrawFeatureSubtypes.CIRCLE,
    then: (schema) => schema.required().min(1),
  }),
  vertexes: array().when('type', {
    is: (value: string) =>
      value === DrawFeatureSubtypes.LINE_STRING ||
      value === DrawFeatureSubtypes.POLYGON,
    then: (schema) =>
      schema
        .of(object().shape({ value: string().test('vertex', validateVertex) }))
        .when('type', {
          is: DrawFeatureSubtypes.LINE_STRING,
          then: (schema) => schema.min(VALID_LINE_STRING_MIN_VERTEX_COUNT),
        })
        .when('type', {
          is: DrawFeatureSubtypes.POLYGON,
          then: (schema) => schema.min(VALID_POLYGON_MIN_VERTEX_COUNT),
        }),
  }),
});
