import { useRef } from 'react';
import { Geocoder } from 'services/map/geocoder';

import { useMapRef } from './map';

export const useGeocoder = () => {
  const map = useMapRef();
  const geocoder = useRef(new Geocoder({ map: map.mapRef.current }));

  return geocoder.current;
};
