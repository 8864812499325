import { Layer, Source } from 'react-map-gl';
import {
  railroadsLineLayer,
  railroadsSource,
  railroadsSymbolLayer,
} from 'configs/map/railroads';
import { LOGISTIC_STATIC_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

export const RailroadsSource = () => {
  const mapTypes = useAppSelector(typesSelector);

  const showRailroads = mapTypes.railroads.active;

  if (!showRailroads) {
    return null;
  }

  return (
    <Source {...railroadsSource}>
      <Layer beforeId={LOGISTIC_STATIC_LAYER} {...railroadsLineLayer} />
      <Layer beforeId={LOGISTIC_STATIC_LAYER} {...railroadsSymbolLayer} />
    </Source>
  );
};
