import { Layer, Source, SourceProps } from 'react-map-gl';
import { fireLayer, fireSource } from 'configs/map/fireActivity';
import { FIRE_STATIC_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity } from 'utils';

export const FireSource = () => {
  const mapTypes = useAppSelector(typesSelector);

  const showFire = mapTypes.fire.active;

  if (!showFire) {
    return null;
  }

  return (
    <Source {...(fireSource(mapTypes.fire.data) as SourceProps)}>
      <Layer
        {...fireLayer}
        beforeId={FIRE_STATIC_LAYER}
        type="raster"
        paint={{ 'raster-opacity': getProperOpacity(mapTypes.fire) }}
      />
    </Source>
  );
};
