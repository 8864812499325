import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { coordinateSystemTitleMap } from 'constants/map';
import { CoordinateSystems, MeasureSystems } from 'types';

import { Select, TextInput } from 'components/ui';

import { measureSystemTitleMap, measureUnitOptions } from './constants';
import { FormData } from './types';

interface CircleFieldsProps {
  coordinateSystem: CoordinateSystems | null;
  measureSystem: MeasureSystems | null;
  control: Control<FormData, any>;
}

export const CircleFormFields: FC<CircleFieldsProps> = ({
  coordinateSystem,
  measureSystem,
  control,
}) => (
  <>
    <Controller
      control={control}
      name="centerVertex"
      render={({ field }) => (
        <TextInput
          value={field.value}
          placeholder="Координата центра"
          onChange={field.onChange}
        >
          {coordinateSystem && (
            <span className="tpg-c1 text-tpg_base">
              {coordinateSystemTitleMap[coordinateSystem] ?? ''}
            </span>
          )}
        </TextInput>
      )}
    />
    <Controller
      control={control}
      name="measureSystem"
      render={({ field }) => (
        <Select
          value={field.value}
          options={measureUnitOptions}
          placeholder="Выберите единицу измерения"
          onSelect={field.onChange}
        />
      )}
    />
    <Controller
      control={control}
      name="radius"
      render={({ field }) => (
        <TextInput
          value={field.value}
          placeholder="Радиус"
          onChange={field.onChange}
        >
          {measureSystem && (
            <span className="tpg-c1 text-tpg_base lowercase">
              {measureSystemTitleMap[measureSystem] ?? ''}
            </span>
          )}
        </TextInput>
      )}
    />
  </>
);
