import { FC, JSX, ReactNode, useCallback, useEffect, useMemo } from 'react';
import type { MarkerDragEvent } from 'react-map-gl';
import * as turf from '@turf/turf';
import { circleOptions, DEFAULT_FLY_TO_SETTINGS } from 'constants/map';
import { ASTRA_COLORS } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { ReactComponent as GeospoofIcon } from 'images/newIcons/geospoofSearch.svg';
import { IGeospoofEntity } from 'interfaces';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { mapActions } from 'store';
import { geospoofSelector } from 'store/slices/map/selectors';
import { measurementActions } from 'store/slices/mapV2/mapReducer/toolsReducer/measurementSlice';
import { converterPositionSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/measurementSlice/selectors';
import { MeasureSystems, TAvatarUnion, TPosition } from 'types';

import { Avatar, CustomMarker } from 'components/ui';
import { GeospoofMarker } from 'components/ui/GeospoofMarker';
import { lngLatFromCoords, prepareFeatureCollection } from 'utils';

import { LabelSource } from '../Sources/LabelSource';
import { PolygonSource } from '../Sources/PolygonSource';

import './style.scss';

interface SpoofGroup {
  objects: IGeospoofEntity[];
  distance: number;
}

interface IGeospoofHistory {
  markers: JSX.Element[];
  labels: GeoJSON.FeatureCollection;
}

const TRIANGULATION_RADIUS = 500;

const FeatureCollection: FC = () => {
  const dispatch = useAppDispatch();
  const {
    isActive,
    visibleObjects,
    current,
    objects,
    searchPosition,
    objectCoordinates,
    pending,
    currentTab,
  } = useAppSelector(geospoofSelector);
  const showSpoofHistory = isActive && currentTab === 'history';
  const converterPosition = useAppSelector(converterPositionSelector);

  interface GeolocateIconProps {
    title?: string;
    icon?: ReactNode;
    className?: string;
  }

  const geolocateIcon = ({ title, icon, className }: GeolocateIconProps) => (
    <GeospoofMarker title={title} className={className} icon={icon} />
  );

  const { mapRef } = useMapRef();

  const currentObject = useMemo(
    () => current[currentTab],
    [current, currentTab]
  );

  const isEmptyUser = useMemo(
    () => !currentObject?.coordinates && currentTab === 'history',
    [currentObject, currentTab]
  );

  const userCoordinates = useMemo(
    () => currentObject?.coordinates || objectCoordinates,
    [currentObject, objectCoordinates]
  );

  const isOnePosition = useMemo(() => {
    if (searchPosition && currentObject?.coordinates) {
      return (
        searchPosition[0] === currentObject?.coordinates[0] &&
        searchPosition[1] === currentObject?.coordinates[1]
      );
    }

    if (searchPosition && objectCoordinates) {
      return (
        searchPosition[0] === objectCoordinates[0] &&
        searchPosition[1] === objectCoordinates[1]
      );
    }
    return false;
  }, [searchPosition, objectCoordinates, currentObject]);

  const groupCurrentList = (objects: IGeospoofEntity[]): SpoofGroup[] => {
    const groupedList = objects.reduce(
      (acc: { [key: string]: IGeospoofEntity[] }, obj) => {
        const groupingProp = obj.distance?.toString();
        if (groupingProp) {
          acc[groupingProp] = [...(acc[groupingProp] || []), obj];
        }
        return acc;
      },
      {}
    );

    return Object.values(groupedList)
      .filter((objs: IGeospoofEntity[]) => objs.length > 0)
      .map((objs: IGeospoofEntity[]) => {
        return { objects: objs, distance: objs[0].distance };
      });
  };

  const geospoofCircles = useMemo(() => {
    const objectList = [...objects['users']];
    const groupedObjects = groupCurrentList(objectList);
    const circles = groupedObjects.map(({ objects, distance }, idx) => {
      if (objectCoordinates) {
        const circleData = turf.circle(
          objectCoordinates as TPosition,
          distance,
          circleOptions
        );
        return (
          <div key={`spoof-group-${idx}`}>
            <PolygonSource
              key={`spoof-circle-${idx}`}
              id={`spoof-circle-${idx}`}
              data={circleData}
            />
          </div>
        );
      }
    });

    return circles;
  }, [objects['users'], searchPosition, pending, current]);

  const [activeCircle, customIcon] = useMemo(() => {
    let activeCircle = null;
    let customIcon = null;

    if (currentObject && !isEmptyUser) {
      if (objectCoordinates) {
        activeCircle = turf.circle(
          objectCoordinates as TPosition,
          currentObject.distance,
          circleOptions
        );
      } else if (currentObject.type === 'user') {
        activeCircle = turf.circle(
          currentObject.coordinates as TPosition,
          TRIANGULATION_RADIUS,
          circleOptions
        );
      }

      customIcon = (
        <Avatar
          type={currentObject.type as TAvatarUnion}
          theme={currentObject?.type === 'group' ? 'orange' : 'blue'}
          src={currentObject.thumbnail ?? null}
          size={56}
        />
      );
    }
    return [activeCircle, customIcon];
  }, [objectCoordinates, currentObject]);

  const handleDragEnd = useCallback(({ lngLat }: MarkerDragEvent) => {
    dispatch(mapActions.setGeospoofCoordinates([lngLat.lng, lngLat.lat]));
  }, []);

  const shouldChangeZoomAndCenter = (
    newZoom: number,
    oldZoom: number,
    newNorthLngLat: LngLat,
    newSouthLngLat: LngLat,
    bounds: LngLatBounds | null
  ) =>
    bounds &&
    (newZoom > oldZoom || !currentObject?.distance) &&
    newNorthLngLat.lat !== bounds.getNorth() &&
    newSouthLngLat.lat !== bounds.getSouth();

  const checkViewportAndFly = () => {
    if (mapRef.current && (currentObject?.coordinates || searchPosition)) {
      const DEFAULT_DISTANCE = 10000;
      const DISTANCE_OFFSET = 1000;
      const DIRECTION_NORTH = 0;
      const DIRECTION_SOUTH = 180;

      const distance = currentObject?.distance ?? DEFAULT_DISTANCE;
      const bounds = mapRef.current.getBounds();

      const oldCenter = mapRef.current.getCenter();
      const newCoordinates = (() => {
        if (currentObject?.coordinates)
          return lngLatFromCoords(currentObject.coordinates);
        if (searchPosition) return lngLatFromCoords(searchPosition);
        return oldCenter;
      })();
      const isCenterInView = bounds?.contains?.(newCoordinates);

      const objCoords =
        currentObject?.coordinates ?? searchPosition ?? oldCenter.toArray();

      const getNewPoint = (direction: number) =>
        turf.destination(objCoords, distance + DISTANCE_OFFSET, direction, {
          units: MeasureSystems.METERS,
        });

      const newNorthPoint = getNewPoint(DIRECTION_NORTH);
      const newNorthTPosition: TPosition = [
        newNorthPoint.geometry.coordinates[0],
        newNorthPoint.geometry.coordinates[1],
      ];
      const newNorthLngLat = lngLatFromCoords(newNorthTPosition);

      const newSouthPoint = getNewPoint(DIRECTION_SOUTH);
      const newSouthTPosition: TPosition = [
        newSouthPoint.geometry.coordinates[0],
        newSouthPoint.geometry.coordinates[1],
      ];
      const newSouthLngLat = lngLatFromCoords(newSouthTPosition);

      const oldZoom = mapRef.current.getZoom();
      const newZoom =
        mapRef.current.cameraForBounds([newNorthLngLat, newSouthLngLat])
          ?.zoom ?? oldZoom;

      if (
        shouldChangeZoomAndCenter(
          newZoom,
          oldZoom,
          newNorthLngLat,
          newSouthLngLat,
          bounds
        )
      ) {
        mapRef.current.fitBounds([newNorthLngLat, newSouthLngLat]);
      } else {
        const center = isCenterInView ? oldCenter : newCoordinates;
        const zoom = newZoom > oldZoom ? newZoom : oldZoom;

        mapRef.current?.flyTo({
          zoom,
          center,
          essential: true,
          speed: 6,
        });
      }
    }
  };

  useEffect(() => {
    mapRef.current?.flyTo({
      ...DEFAULT_FLY_TO_SETTINGS,
      center: converterPosition
        ? lngLatFromCoords(converterPosition)
        : undefined,
    });
  }, [converterPosition]);

  useEffect(() => {
    checkViewportAndFly();
  }, [currentObject, searchPosition]);

  const geospoofHistory: IGeospoofHistory = useMemo(
    () =>
      visibleObjects.reduce(
        (result, current, index) => ({
          markers: [
            ...result.markers,
            <CustomMarker
              icon={geolocateIcon({
                icon: (
                  <GeospoofIcon
                    viewBox="0 0 30 30"
                    height={20}
                    width={20}
                    className="mt-1.5 ml-1.5"
                  />
                ),
              })}
              key={`geospoof-history-marker-${index}`}
              longitude={current.coordinates[0]}
              latitude={current.coordinates[1]}
              onClick={current.onClick}
              style={{ zIndex: '0' }}
            />,
          ],
          labels: {
            ...result.labels,
            features: [
              ...result.labels.features,
              {
                type: 'Feature',
                geometry: { type: 'Point', coordinates: current.coordinates },
                properties: { title: current.label.replace(',', '\n') },
              },
            ],
          },
        }),
        { markers: [], labels: prepareFeatureCollection() } as IGeospoofHistory
      ),
    [visibleObjects]
  );

  const loaderCircleData = turf.circle(
    searchPosition ?? [0, 0],
    12000,
    circleOptions
  );

  return (
    <>
      {searchPosition && !isOnePosition && (
        <CustomMarker
          icon={
            pending
              ? geolocateIcon({ title: '...' })
              : geolocateIcon({ title: objects['users'].length.toString() })
          }
          draggable={!pending}
          longitude={+searchPosition[0]}
          latitude={+searchPosition[1]}
          onDragEnd={handleDragEnd}
          style={{ zIndex: '1' }}
        />
      )}
      {converterPosition && (
        <CustomMarker
          longitude={converterPosition[0]}
          latitude={converterPosition[1]}
          onClick={() =>
            dispatch(measurementActions.setConverterPosition(null))
          }
          onContextMenu={() =>
            dispatch(measurementActions.setConverterPosition(null))
          }
          color={ASTRA_COLORS.MAIN_PRODUCT}
        />
      )}
      {pending && (
        <PolygonSource
          key="loader-circle"
          id="loader-circle"
          data={loaderCircleData}
        />
      )}

      {!activeCircle && !pending && geospoofCircles}
      {userCoordinates && (
        <div onClick={(e) => e.stopPropagation()}>
          <CustomMarker
            icon={
              customIcon ??
              geolocateIcon({ title: objects['users'].length.toString() })
            }
            longitude={userCoordinates[0]}
            latitude={userCoordinates[1]}
            style={{ zIndex: '1' }}
          />
        </div>
      )}
      {showSpoofHistory && geospoofHistory.markers}
      {showSpoofHistory && (
        <LabelSource id="geospoof-history" data={geospoofHistory.labels} />
      )}
      {activeCircle && (
        <PolygonSource id="active-spoof-circle" data={activeCircle} />
      )}
    </>
  );
};

export default FeatureCollection;
