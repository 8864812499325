import { FC, memo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { labelConfig, lineConfig } from 'configs/map/common';
import { LINE_SOURCE } from 'constants/map';
import { MapGeoJSONOSource } from 'interfaces';

interface LineSourceProps extends MapGeoJSONOSource {
  beforeId?: string;
  mainConfig?: LayerProps;
  mainLabelConfig?: LayerProps;
}

export const LineSource: FC<LineSourceProps> = memo(
  ({
    id = LINE_SOURCE,
    beforeId,
    data,
    mainConfig = lineConfig,
    mainLabelConfig = labelConfig,
  }) => (
    <Source id={id} type="geojson" data={data}>
      <Layer id={`${id}-main-fill`} beforeId={beforeId} {...mainConfig} />
      <Layer id={`${id}-main-label`} beforeId={beforeId} {...mainLabelConfig} />
    </Source>
  )
);

LineSource.displayName = 'LineSource';
