import { ComponentType, useRef, useState } from 'react';
import cn from 'classnames';
import { Option } from 'interfaces';

import TextInput, { TextInputProps } from '../TextInput';

import {
  AutocompleteDropdownOption,
  AutocompleteDropdownOptionProps,
} from './AutocompleteDropdownOption';

export interface AutocompleteProps<T = any>
  extends Omit<TextInputProps, 'classNames'> {
  options?: Option<T>[];
  dropdownPosition?: 'bottom' | 'top';
  dropdownOption?: ComponentType<AutocompleteDropdownOptionProps>;
  inputClassNames?: TextInputProps['classNames'];
  dropdownClassnames?: { container?: string; option?: string };
  onOptionClick: (option: Option<T>) => void;
}

const AUTOCOMPLETE_DROPDOWN_POSITION_STYLES = {
  top: 'flex-col-reverse',
  bottom: 'flex-col',
};

const DROPDOWN_CONTAINTER_STYLES = {
  top: 'top-[auto] bottom-[100%]',
  bottom: 'bottom-[auto] top-[100%]',
};

export const Autocomplete = <T,>({
  options,
  dropdownPosition = 'bottom',
  dropdownOption,
  inputClassNames,
  dropdownClassnames,
  onFocus,
  onBlur,
  onOptionClick,
  ...props
}: AutocompleteProps<T>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const showDropdown = !!options?.length && isFocused;

  const handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  const handleOptionClick = (option: Option<T>) => {
    // manual blur control because onClick is not fired on hidden element
    onOptionClick(option);
    inputRef.current?.blur?.();
    handleBlur();
  };

  const DropdownOption = dropdownOption ?? AutocompleteDropdownOption;

  return (
    <div
      role="button"
      className={cn(
        'relative flex',
        AUTOCOMPLETE_DROPDOWN_POSITION_STYLES[dropdownPosition]
      )}
    >
      <TextInput
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        classNames={inputClassNames}
        {...props}
      />
      {showDropdown && (
        <ul
          className={cn(
            'scrollbar_hidden absolute w-full max-h-[200px] overflow-scroll rounded [&>*:first-child]:rounded-t-[inherit] [&>*:last-child]:rounded-b-[inherit]',
            DROPDOWN_CONTAINTER_STYLES[dropdownPosition],
            dropdownClassnames?.container
          )}
        >
          {options?.map?.((option, index) => (
            <DropdownOption<T>
              key={`${index}-${option.id}-${option.label}`}
              option={option}
              className={dropdownClassnames?.option}
              onClick={handleOptionClick}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
