import { labelConfig, labelLayout, labelPaint } from 'configs/map/common';
import { MapEntityStatuses } from 'constants/entities';
import { ExpressionSpecification, SymbolLayerSpecification } from 'mapbox-gl';
import { LayerPartialSpecification } from 'types';

const zoomBasedLabelLayoutConfig = {
  layout: {
    ...labelLayout,
    'text-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      ['literal', [0, 1.6]],
      10,
      ['literal', [0, 1.8]],
      17.5,
      ['literal', [0, 2.4]],
    ] as ExpressionSpecification,
  },
};

const highlightedLabelPaintConfig = (id?: number) => ({
  paint: {
    ...labelPaint,
    'text-halo-color': [
      'case',
      ['==', ['id'], id ?? null],
      '#053C92',
      '#0A0C17',
    ] as ExpressionSpecification,
  },
});

const getPointActiveConfig = (icon: string, id?: number) => [
  'case',
  ['==', ['id'], id ?? null],
  `${icon}-active`,
  icon,
];

const getPointStatusConfig = (icon: string, id?: number) => [
  'case',
  ['==', ['get', 'status'], MapEntityStatuses.REVEALED],
  getPointActiveConfig(`${icon}-revealed`, id),
  ['==', ['get', 'status'], MapEntityStatuses.CONFIRMED],
  getPointActiveConfig(`${icon}-confirmed`, id),
  ['==', ['get', 'status'], MapEntityStatuses.SENT_TO_DEFEAT],
  getPointActiveConfig(`${icon}-sent-to-defeat`, id),
  ['==', ['get', 'status'], MapEntityStatuses.ACCEPTED_TO_DEFEAT],
  getPointActiveConfig(`${icon}-accepted-to-defeat`, id),
  ['==', ['get', 'status'], MapEntityStatuses.SUPRESSED],
  getPointActiveConfig(`${icon}-supressed`, id),
  ['==', ['get', 'status'], MapEntityStatuses.MISSED],
  getPointActiveConfig(`${icon}-missed`, id),
  ['==', ['get', 'status'], MapEntityStatuses.DEFEATED],
  getPointActiveConfig(`${icon}-defeated`, id),
  ['==', ['get', 'status'], MapEntityStatuses.DESTROYED],
  getPointActiveConfig(`${icon}-destroyed`, id),
  ['==', ['get', 'status'], MapEntityStatuses.BANNED],
  getPointActiveConfig(`${icon}-banned`, id),
  ['==', ['get', 'status'], MapEntityStatuses.UNKNOWN],
  getPointActiveConfig(`${icon}-unknown`, id),
  getPointActiveConfig(`${icon}-revealed`, id),
];

const getPointColorConfig = (icon: string, id?: number) =>
  getPointStatusConfig(`${icon}-blue`, id);

const composePointConfig = (id?: number) => ({
  layout: {
    'icon-image': [
      'step',
      ['zoom'],
      getPointColorConfig('circle', id),
      11,
      getPointColorConfig('pin', id),
      17.5,
      ['case', ['==', ['id'], id ?? null], 'radius-blue-active', 'radius-blue'],
    ] as ExpressionSpecification,
    'icon-allow-overlap': true,
  },
});

export const getPointLabelConfig = (
  id?: number
): LayerPartialSpecification<SymbolLayerSpecification> => ({
  ...labelConfig,
  ...{
    ...zoomBasedLabelLayoutConfig,
    ...highlightedLabelPaintConfig(id),
  },
});

export const getLabelConfig = (
  id?: number
): LayerPartialSpecification<SymbolLayerSpecification> => ({
  ...labelConfig,
  ...highlightedLabelPaintConfig(id),
});

export const getPointConfig = (
  id?: number
): LayerPartialSpecification<SymbolLayerSpecification> => ({
  ...composePointConfig(id),
  filter: ['!', ['has', 'point_count']],
  type: 'symbol',
});
