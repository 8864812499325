import {
  MapboxDrawCustomEvents,
  MapboxDrawEvents,
  MapboxDrawModes,
  VALID_LINE_STRING_MIN_VERTEX_COUNT,
} from 'constants/mapDraw';
import {
  DrawCustomModeOverride,
  DrawEventActions,
  DrawFeatureSubtypes,
  MapDrawModes,
} from 'types';

const DrawLineStringModeOverride = Object.assign(
  {},
  MapboxDrawModes.draw_line_string
) as DrawCustomModeOverride;

// events miss featureTarget sometimes for some reasons, so we need to add it explicitly
const _onSetup = DrawLineStringModeOverride.onSetup;
const _onStop = DrawLineStringModeOverride.onStop;
const _onMouseMove = DrawLineStringModeOverride.onMouseMove;
const _clickOnVertex = DrawLineStringModeOverride.clickOnVertex;
const _onClick = DrawLineStringModeOverride.onClick;
const _onTap = DrawLineStringModeOverride.onTap;

DrawLineStringModeOverride.onSetup = function (opts) {
  const setupData = _onSetup?.apply?.(this, [opts]);

  setupData.line.properties.subtype = DrawFeatureSubtypes.LINE_STRING;
  setupData.line.properties.vertexCount = 0;
  setupData.line.properties.hasMinimumVertexCount = false;

  return setupData;
};

DrawLineStringModeOverride.onStop = function (state) {
  _onStop?.apply?.(this, [state]);

  const featureTarget = state.line.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.STOP, { featureTarget });
};

DrawLineStringModeOverride.onMouseMove = function (state, e) {
  _onMouseMove?.apply?.(this, [state, e]);

  const featureTarget = state.line.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.MOUSE_MOVE, {
    ...e,
    featureTarget,
  });
};

DrawLineStringModeOverride.onTap = function (state, e) {
  _onTap?.apply?.(this, [state, e]);

  state.line.properties = {
    ...state.line.properties,
    vertexCount: state.currentVertexPosition,
    hasMinimumVertexCount:
      state.currentVertexPosition >= VALID_LINE_STRING_MIN_VERTEX_COUNT,
  };

  const featureTarget = state.line.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.TAP, {
    ...e,
    featureTarget,
  });
};

DrawLineStringModeOverride.clickOnVertex = function (state, e) {
  _clickOnVertex.apply?.(this, [state, e]);

  const hasMinimumVertexCount =
    state.currentVertexPosition >= VALID_LINE_STRING_MIN_VERTEX_COUNT;

  !hasMinimumVertexCount &&
    this.map.fire(MapboxDrawEvents.MODE_CHANGE, {
      mode: MapDrawModes.simple_select,
      action: DrawEventActions.DRAW_CANCEL,
    });
};

DrawLineStringModeOverride.onClick = function (state, e) {
  // disable draw interaction for context menu click
  if (e.originalEvent.button === 2) {
    return;
  }

  _onClick?.apply?.(this, [state, e]);

  state.line.properties = {
    ...state.line.properties,
    vertexCount: state.currentVertexPosition,
    hasMinimumVertexCount:
      state.currentVertexPosition >= VALID_LINE_STRING_MIN_VERTEX_COUNT,
  };

  const featureTarget = state.line.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.CLICK, {
    ...e,
    featureTarget,
  });
};

DrawLineStringModeOverride.onTap = function (state, e) {
  _onTap?.apply?.(this, [state, e]);

  state.line.properties = {
    ...state.line.properties,
    vertexCount: state.currentVertexPosition,
    hasMinimumVertexCount: state.currentVertexPosition >= 2,
  };

  const featureTarget = state.line.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.TAP, {
    ...e,
    featureTarget,
  });
};

export { DrawLineStringModeOverride };
