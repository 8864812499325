import { search } from 'api/geocoder';
import {
  GeocoderSearchRequest,
  GeocodeSearchGeoJSONResponse,
} from 'interfaces/geocoder';
import { Map } from 'mapbox-gl';

export interface GeocoderProps {
  map: Map | null;
}

export class Geocoder {
  private map: Map | null;

  constructor({ map }: GeocoderProps) {
    this.map = map;
  }

  public async forwardGeocode(request: GeocoderSearchRequest) {
    const res = await search<GeocodeSearchGeoJSONResponse>({
      format: 'geojson',
      countrycodes: 'ua',
      ...request,
    });

    return res;
  }
}
