import { FC } from 'react';

import { DocumentRes } from '../../../interfaces/documents';
import { formatDate } from '../../../utils';

import TagList from './TagList';

interface CardPops {
  document: DocumentRes;
  onClick: () => void;
}

export const Card: FC<CardPops> = ({ document, onClick }) => (
  <div className="flex flex-col bg-dark p-[16px] rounded-[10px] min-h-[116px]">
    <div className={'flex flex-row gap-[30px] pb-[12px] justify-between'}>
      <div className="max-w-[75%] overflow-hidden overflow-ellipsis">
        <span className="cursor-pointer" onClick={onClick}>
          {document.name}
        </span>
      </div>
      <span>{formatDate(new Date(document.createdAt))}</span>
    </div>
    <div className="flex flex-row flex-wrap w-full gap-[4px]">
      <TagList document={document} />
    </div>
  </div>
);
