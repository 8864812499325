import { ReactNode } from 'react';
import area from '@turf/area';
import { lineString, polygon } from '@turf/helpers';
import length from '@turf/length';
import { ReactComponent as PenToolIcon } from 'images/newIcons/penTool.svg';
import { ReactComponent as PolygonIcon } from 'images/newIcons/polygon.svg';
import { ReactComponent as RadiusIcon } from 'images/newIcons/radius.svg';
import { DrawCustomFeature } from 'interfaces';
import {
  GeometryTypes,
  MapCreateTools,
  MapDrawTool,
  MeasureSystems,
} from 'types';

import { isFeatureValid } from 'utils';
import { getMeasurement } from 'utils/measurements';

export const TOOL_INFOS: Record<
  string,
  { title: string; description: string }
> = {
  [MapCreateTools.create_line_string]: {
    title: 'Линия',
    description:
      'Продолжайте нажимать, чтобы добавить больше вершин. Точки можно перетаскивать.',
  },
  [MapCreateTools.create_polygon]: {
    title: 'Полигон',
    description:
      'Продолжайте нажимать, чтобы добавить больше вершин. Точки можно перетаскивать.',
  },
  [MapCreateTools.create_circle]: {
    title: 'Радиус',
    description:
      'Тяните мышью, чтобы задать больший радиус. Окружность можно будет изменить или перенести.',
  },
};

export const TOOL_ICONS: Record<string, ReactNode> = {
  [MapCreateTools.create_line_string]: <PenToolIcon className="w-4 h-4" />,
  [MapCreateTools.create_polygon]: <PolygonIcon className="w-4 h-4" />,
  [MapCreateTools.create_circle]: <RadiusIcon className="w-4 h-4" />,
};

export const getToolValues = (
  measureMode: MapDrawTool,
  drawFeature: DrawCustomFeature | null | undefined
) => {
  const isValidFeature = drawFeature && isFeatureValid(drawFeature);

  switch (measureMode) {
    case MapCreateTools.create_line_string: {
      const len =
        isValidFeature &&
        drawFeature.geometry.type === GeometryTypes.LINE_STRING
          ? length(lineString(drawFeature.geometry.coordinates), {
              units: MeasureSystems.METERS,
            })
          : 0;

      const measurement = getMeasurement(len);

      return [
        { label: `Длина, ${measurement.unit}`, value: measurement.value },
      ];
    }
    case MapCreateTools.create_polygon: {
      const perimeter =
        isValidFeature && drawFeature.geometry.type === GeometryTypes.POLYGON
          ? length(polygon(drawFeature.geometry.coordinates), {
              units: MeasureSystems.METERS,
            })
          : 0;

      const square =
        isValidFeature && drawFeature.geometry.type === GeometryTypes.POLYGON
          ? area(polygon(drawFeature.geometry.coordinates))
          : 0;

      const perimeterMeasurement = getMeasurement(perimeter);
      const squareMeasurement = getMeasurement(square, true);

      return [
        {
          label: `Периметр, ${perimeterMeasurement.unit}`,
          value: perimeterMeasurement.value,
        },
        {
          label: `Площадь, ${squareMeasurement.unit}`,
          value: squareMeasurement.value,
        },
      ];
    }
    case MapCreateTools.create_circle: {
      const radius = isValidFeature ? drawFeature.properties.radiusInM : 0;
      const square = Math.PI * Math.pow(radius, 2);

      const radiusMeasurement = getMeasurement(radius);
      const squareMeasurement = getMeasurement(square, true);

      return [
        {
          label: `Радиус, ${radiusMeasurement.unit}`,
          value: radiusMeasurement.value,
        },
        {
          label: `Площадь, ${squareMeasurement.unit}`,
          value: squareMeasurement.value,
        },
      ];
    }
    default:
      return null;
  }
};
