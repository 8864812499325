import { FC, Fragment, memo, useMemo } from 'react';
import { FeatureCollection } from '@turf/turf';
import { MAP_ENTITY_PARAM_VALUES } from 'constants/entities';
import {
  LAYERS_LINE,
  LAYERS_POINT,
  LAYERS_POLYGON,
  LINE_STATIC_LAYER,
  POINT_STATIC_LAYER,
  POLYGON_STATIC_LAYER,
} from 'constants/map';
import { ENTITY_DETAILS_MODAL, ENTITY_PREVIEW_MODAL } from 'constants/modals';
import { LineString, Point, Polygon } from 'geojson';
import { useAppSelector } from 'hooks';
import {
  clusterLayersObjectsSelector,
  showLayersSelector,
} from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { EntitiesMap } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';
import { modalSelector } from 'store/slices/service/modalsSlice/selectors';
import { MapEntity, MapFeatureProperties } from 'types';
import { PredefinedTemplate } from 'types/entities';

import { getFeaturesFromMapEntities } from 'utils';
import { getEntityParametersIdTitleMap } from 'utils/entity';

import { LineSource } from '../LineSource';
import { PointSource } from '../PointSource';
import { PolygonSource } from '../PolygonSource';

import { getLabelConfig, getPointConfig, getPointLabelConfig } from './configs';

interface LayerSourceProps {
  points: Record<string, FeatureCollection<Point, MapFeatureProperties>>;
  lines: FeatureCollection<LineString, MapFeatureProperties>;
  polygons: FeatureCollection<Polygon, MapFeatureProperties>;
}

// based on https://stackoverflow.com/questions/66556362/cluster-markers-in-mapbox-how-to-accumulate-just-distinct-properties
const CLUSTER_TYPE_LABEL = {
  label: [
    [
      'concat',
      ['get', 'label'],
      [
        'case',
        ['in', ['accumulated'], ['get', 'label']],
        '',
        ['concat', ', ', ['accumulated']],
      ],
    ],
    ['get', 'type'],
  ],
};

export const useLayersSourceData = (
  entitiesMap: EntitiesMap,
  temporaryEntities: MapEntity[],
  mapObjectTemplate?: PredefinedTemplate
) => {
  const parametersIdTitleMap = useMemo(() => {
    const mapObjectTemplateParameters = mapObjectTemplate?.parameters || [];

    return getEntityParametersIdTitleMap(
      mapObjectTemplateParameters,
      MAP_ENTITY_PARAM_VALUES,
      'reverse'
    );
  }, [mapObjectTemplate]);

  const { points, lines, polygons } = useMemo(
    () =>
      getFeaturesFromMapEntities(
        [...Object.values(entitiesMap), ...temporaryEntities],
        parametersIdTitleMap
      ),
    [entitiesMap, temporaryEntities, parametersIdTitleMap]
  );

  return { points, lines, polygons };
};

export const LayersSource: FC<LayerSourceProps> = memo(
  ({ points, lines, polygons }) => {
    const showLayers = useAppSelector(showLayersSelector);
    const clusterObjects = useAppSelector(clusterLayersObjectsSelector);

    const entityPreviewModal = useAppSelector((state) =>
      modalSelector(state, ENTITY_PREVIEW_MODAL)
    );

    const entityDetailsModal = useAppSelector((state) =>
      modalSelector(state, ENTITY_DETAILS_MODAL)
    );

    const activeEntity = (entityPreviewModal?.props ||
      entityDetailsModal?.props) as MapEntity | undefined;

    const rerenderPointsTrigger = String(clusterObjects);

    const groupedByPointType = Object.entries(points);

    if (!showLayers) {
      return null;
    }

    return (
      <>
        <PolygonSource
          id={LAYERS_POLYGON}
          beforeId={POLYGON_STATIC_LAYER}
          data={polygons}
          mainLabelConfig={getLabelConfig(activeEntity?.entity?.id)}
        />
        <LineSource
          id={LAYERS_LINE}
          beforeId={LINE_STATIC_LAYER}
          data={lines}
          mainLabelConfig={getLabelConfig(activeEntity?.entity?.id)}
        />
        <Fragment key={rerenderPointsTrigger}>
          {groupedByPointType.map(([type, data]) => (
            <PointSource
              key={type}
              id={`${LAYERS_POINT}-${type}`}
              beforeId={POINT_STATIC_LAYER}
              mainConfig={getPointConfig(activeEntity?.entity?.id)}
              mainLabelConfig={getPointLabelConfig(activeEntity?.entity?.id)}
              data={data}
              cluster={clusterObjects}
              clusterMaxZoom={15}
              clusterRadius={30}
              clusterProperties={CLUSTER_TYPE_LABEL}
            />
          ))}
        </Fragment>
      </>
    );
  }
);

LayersSource.displayName = 'LayersSource';
