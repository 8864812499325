import { createSlice } from '@reduxjs/toolkit';

import { TOOLS_REDUCER_NAMES } from '../constants';

import {
  MeasurementState,
  SetConverterPositionAction,
  SetMeasurementAction,
  UpdateMeasurementAction,
} from './types';

const initialState: MeasurementState = {
  measurement: null,
  converterPosition: null,
};

const measurementSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.MEASUREMENT,
  initialState: initialState,
  reducers: {
    setMeasurement(state, action: SetMeasurementAction) {
      state.measurement = action.payload;
    },
    updateMeasurement(state, action: UpdateMeasurementAction) {
      if (!state.measurement) {
        return;
      }

      state.measurement = {
        ...state.measurement,
        value: { ...state.measurement.value, ...action.payload },
      };
    },
    setConverterPosition(state, action: SetConverterPositionAction) {
      state.converterPosition = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: measurementActions, reducer: measurementReducer } =
  measurementSlice;
