import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { convertValidationErrors, errorMessages } from 'constants/errors';
import { coordinateSystemOptions } from 'constants/map';
import {
  VALID_LINE_STRING_MIN_VERTEX_COUNT,
  VALID_POLYGON_MIN_VERTEX_COUNT,
} from 'constants/mapDraw';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapDraw } from 'hooks/useMapDraw';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { drawActions } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice';
import { drawFeatureSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice/selectors';
import { stateActions } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice';
import { drawToolSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice/selectors';
import { DrawFeatureSubtypes, MapDrawModes } from 'types';

import { Button, Modal, Select } from 'components/ui';
import { IconButton } from 'components/ui/IconButton';
import { notify } from 'utils';

import { CircleFormFields } from './CircleFormFields';
import { CommonFormFields } from './CommonFormFields';
import {
  defaultFormDynamicField,
  drawFeatureOptions,
  drawSubtypeToolMap,
  validationSchema,
} from './constants';
import { FormData } from './types';
import {
  createDrawFeatureFromFormData,
  getConvertedFormCenterVertex,
  getConvertedFormVertexes,
  getDefaultValues,
} from './utils';

interface CreateDrawFeatureModalProps {
  onClose: () => void;
}

export const CreateDrawFeatureModal: FC<CreateDrawFeatureModalProps> = ({
  onClose,
}) => {
  const drawTool = useAppSelector(drawToolSelector);
  const drawFeature = useAppSelector(drawFeatureSelector);
  const [isConverting, setIsConverting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fieldsContainerRef = useRef<HTMLDivElement>(null);

  const {
    formState: { isValid },
    control,
    getValues,
    reset,
    handleSubmit,
    trigger,
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(drawTool, drawFeature),
    resolver: yupResolver(validationSchema) as any,
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'vertexes',
  });

  const { mapDraw, handleChangeDrawMode, handleDrawClear } = useMapDraw();

  const dispatch = useAppDispatch();

  const drawFeatureType = watch('type');
  const coordinateSystem = watch('coordinateSystem');
  const measureSystem = watch('measureSystem');

  const handleFeatureTypeSelect = (e: any, onChange: (...e: any[]) => void) => {
    const isActive = drawFeatureType === e;

    if (isActive) {
      return;
    }

    const drawTool = drawSubtypeToolMap[e] ?? null;

    const minVertexCount =
      (e === DrawFeatureSubtypes.LINE_STRING &&
        VALID_LINE_STRING_MIN_VERTEX_COUNT) ||
      (e === DrawFeatureSubtypes.POLYGON && VALID_POLYGON_MIN_VERTEX_COUNT) ||
      0;

    reset(getDefaultValues(drawTool, drawFeature));
    append(new Array(minVertexCount).fill(defaultFormDynamicField));

    handleDrawClear();
    handleChangeDrawMode(drawTool);

    dispatch(stateActions.setDrawTool(drawTool));
    dispatch(drawActions.setDrawFeature(null));

    onChange(e);
  };

  const handleCoordinateSystemSelect = async (
    e: any,
    onChange: (...e: any[]) => void
  ) => {
    setIsConverting(true);

    const isActive = coordinateSystem === e;

    if (isActive) {
      return;
    }

    onChange(e);

    try {
      if (
        drawFeatureType === DrawFeatureSubtypes.LINE_STRING ||
        drawFeatureType === DrawFeatureSubtypes.POLYGON
      ) {
        const vertexes = getValues('vertexes');
        const hasEmptyVertex = vertexes.some((vertex) => !vertex.value);

        if (hasEmptyVertex) {
          return;
        }

        const newVertexes = await getConvertedFormVertexes(
          coordinateSystem,
          e,
          getValues('vertexes')
        );

        reset((prev) => ({ ...prev, vertexes: newVertexes }));
      }

      if (drawFeatureType === DrawFeatureSubtypes.CIRCLE) {
        const centerVertex = getValues('centerVertex');

        if (!centerVertex) {
          return;
        }

        const newCenterVertex = await getConvertedFormCenterVertex(
          coordinateSystem,
          e,
          { value: centerVertex }
        );

        reset((prev) => ({ ...prev, centerVertex: newCenterVertex.value }));
      }
    } catch (e) {
      console.error(e);
      notify.error(convertValidationErrors.GET_RESULT_ERROR);
    } finally {
      setIsConverting(false);
    }

    trigger();
  };

  const handleVertexInsert = () => append([defaultFormDynamicField]);

  const handleDrawFeature = async (formData: FormData) => {
    try {
      setIsLoading(true);

      const drawFeature = await createDrawFeatureFromFormData(formData);

      if (!drawFeature) {
        return;
      }

      handleDrawClear();

      const addedDrawFeatureId = mapDraw.add(drawFeature)[0];

      handleChangeDrawMode(MapDrawModes.direct_select, {
        featureId: addedDrawFeatureId,
      });
      dispatch(drawActions.setDrawFeature(drawFeature));
      onClose();
    } catch (e) {
      console.error(e);
      notify.error(errorMessages.DRAW_FEATURE_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fieldsContainerRef.current?.scroll?.({
      top: fieldsContainerRef.current.scrollHeight,
    });
  }, [fields]);

  return (
    <Modal
      width={460}
      className="!p-12 bg-dark border border-solid border-tpg_light rounded-[10px]"
      isBlurred
      keyboard
      onClose={onClose}
    >
      <div className="w-full h-full">
        <p className="text-center tpg-h4">Создать фигуру</p>
        <form
          className="flex flex-col items-center gap-3 w-full h-full mt-6"
          onSubmit={handleSubmit(handleDrawFeature)}
        >
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select
                value={field.value}
                options={drawFeatureOptions}
                placeholder="Выберите фигуру"
                theme="light"
                onSelect={(e) => handleFeatureTypeSelect(e, field.onChange)}
              />
            )}
          />
          <Controller
            control={control}
            name="coordinateSystem"
            render={({ field }) => (
              <Select
                value={field.value}
                options={coordinateSystemOptions}
                placeholder="Выберите систему координат"
                theme="light"
                disabled={isConverting}
                isLoading={isConverting}
                onSelect={(e) =>
                  handleCoordinateSystemSelect(e, field.onChange)
                }
              />
            )}
          />
          <div
            ref={fieldsContainerRef}
            className="w-full flex flex-col gap-3 max-h-[300px] overflow-auto"
          >
            {drawFeatureType === DrawFeatureSubtypes.CIRCLE ? (
              <CircleFormFields
                measureSystem={measureSystem}
                coordinateSystem={coordinateSystem}
                control={control}
              />
            ) : (
              <CommonFormFields
                coordinateSystem={coordinateSystem}
                control={control}
                fields={fields}
              />
            )}
          </div>
          {(drawFeatureType === DrawFeatureSubtypes.LINE_STRING ||
            drawFeatureType === DrawFeatureSubtypes.POLYGON) && (
            <IconButton
              title="Добавить вершину"
              icon={<PlusIcon />}
              classNames={{ container: 'w-full' }}
              onClick={handleVertexInsert}
            />
          )}
          <Button
            type="submit"
            title="Создать"
            disabled={!isValid || isConverting || isLoading}
            isLoading={isLoading}
            className="w-full"
            onClick={() => null}
          />
        </form>
      </div>
    </Modal>
  );
};
