import { useState } from 'react';

import {
  AccessControlDetails,
  AccessControlDetailsProps,
} from 'components/Access/AccessControlDetails';
import { LayerDetails, LayerDetailsProps } from 'components/Map/LayerDetails';
import SidebarWindowWithTabs from 'components/ui/SidebarWindowWithTabs';

import ImportDetails, {
  ImportDetailsProps,
} from '../../ImportDetails/ImportDetails';
import UpdateLayerChildrenDetails, {
  UpdateLayerChildrenDetailsProps,
} from '../../UpdateLayerChildrenDetails/UpdateLayerChildrenDetails';

import SidebarContent from './SidebarContent';
import { ActiveFilterCriteriasMap } from './types';

export const MapLayers = () => {
  const [search, setSearch] = useState<string>('');
  const [activeFilterCriteriasMap, setActiveFilterCriteriasMap] =
    useState<ActiveFilterCriteriasMap>({});
  const [layerDetailsProps, setLayerDetailsProps] =
    useState<LayerDetailsProps>();
  const [accessControlDetailsProps, setAccessControlDetailsProps] =
    useState<AccessControlDetailsProps>();
  const [importDetailsProps, setImportDetailsProps] =
    useState<ImportDetailsProps>();
  const [updateLayerChildrenDetailsProps, setUpdateLayerChildrenDetailsProps] =
    useState<UpdateLayerChildrenDetailsProps>();

  return (
    <>
      <SidebarWindowWithTabs
        tabs={[
          <SidebarContent
            key="public"
            isPublic={true}
            setLayerDetailsProps={setLayerDetailsProps}
            setAccessControlDetailsProps={setAccessControlDetailsProps}
            setImportDetailsProps={setImportDetailsProps}
            search={search}
            setSearch={setSearch}
            activeFilterCriteriasMap={activeFilterCriteriasMap}
            setActiveFilterCriteriasMap={setActiveFilterCriteriasMap}
            setUpdateLayerChildrenDetailsProps={
              setUpdateLayerChildrenDetailsProps
            }
          />,
          <SidebarContent
            key="private"
            isPublic={false}
            setLayerDetailsProps={setLayerDetailsProps}
            setAccessControlDetailsProps={setAccessControlDetailsProps}
            setImportDetailsProps={setImportDetailsProps}
            search={search}
            setSearch={setSearch}
            activeFilterCriteriasMap={activeFilterCriteriasMap}
            setActiveFilterCriteriasMap={setActiveFilterCriteriasMap}
            setUpdateLayerChildrenDetailsProps={
              setUpdateLayerChildrenDetailsProps
            }
          />,
        ]}
        tabOptions={['Общие', 'Приватные']}
      />
      {importDetailsProps && <ImportDetails {...importDetailsProps} />}
      {layerDetailsProps && <LayerDetails {...layerDetailsProps} />}
      {accessControlDetailsProps && (
        <AccessControlDetails {...accessControlDetailsProps} />
      )}
      {updateLayerChildrenDetailsProps && (
        <UpdateLayerChildrenDetails {...updateLayerChildrenDetailsProps} />
      )}
    </>
  );
};
