import { FC, ReactNode } from 'react';
import cn from 'classnames';

export interface IconButtonProps {
  icon?: ReactNode;
  title?: string;
  disabled?: boolean;
  classNames?: { container?: string };
  onClick: () => void;
}

export const IconButton: FC<IconButtonProps> = ({
  icon,
  title,
  disabled,
  classNames,
  onClick,
}) => (
  <div
    role="button"
    className={cn(
      'flex gap-1 items-center cursor-pointer hover:text-bright_product',
      disabled && '!cursor-default !text-tpg_base [&>svg>path]:fill-tpg_base',
      classNames?.container
    )}
    onClick={disabled ? undefined : onClick}
  >
    {icon}
    {title && <span className="tpg-c2">{title}</span>}
  </div>
);
