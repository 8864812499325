import { convertCoordinates } from 'api/converter';
import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { convertValidationErrors } from 'constants/errors';
import {
  MGRS_FULL_REGEX,
  SK42_FULL_REGEX,
  SK42_SHORT_REGEX,
  WGS_FULL_REGEX,
} from 'constants/map';
import {
  ICoordinates,
  Sk42ConverterResponse,
  WgsConverterResponse,
  XYHCoordinates,
} from 'interfaces';
import { GeocoderFeatureSubtype } from 'interfaces/geocoder';
import { CoordinateSystems } from 'types';

import { getCoordinates, getCoordinatesTuple, notify } from 'utils';

import {
  Coordinates,
  PossibleCoordinateEntriesFormat,
  PossibleCoordinates,
  PossibleCoordinatesEntriesCheck,
  PossibleCoordinatesOption,
} from '../types';

import { getGeocoderFeature } from './common';

const getPossibleCoordinatesValues = (
  coordinateSystem: CoordinateSystems,
  coordinatesString: string,
  format: PossibleCoordinateEntriesFormat
): PossibleCoordinates | null => {
  const coordinates = getCoordinates(coordinateSystem, coordinatesString);

  switch (coordinateSystem) {
    case CoordinateSystems.MGRS:
      return {
        coordinateSystem,
        coordinates: coordinates as string,
        format,
      };
    case CoordinateSystems.WGS:
    case CoordinateSystems.SK42: {
      return {
        coordinateSystem,
        coordinates: coordinates as [number, number],
        format,
      };
    }
    default:
      return null;
  }
};

const getMergedSK42FullAndShortCoordinates = (
  fullCoordinates: XYHCoordinates,
  shortCoordinates: Coordinates
): XYHCoordinates => {
  const { x: fullX, y: fullY } = fullCoordinates;
  const [shortX, shortY] = shortCoordinates;

  const mergedX = Number(String(fullX).slice(0, 2) + shortX);
  const mergedY = Number(String(fullY).slice(0, -5) + shortY);

  return { x: mergedX, y: mergedY, h: DEFAULT_CONVERTER_HEIGHT };
};

const getSK42ShortCoordinatesOption = async (
  fullCoordinates: ICoordinates,
  shortCoordinates: Coordinates,
  currentCoordsMode: CoordinateSystems
): Promise<PossibleCoordinatesOption | null> => {
  const sk42Coordinates = await convertCoordinates<Sk42ConverterResponse>(
    currentCoordsMode,
    CoordinateSystems.SK42,
    fullCoordinates
  );

  const mergedSK42Coordinates = getMergedSK42FullAndShortCoordinates(
    sk42Coordinates.payload,
    shortCoordinates
  );

  const wgsCoordinates = await convertCoordinates(
    CoordinateSystems.SK42,
    CoordinateSystems.WGS,
    mergedSK42Coordinates
  );

  const coordinates = getCoordinatesTuple(
    wgsCoordinates.payload,
    CoordinateSystems.WGS
  ) as Coordinates;

  return {
    id: CoordinateSystems.SK42,
    label: 'Перейти к СК-42 координате',
    value: getGeocoderFeature(
      GeocoderFeatureSubtype.COORDINATES,
      coordinates,
      {},
      'lngLat'
    ),
  };
};

const getSK42FullCoordinatesOption = async (
  fullCoordinates: Coordinates
): Promise<PossibleCoordinatesOption | null> => {
  const result = await convertCoordinates<WgsConverterResponse>(
    CoordinateSystems.SK42,
    CoordinateSystems.WGS,
    {
      x: fullCoordinates[0],
      y: fullCoordinates[1],
      h: DEFAULT_CONVERTER_HEIGHT,
    }
  );

  const coordinates = getCoordinatesTuple(
    result.payload,
    CoordinateSystems.WGS
  ) as Coordinates;

  return {
    id: CoordinateSystems.SK42,
    label: 'Перейти к СК-42 координате',
    value: getGeocoderFeature(
      GeocoderFeatureSubtype.COORDINATES,
      coordinates,
      {},
      'lngLat'
    ),
  };
};

// use reversed [lng, lat] coordinates order for mapbox & geocoder
const getCoordinatesOption = async (
  possibleCoords: PossibleCoordinates,
  currentCoords: ICoordinates,
  currentCoordsMode: CoordinateSystems
): Promise<PossibleCoordinatesOption | null> => {
  try {
    switch (possibleCoords.coordinateSystem) {
      case CoordinateSystems.MGRS: {
        const result = await convertCoordinates<WgsConverterResponse>(
          CoordinateSystems.MGRS,
          CoordinateSystems.WGS,
          possibleCoords.coordinates
        );

        const coordinates = [result.payload.b, result.payload.l] as Coordinates;

        return {
          id: possibleCoords.coordinateSystem,
          label: 'Перейти к MGRS координате',
          value: getGeocoderFeature(
            GeocoderFeatureSubtype.COORDINATES,
            coordinates,
            {},
            'lngLat'
          ),
        };
      }
      case CoordinateSystems.WGS:
        return {
          id: possibleCoords.coordinateSystem,
          label: 'Перейти к WGS координате',
          value: getGeocoderFeature(
            GeocoderFeatureSubtype.COORDINATES,
            possibleCoords.coordinates,
            {},
            'lngLat'
          ),
        };
      case CoordinateSystems.SK42: {
        return possibleCoords.format === 'short'
          ? getSK42ShortCoordinatesOption(
              currentCoords,
              possibleCoords.coordinates,
              currentCoordsMode
            )
          : getSK42FullCoordinatesOption(possibleCoords.coordinates);
      }
      default:
        return null;
    }
  } catch (e) {
    notify.error(convertValidationErrors.GET_RESULT_ERROR);

    return null;
  }
};

// possible coordinate may have different systems
export const getPossibleCoordinates = (
  value?: string | null
): PossibleCoordinates[] => {
  if (!value) {
    return [];
  }

  const possibleCoordinates: PossibleCoordinates[] = [];
  const coordinateChecks: PossibleCoordinatesEntriesCheck[] = [
    {
      name: CoordinateSystems.SK42,
      shortFormat: SK42_SHORT_REGEX,
      fullFormat: SK42_FULL_REGEX,
    },
    { name: CoordinateSystems.WGS, fullFormat: WGS_FULL_REGEX },
    { name: CoordinateSystems.MGRS, fullFormat: MGRS_FULL_REGEX },
  ];

  coordinateChecks.forEach((check) => {
    const isMatchedShortFormat = check.shortFormat?.test?.(value);
    const isMatchedFullFormat = check.fullFormat.test(value);
    const matchedFormat =
      (isMatchedShortFormat && 'short') ||
      (isMatchedFullFormat && 'full') ||
      null;

    if (matchedFormat) {
      const possibleCoordinatesValues = getPossibleCoordinatesValues(
        check.name,
        value,
        matchedFormat
      );

      possibleCoordinatesValues &&
        possibleCoordinates.push(possibleCoordinatesValues);
    }
  });

  return possibleCoordinates;
};

export const getCoordinatesOptions = async (
  possibleCoords: PossibleCoordinates[],
  currentCoords: ICoordinates,
  currentCoordsMode: CoordinateSystems
) => {
  try {
    const options: PossibleCoordinatesOption[] = [];

    for (const possibleCoord of possibleCoords) {
      const option = await getCoordinatesOption(
        possibleCoord,
        currentCoords,
        currentCoordsMode
      );

      if (option) {
        options.push(option);
      }
    }

    return options;
  } catch (e) {
    notify.error(convertValidationErrors.GET_RESULT_ERROR);

    return [];
  }
};
