import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MAPBOX, MAPBOX_MAP_CONTAINER } from 'constants/map';
import { useAppDispatch } from 'hooks';
import MapProvider from 'providers/map/MapProvider';
import { MapRefProvider } from 'providers/map/MapRefProvider';
import { getAttributesThunk, getGCRangesThunk } from 'store/slices/map/actions';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import {
  getMapEntitiesThunk,
  getPredefinedTemplateThunk,
  searchEntityByIdThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { modalsActions } from 'store/slices/service/modalsSlice';

import { MapFooter } from 'components/Map/MapFooter';
import { MapHeader } from 'components/Map/MapHeader';
import { MapSidebar } from 'components/Map/MapSidebar';

import { predefinedTemplates } from '../../constants/entities';

import { Map } from './Map';

import './style.scss';

const MapPage = () => {
  const { entityId } = useParams<{ entityId: string }>();
  const dispatch = useAppDispatch();
  const [areEntitiesLoaded, setAreEntitiesLoaded] = useState(false);

  const getMapData = useCallback(async () => {
    dispatch(getAttributesThunk());
    dispatch(getGCRangesThunk());

    const mapObjectTemplateResult = await dispatch(
      getPredefinedTemplateThunk(predefinedTemplates.MAP_OBJECT)
    );

    // make sure all predefined templates are loaded before getting map entities
    await dispatch(getPredefinedTemplateThunk(predefinedTemplates.MAP_LAYER));
    await dispatch(getMapEntitiesThunk({ withCounters: true })).then(
      async () => {
        if (entityId) {
          await dispatch(searchEntityByIdThunk(entityId)).then(() =>
            setAreEntitiesLoaded(true)
          );
        }
      }
    );
  }, [dispatch]);

  useEffect(() => {
    getMapData();

    return () => {
      dispatch(mapEntitiesActions.resetState);
      dispatch(modalsActions.setModals({}));
    };
  }, [getMapData]);

  return (
    <MapRefProvider>
      <MapProvider>
        <>
          <MapHeader />
          <div id={MAPBOX} className="map-container relative overflow-hidden">
            <>
              <MapSidebar />
              <div
                id={MAPBOX_MAP_CONTAINER}
                className="map-container__wrapper flex flex-col"
              >
                <Map areEntitiesLoaded={areEntitiesLoaded} />
                <MapFooter />
              </div>
            </>
          </div>
        </>
      </MapProvider>
    </MapRefProvider>
  );
};

export default MapPage;
