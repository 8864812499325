import { EntityStorages, Hierarchy } from 'types';
import {
  DocumentEntity,
  DocumentFolderNodeData,
  DocumentNode,
  DocumentNodeData,
  DocumentObjectNodeData,
} from 'types/documents';
import { EntityListResponse } from 'types/entities';

export const getDocumentEntitiesTree = (
  data: EntityListResponse,
  activeNodes: DocumentNode[],
  directoryTemplateId: number | undefined,
  documentTemplateId: number | undefined,
  level = 0
): DocumentNodeData[] => {
  if (!data || !data.entities || !data.hierarchy) return [];

  const entitiesMap = Object.fromEntries(
    data.entities.map((entity) => [entity.entity.id, entity])
  );

  if (!data.hierarchy) return [];

  const buildTree = (
    hierarchy: Hierarchy,
    level: number
  ): DocumentNodeData[] => {
    const { id } = hierarchy;
    const entity = entitiesMap[id] as DocumentEntity;

    if (!entity) return [];

    const parentId = entity.parentIDs[0] ?? 0;
    const isFolder = entity.entity.templateID === directoryTemplateId;
    const isObject = entity.entity.templateID === documentTemplateId;

    if (isFolder) {
      const folderNode: DocumentFolderNodeData = {
        id: String(id),
        name: entity.entity.title,
        type: 'folder',
        isFolder: true,
        info: {
          level: level,
          parent: { id: String(parentId) },
          stats: {
            selectedObjectsCount: 0,
            totalObjectsCount: 0,
          },
        },
        state: {
          storage: EntityStorages.NONE,
          selected: activeNodes.some((n) => n.id === String(entity.entity.id)),
        },
        entity: entity,
        children: hierarchy.children.flatMap((child) =>
          buildTree(child, level + 1)
        ),
      };
      return [folderNode];
    }

    if (isObject) {
      const objectNode: DocumentObjectNodeData = {
        id: String(id),
        name: entity.entity.title,
        type: 'object',
        isFolder: false,
        info: {
          level: level,
          parent: { id: String(parentId) },
        },
        state: {
          storage: EntityStorages.NONE,
          selected: activeNodes.some((n) => n.id === String(entity.entity.id)),
        },
        entity: entity,
      };
      return [objectNode];
    }

    return [];
  };

  return data.hierarchy.flatMap((hierarchyItem) =>
    buildTree(hierarchyItem, level)
  );
};

export const updateDocumentTreeSelection = (
  tree: DocumentNodeData[],
  activeNodes: DocumentNode[]
): DocumentNodeData[] => {
  return tree.map((node) => {
    const isSelected = activeNodes.some((n) => n.id === node.id);
    const updatedNode = {
      ...node,
      state: { ...node.state, selected: isSelected },
    };

    if (node.isFolder && node.children) {
      updatedNode.children = updateDocumentTreeSelection(
        node.children,
        activeNodes
      );
    }

    return updatedNode;
  });
};

export const updateDocumentsTree = (
  documentsTree: DocumentNodeData[],
  updatedTree: DocumentNodeData[]
): DocumentNodeData[] => {
  const updateNode = (
    node: DocumentNodeData,
    updatedNode: DocumentNodeData
  ): DocumentNodeData => {
    if (node.entity.entity.id === updatedNode.entity.entity.id) {
      return updatedNode;
    }

    if (node.isFolder) {
      return {
        ...node,
        children: (node.children || []).map((child) =>
          updateNode(child, updatedNode)
        ),
      };
    }
    return node;
  };

  const traverseNodes = (nodes: DocumentNodeData[]): DocumentNodeData[] => {
    return nodes.map((node) => {
      const updatedNode = updatedTree.find(
        (updated) => updated.entity.entity.id === node.entity.entity.id
      );
      if (updatedNode) {
        node = updateNode(node, updatedNode);
      }

      if (node.isFolder && node.children) {
        node.children = traverseNodes(node.children);
      }

      return node;
    });
  };

  return traverseNodes(documentsTree);
};
