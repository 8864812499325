import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { INTEGER_REGEX } from 'constants/map';
import { Position } from 'geojson';
import { BLHCoordinates, ICoordinates, XYHCoordinates } from 'interfaces';

import { notify } from 'utils';

import { CoordinateSystems } from '../types';

import { convertWgsDegreeToDecimal } from './converterUtils';

export const getCoordinatesFromDecimalString = (value: string) =>
  value ? value.split(',').map((item) => parseFloat(item)) : null;

export const getCoordinatesFromDegreeString = (value: string) =>
  value
    ? value
        .split(' ')
        .map((coordinate) => convertWgsDegreeToDecimal(coordinate))
    : null;

export const formatWgsCoords = (lat: number, lng: number) =>
  `${lat.toFixed(6)}, ${lng.toFixed(6)}`;

export const formatXYCoords = (x: number, y: number) => `X:${x}, Y:${y}`;

export const copyToClipboard = (
  text: string,
  message = 'Скопировано в буфер обмена'
) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notify.success(message);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getVisibleCoordMode = (coordsMode: CoordinateSystems) => {
  switch (coordsMode) {
    case CoordinateSystems.WGS:
      return 'WGS';
    case CoordinateSystems.SK42:
      return 'СК-42';
    case CoordinateSystems.USK2000:
      return 'УСК2000';
  }
};

export const getCoordinates = (
  coordinateSystem: CoordinateSystems,
  coordinatesString: string
): string | [number, number] | null => {
  switch (coordinateSystem) {
    case CoordinateSystems.MGRS:
      return coordinatesString;
    case CoordinateSystems.WGS:
    case CoordinateSystems.SK42:
    case CoordinateSystems.USK2000: {
      const position = coordinatesString.match(INTEGER_REGEX);

      return position ? [+position[0], +position[1]] : null;
    }
    default:
      return null;
  }
};

export const getCoordinatesTuple = (
  object: ICoordinates,
  system: CoordinateSystems,
  coordinatesOrder: 'latLng' | 'lngLat' = 'latLng'
): [number, number] | string => {
  switch (system) {
    case CoordinateSystems.MGRS: {
      return object as string;
    }
    case CoordinateSystems.WGS: {
      const { b, l } = object as BLHCoordinates;
      const tuple = [b, l];

      return (coordinatesOrder === 'latLng' ? tuple : tuple.reverse()) as [
        number,
        number
      ];
    }
    case CoordinateSystems.SK42:
    case CoordinateSystems.USK2000: {
      const { x, y } = object as XYHCoordinates;
      const tuple = [x, y];

      return (coordinatesOrder === 'latLng' ? tuple : tuple.reverse()) as [
        number,
        number
      ];
    }
    default:
      return [0, 0];
  }
};

export const getCoordinatesObject = (
  coordinates: Position | string | null,
  system: CoordinateSystems,
  coordinatesOrder: 'latLng' | 'lngLat' = 'latLng'
): ICoordinates => {
  if (!coordinates) {
    return '';
  }

  switch (system) {
    case CoordinateSystems.MGRS: {
      return coordinates as string;
    }
    case CoordinateSystems.WGS: {
      const position = coordinates as [number, number];

      const [b, l] =
        coordinatesOrder === 'latLng' ? position : position.reverse();

      return { b, l, h: DEFAULT_CONVERTER_HEIGHT };
    }
    case CoordinateSystems.SK42:
    case CoordinateSystems.USK2000: {
      const position = coordinates as [number, number];

      const [x, y] =
        coordinatesOrder === 'latLng' ? position : position.reverse();

      return { x, y, h: DEFAULT_CONVERTER_HEIGHT };
    }
    default:
      return '';
  }
};

export const getCoordinatesStringFromTuple = (
  tuple: [number, number] | string,
  system: CoordinateSystems,
  coordinatesOrder: 'latLng' | 'lngLat' = 'latLng'
) => {
  const stringTuple = tuple as string;
  const numericTuple = tuple as [number, number];

  switch (system) {
    case CoordinateSystems.MGRS: {
      return stringTuple;
    }
    case CoordinateSystems.WGS: {
      return formatWgsCoords(
        ...(coordinatesOrder === 'latLng'
          ? numericTuple
          : (numericTuple.reverse() as [number, number]))
      );
    }
    case CoordinateSystems.SK42:
    case CoordinateSystems.USK2000: {
      return formatXYCoords(
        ...(coordinatesOrder === 'latLng'
          ? numericTuple
          : (numericTuple.reverse() as [number, number]))
      );
    }
    default:
      return '';
  }
};
