import { Hierarchy } from 'interfaces';

export const processHierarchy = (
  hierarchy: Hierarchy,
  id: number,
  processFunc: (node: Hierarchy) => Hierarchy
) => {
  if (hierarchy.id === id) {
    return processFunc(hierarchy);
  }

  for (let i = 0; i < hierarchy.children.length; i++) {
    hierarchy.children[i] = processHierarchy(
      hierarchy.children[i],
      id,
      processFunc
    );
  }

  return hierarchy;
};

export const processHierarchyNodeMoving = (
  hierarchy: Hierarchy,
  node: Hierarchy,
  oldParentId: number,
  newParentId: number,
  processedParentIds: number[]
) => {
  let processedHierarchy = { ...hierarchy };

  if (processedHierarchy.id === oldParentId) {
    processedHierarchy = {
      ...processedHierarchy,
      children: processedHierarchy.children.filter(
        (hierarchy) => hierarchy.id !== node.id
      ),
    };
    processedParentIds.push(oldParentId);
  }

  if (processedHierarchy.id === newParentId) {
    processedHierarchy = {
      ...processedHierarchy,
      children: [...processedHierarchy.children, node],
    };
    processedParentIds.push(newParentId);
  }

  if (processedParentIds.length === 2) {
    return processedHierarchy;
  }

  for (let i = 0; i < processedHierarchy.children.length; i++) {
    processedHierarchy.children[i] = processHierarchyNodeMoving(
      processedHierarchy.children[i],
      node,
      oldParentId,
      newParentId,
      processedParentIds
    );
  }

  return processedHierarchy;
};

export const findHierarchyNode = (hierarchy: Hierarchy, id: number) => {
  let result: Hierarchy | null = null;

  if (hierarchy.id === id) {
    return hierarchy;
  }

  if (hierarchy.children) {
    hierarchy.children.some((node) => (result = findHierarchyNode(node, id)));
  }

  return result;
};

export const createHierarchyNode = (
  id: number,
  children?: Hierarchy[]
): Hierarchy => ({ id, children: children ?? [] });

export const updateHierarchyProperty = <T extends keyof Hierarchy>(
  hierarchy: Hierarchy,
  id: number,
  property: T,
  value: Hierarchy[T]
) =>
  processHierarchy(hierarchy, id, (hierarchy) => ({
    ...hierarchy,
    [property]: value,
  }));

export const moveHierarchyNode = (
  hierarchy: Hierarchy,
  nodeId: number,
  oldParentId: number,
  newParentId: number
) => {
  const node = findHierarchyNode(hierarchy, nodeId);
  const processedParentIds: number[] = [];

  if (oldParentId === newParentId || !node) {
    return hierarchy;
  }

  return processHierarchyNodeMoving(
    hierarchy,
    node,
    oldParentId,
    newParentId,
    processedParentIds
  );
};

export const deleteNodeFromHierarchy = (
  hierarchy: Hierarchy,
  id: number,
  parentID: number
) =>
  processHierarchy(hierarchy, parentID, (hierarchy) => ({
    ...hierarchy,
    children: hierarchy.children.filter((i) => i.id != id),
  }));
