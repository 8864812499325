import { FC } from 'react';
import { FeatureTypes } from 'constants/map';
import { Geometry, Position } from 'geojson';
import {
  BoundaryGrid,
  BoundaryGridCellGeoJSON,
  ContextMenu as IContextMenu,
  ContextMenuTypes,
  CoordinateSystems,
  ImageryBoundaryGeoJSON,
} from 'types';

import { BoundaryGridContextMenu } from 'components/Map/ContextMenu/BoundaryGridContextMenu';
import { ImageryContextMenu } from 'components/Map/ContextMenu/ImageryContextMenu';

import { ContextPopup } from './ContextPopup';
import { copyCoordinates } from './utils';

interface ContextMenuProps {
  contextMenu: IContextMenu;
  onClose: () => void;
  onEntityCreate: (geometry: Geometry) => void;
}

export const ContextMenu: FC<ContextMenuProps> = ({
  contextMenu,
  onClose,
  onEntityCreate,
}) => {
  const { latitude, longitude } = contextMenu;

  const handleCreatePoint = (position: Position) =>
    onEntityCreate({
      type: FeatureTypes.POINT,
      coordinates: position,
    });

  const commonOptions = [
    {
      title: `Скопировать координату в WGS`,
      onClick: () =>
        copyCoordinates(CoordinateSystems.WGS, latitude, longitude),
    },
    {
      title: `Скопировать координату в СК-42`,
      onClick: () =>
        copyCoordinates(CoordinateSystems.SK42, latitude, longitude),
    },
    {
      title: 'Создать объект',
      onClick: () => handleCreatePoint([longitude, latitude]),
    },
  ];

  const isDefaultContextMenuOpen =
    contextMenu?.type === ContextMenuTypes.DEFAULT;
  const isBoundaryGridContextMenuOpen =
    contextMenu?.type === ContextMenuTypes.BOUNDARY_GRID;
  const isImageryContextMenuOpen =
    contextMenu?.type === ContextMenuTypes.IMAGERY;

  return (
    <>
      {isDefaultContextMenuOpen && (
        <ContextPopup
          options={commonOptions}
          longitude={contextMenu.longitude}
          latitude={contextMenu.latitude}
          keyboard
          onClose={onClose}
        />
      )}

      {isImageryContextMenuOpen && (
        <ImageryContextMenu
          longitude={contextMenu.longitude}
          latitude={contextMenu.latitude}
          options={commonOptions}
          imageryBoundary={
            contextMenu.props.imageryBoundary as ImageryBoundaryGeoJSON
          }
          onClose={onClose}
        />
      )}
      {isBoundaryGridContextMenuOpen && (
        <BoundaryGridContextMenu
          longitude={contextMenu.longitude}
          latitude={contextMenu.latitude}
          options={commonOptions}
          boundaryGrid={contextMenu.props.boundaryGrid as BoundaryGrid}
          boundaryGridCell={
            contextMenu.props.boundaryGridCell as BoundaryGridCellGeoJSON
          }
          onClose={onClose}
        />
      )}
    </>
  );
};
