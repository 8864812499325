import { Layer, Source } from 'react-map-gl';
import { googleSatelliteSource } from 'configs/map/google';
import {
  GOOGLE_SATELLITE_UPPER_LAYER,
  SATELLITE_STATIC_LAYER,
} from 'constants/map';
import { useAppSelector } from 'hooks';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity } from 'utils';

export const GoogleSatelliteSource = () => {
  const mapTypes = useAppSelector(typesSelector);

  const showGoogle = mapTypes.googleSatelliteUpper.active;

  if (!showGoogle) {
    return null;
  }

  return (
    <Source {...googleSatelliteSource} id="google-satellite-upper-source">
      <Layer
        id={GOOGLE_SATELLITE_UPPER_LAYER}
        source="google-satellite-upper-source"
        beforeId={SATELLITE_STATIC_LAYER}
        type="raster"
        paint={{
          'raster-opacity': getProperOpacity(mapTypes.googleSatelliteUpper),
        }}
      />
    </Source>
  );
};
