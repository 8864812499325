import { SELFHOSTED_DEM_RASTER_LAYER } from 'constants/map';
import { RasterLayerSpecification, RasterSourceSpecification } from 'mapbox-gl';

const { REACT_APP_API_URL } = process.env;

export const getDemMapUrl = () =>
  REACT_APP_API_URL + '/data/prod_dem/{z}/{x}/{y}.png';

export const demMapSource: RasterSourceSpecification = {
  id: 'selfhosted-dem-source',
  type: 'raster',
  tiles: [getDemMapUrl()],
  bounds: [
    21.156805555555557, 42.9813326094, 44.3897996351454, 52.700138888888894,
  ],
  maxzoom: 12,
  minzoom: 3,
};

export const demMapLayer: RasterLayerSpecification = {
  id: SELFHOSTED_DEM_RASTER_LAYER,
  type: 'raster',
  source: '',
  paint: { 'raster-opacity': 0.8 },
};
