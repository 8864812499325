import { ResourceType } from 'mapbox-gl';

const { REACT_APP_API_URL } = process.env;

export const addTileTokenToUrl = (url: string, tileToken: string) =>
  `${url}?token=${tileToken}`;

export const constructTilesetUrl = (route: string) => {
  const stripSlash = (value: string) =>
    value.endsWith('/') ? value.slice(0, -1) : value;
  return stripSlash(REACT_APP_API_URL || '') + route;
};

export const transformTileRequest = (
  url: string,
  resourceType: ResourceType | undefined,
  tileToken: string | null
) => {
  const handleUrl =
    tileToken &&
    resourceType == 'Tile' &&
    REACT_APP_API_URL &&
    url.startsWith(REACT_APP_API_URL);

  return {
    url: handleUrl ? addTileTokenToUrl(url, tileToken) : url,
  };
};
