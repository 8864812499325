import { FC, useEffect, useMemo, useState } from 'react';
import { AccessRuleCode, predefinedTemplates } from 'constants/entities';
import { MAP_LAYER_NAME_LIMIT } from 'constants/map';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ISelectOption } from 'interfaces';
import {
  relinkEntityThunk,
  upsertEntityThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import {
  entitiesMapSelector,
  predefinedTemplateIdSelector,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { getEmptyMapLayerEntity } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/utils';
import { MapEntity } from 'types';

import {
  AccessControlForm,
  parseSubjectID,
} from 'components/Access/AccessControlForm';
import { Button, Modal, Select, TextInput } from 'components/ui';
import { getSelectOptionsFromSearch } from 'utils/entity';

import { EntityAccessRule } from '../../../interfaces/entity';
import { accountSelector } from '../../../store/slices/auth/selectors';
import { UpsertEntityRequest } from '../../../types/entities';
import { EntityNode } from '../Tabs/Layers/types';

export interface LayerDetailsProps {
  parentId?: number;
  layer?: EntityNode;
  onClose: () => void;
}

export const LayerDetails: FC<LayerDetailsProps> = ({
  onClose,
  layer,
  parentId: initialParentId = 0,
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(accountSelector);
  const mapObjectTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, predefinedTemplates.MAP_OBJECT)
    ) || 0;
  const mapLayerTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, predefinedTemplates.MAP_LAYER)
    ) || 0;
  const entitiesMap = useAppSelector(entitiesMapSelector);

  const [loading, setLoading] = useState(false);
  const [layerOptions, setLayerOptions] = useState<ISelectOption[]>([]);
  const [accessRules, setAccessRules] = useState<EntityAccessRule[]>([]);

  const [layerEntity, setLayerEntity] = useState<MapEntity>(
    layer
      ? layer.data.entity
      : getEmptyMapLayerEntity(mapLayerTemplateId, mapObjectTemplateId)
  );

  const [parentId, setParentId] = useState(
    layer && layer.data.entity.parentIDs.length
      ? layer.data.entity.parentIDs[0]
      : initialParentId
  );

  const parentEntity = useMemo(
    () => entitiesMap[parentId],
    [entitiesMap, parentId]
  );

  const isCreatingEntity = !!layerEntity.state.draft;
  const isEntityTitlePresent = !!layerEntity.entity.title;

  const handleParentChange = (parentId: number | null) =>
    setParentId(Number(parentId));

  const handleSave = async () => {
    const prevParentID = layerEntity.parentIDs[0] ?? 0;
    const isRelinking = !isCreatingEntity && prevParentID !== parentId;

    let payload: UpsertEntityRequest = layerEntity.entity;

    if (accessRules.length > 0) {
      payload = {
        ...payload,
        accessRule: accessRules[0],
      };
    }

    if (!prevParentID && parentId) {
      payload = {
        ...payload,
        parentEntityID: parentId,
      };
    }

    layerEntity.entity.id && delete payload.parentEntityID;

    setLoading(true);

    await dispatch(upsertEntityThunk(payload))
      .then(async (r) => {
        if (r.payload && 'id' in r.payload) {
          const entityId = r.payload.id;
          const upsertedMapEntity = {
            ...layerEntity,
            entity: { ...layerEntity.entity, id: entityId },
            parentIDs: [parentId],
          };

          if (isRelinking) {
            await dispatch(
              relinkEntityThunk({
                entityId: entityId,
                oldParentEntityId: prevParentID,
                newParentEntityId: parentId,
              })
            );
          }

          setLayerEntity(upsertedMapEntity);
          onClose();
        }
      })
      .finally(() => setLoading(false));
  };

  const handleUpdateLayerTitle = (title: string) =>
    setLayerEntity({
      ...layerEntity,
      entity: { ...layerEntity.entity, title },
    });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await getSelectOptionsFromSearch(
        mapObjectTemplateId,
        setLayerOptions,
        [mapLayerTemplateId],
        [layerEntity.entity.id || []].flat(),
        true
      ).finally(() => setLoading(false));
    };

    fetchData();
  }, [layerEntity.entity.id]);

  const isSaveAllowed = useMemo(() => {
    const hasParent = !!parentId;
    const hasAccessRules =
      accessRules.length > 0 && accessRules.some((rule) => !!rule.subjectID);

    return (
      isEntityTitlePresent && (hasParent || hasAccessRules || !isCreatingEntity)
    );
  }, [isEntityTitlePresent, parentId, accessRules, isCreatingEntity]);

  const shouldShowAccessControlForm = useMemo(
    () =>
      !layer &&
      (parentId === 0 || (parentEntity && parentEntity.isUserBranchAdmin)),
    [layer, parentId, parentEntity]
  );

  const accessControlSelectOptionsFilter = (option: ISelectOption) => {
    if (!option.value) {
      return false;
    }
    const [subjectType, subjectId] = parseSubjectID(option.value.toString());

    const subjectIsGroup = subjectType === 'group';
    const entityIsNotRoot = parentId !== 0;
    const entityParentIsInPersonalSpace =
      !!parentEntity && parentEntity.isPersonalSpace;
    const isLinkToCurrentUser = subjectId === currentUser?.id;

    // if folder is being created in personal space it shouldn't be linked to group subjects
    if (subjectIsGroup && entityIsNotRoot && entityParentIsInPersonalSpace) {
      return false;
    }

    // in case we're creating root folder - it must be linked to current user OR to one of his groups
    // otherwise the folder will just disappear after it's creation because it's not linked to current user, so it's invisible to him
    return entityIsNotRoot || subjectIsGroup || isLinkToCurrentUser;
  };

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12">
        <div className="tpg-h4 flex justify-center pb-4">
          {layerEntity.entity.id ? 'Редактирование' : 'Создание'}
        </div>
        <TextInput
          value={layerEntity.entity.title}
          placeholder="Название проекта"
          autoFocus
          maxLength={MAP_LAYER_NAME_LIMIT}
          onChange={handleUpdateLayerTitle}
          classNames={{ container: 'w-full' }}
        />
        <div className="pb-4 pt-3">
          <Select<number | null>
            withSearch
            searchPlaceholder="Начните поиск"
            value={parentId}
            options={layerOptions}
            onSelect={handleParentChange}
            theme="light"
            isExpandable
            disabled={loading}
            unknownOptionLabel="Вложенность проекта"
          />
        </div>
        {/* AccessControlForm should be rendered for new features only! */}
        {(shouldShowAccessControlForm ||
          shouldShowAccessControlForm === undefined) && (
          <AccessControlForm
            entity={layerEntity}
            accessRules={accessRules}
            setAccessRules={setAccessRules}
            subjectOptionsFilter={accessControlSelectOptionsFilter}
            // enforcing readwrite so user couldn't create a root folder that is restricted or readonly to him
            enforcedAccessRule={parentId ? undefined : AccessRuleCode.READWRITE}
            // false because we're using custom filter to handle subject options
            removeGroupsIfPersonalSpace={false}
            maxAccessRulesLength={1}
          />
        )}
        <Button
          className="w-full"
          disabled={!isSaveAllowed}
          title="Сохранить"
          onClick={handleSave}
          isLoading={loading}
        />
      </div>
    </Modal>
  );
};
