import { CSSProperties } from 'react';
import { NodeRendererProps } from 'react-arborist';
import { useDoubleClick } from 'hooks/useDoubleClick';

import { ContextMenuWrapper } from './ContextMenuWrapper';
import { Folder } from './Folder';
import { Object } from './Object';
import {
  INodeInfo,
  INodeState,
  TGetContextMenu,
  TNodeClickEvent,
  TNodeData,
  TNodeDragEvent,
  TOnNodeBooleanStateChange,
  TOnNodeClick,
  TOnNodeEdit,
  TOnNodeOpen,
  TRenderEditingNameSlot,
  TRenderSlot,
} from './types';
import { preventInteraction } from './utils';

/*
 * react-arborist provides tree/node selection API
 * but it's not flexible enough for our purposes
 * as it's impossible to select all existing (hidden) nodes at once.
 * So library node.select is overriden by custom onClick method
 * and node.isSelected overriden by custom node.data.state.selected.
 * DnD (and other unused things I guess) depends on tree/node library selection
 * so anyway we need to handle tree/node selection methods under the hood to activate multiple nodes DnD.
 */

export type INodeProps<
  F,
  O,
  I extends INodeInfo = INodeInfo,
  S extends INodeState = INodeState
> = NodeRendererProps<TNodeData<F, O, I, S>> & {
  className?: string;
  styleOverride?: CSSProperties;
  getContextMenu?: TGetContextMenu<F, O, I, S>;
  renderArrow?: TRenderSlot<F, O, I, S>;
  renderIcon?: TRenderSlot<F, O, I, S>;
  renderName?: TRenderSlot<F, O, I, S>;
  renderLoading?: TRenderSlot<F, O, I, S>;
  renderEditingName?: TRenderEditingNameSlot<F, O, I, S>;
  renderPostfix?: TRenderSlot<F, O, I, S>;
  renderCounter?: TRenderSlot<F, O, I, S>;
  onEdit?: TOnNodeEdit<F, O, I, S>;
  onOpen?: TOnNodeOpen<F, O, I, S>;
  onEditingChange?: TOnNodeBooleanStateChange<F, O, I, S>;
  onOpenChange?: TOnNodeBooleanStateChange<F, O, I, S>;
  onClick?: TOnNodeClick<F, O, I, S>;
  onDoubleClick?: TOnNodeClick<F, O, I, S>;
};

export const Node = <
  F,
  O,
  I extends INodeInfo = INodeInfo,
  S extends INodeState = INodeState
>({
  node,
  style,
  dragHandle,
  className,
  styleOverride,
  getContextMenu,
  renderArrow,
  renderIcon,
  renderName,
  renderLoading,
  renderEditingName,
  renderPostfix,
  renderCounter,
  onEdit,
  onOpen,
  onEditingChange,
  onOpenChange,
  onClick,
  onDoubleClick,
}: INodeProps<F, O, I, S>) => {
  const isFolder = node.isInternal;

  const handleEdit = (value: string) => {
    onEdit?.(value, node);

    node.submit(value);
  };

  const handleOpen = (open: boolean) => onOpen?.(open, node);

  const handleEditingChange = (editing: boolean) => {
    if (onEditingChange) {
      onEditingChange?.(editing, node);

      return;
    }

    editing ? node.edit() : node.reset();
  };

  const handleOpenChange = (open: boolean) => {
    if (onOpenChange) {
      onOpenChange?.(open, node);

      return;
    }

    open ? node.close() : node.open();
  };

  const handleDragStart = (e: TNodeDragEvent) =>
    preventInteraction(e, node.isEditing);

  const handleSingleClick = (e: TNodeClickEvent) => {
    node.isSelected ? node.deselect() : node.selectMulti();

    onClick?.(e, node);
    e.stopPropagation();
  };

  const handleDoubleClick = (e: TNodeClickEvent) => {
    onDoubleClick?.(e, node);
    e.stopPropagation();
  };

  const handleDividedClick = useDoubleClick(
    handleSingleClick,
    handleDoubleClick
  );

  const handleClick = (e: TNodeClickEvent) => {
    node.isEditing
      ? preventInteraction(e, node.isEditing)
      : handleDividedClick(e);
  };

  return (
    <div
      ref={dragHandle}
      className={className}
      draggable={node.isDraggable}
      style={{ ...style, ...styleOverride }}
      onDragStart={handleDragStart}
      onClick={handleClick}
    >
      <ContextMenuWrapper contextMenu={getContextMenu?.(node) ?? []}>
        {isFolder ? (
          <Folder
            name={node.data.name}
            editable={node.isEditable}
            editing={node.isEditing}
            selected={node.data.state.selected}
            loading={node.data.state.loading}
            open={node.isOpen}
            empty={!node.children?.length}
            renderArrow={renderArrow?.(node)}
            renderIcon={renderIcon?.(node)}
            renderName={renderName?.(node)}
            renderLoading={renderLoading?.(node)}
            renderEditingName={(value, onChange, onEnter, onBlur) =>
              renderEditingName?.(value, onChange, onEnter, onBlur, node)
            }
            renderPostfix={renderPostfix?.(node)}
            renderCounter={renderCounter?.(node)}
            onEdit={handleEdit}
            onOpen={handleOpen}
            onEditingChange={handleEditingChange}
            onOpenChange={handleOpenChange}
          />
        ) : (
          <Object
            name={node.data.name}
            editable={node.isEditable}
            editing={node.isEditing}
            selected={node.data.state.selected}
            renderIcon={renderIcon?.(node)}
            renderName={renderName?.(node)}
            renderEditingName={(value, onChange, onEnter, onBlur) =>
              renderEditingName?.(value, onChange, onEnter, onBlur, node)
            }
            renderPostfix={renderPostfix?.(node)}
            onEdit={handleEdit}
            onEditingChange={handleEditingChange}
          />
        )}
      </ContextMenuWrapper>
    </div>
  );
};
