import { Layer, LayerProps, Source } from 'react-map-gl';
import {
  heatLayers,
  petrolLayers,
  powerLayers,
  teleconsLayers,
  waterLayers,
} from 'configs/map/infrastructure/layers';
import {
  heatmapConfig,
  openInfraMapConfig,
} from 'configs/map/infrastructure/source';
import { LOGISTIC_STATIC_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

export const InfrastructureSource = () => {
  const mapTypes = useAppSelector(typesSelector);

  const showInfrastructure = mapTypes.infrastructure.active;

  if (!showInfrastructure) {
    return null;
  }

  return (
    <>
      <Source
        {...openInfraMapConfig}
        scheme="xyz"
        id="openinframap"
        type="vector"
        format="pbf"
      >
        {powerLayers.map((item, index) => (
          <Layer
            key={index}
            beforeId={LOGISTIC_STATIC_LAYER}
            {...(item as LayerProps)}
          />
        ))}
        {waterLayers.map((item, index) => (
          <Layer
            key={index}
            beforeId={LOGISTIC_STATIC_LAYER}
            {...(item as LayerProps)}
          />
        ))}
        {petrolLayers.map((item, index) => (
          <Layer
            key={index}
            beforeId={LOGISTIC_STATIC_LAYER}
            {...(item as LayerProps)}
          />
        ))}
        {teleconsLayers.map((item, index) => (
          <Layer
            key={index}
            beforeId={LOGISTIC_STATIC_LAYER}
            {...(item as LayerProps)}
          />
        ))}
      </Source>
      <Source
        {...heatmapConfig}
        id="solar_heatmap"
        type="vector"
        format="pbf"
        scheme="xyz"
      >
        <Layer
          beforeId={LOGISTIC_STATIC_LAYER}
          {...(heatLayers as LayerProps)}
        />
      </Source>
    </>
  );
};
