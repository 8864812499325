import { Layer, Source } from 'react-map-gl';
import { googleLabelLayer, googleLabelSource } from 'configs/map/google';
import { SATELLITE_STATIC_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { showLabelsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

export const GoogleLabelsSource = () => {
  const showLabels = useAppSelector(showLabelsSelector);

  if (!showLabels) {
    return null;
  }

  return (
    <Source {...googleLabelSource}>
      <Layer {...googleLabelLayer} beforeId={SATELLITE_STATIC_LAYER} />
    </Source>
  );
};
