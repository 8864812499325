import { useMemo } from 'react';
import * as turf from '@turf/turf';
import { circleOptions } from 'constants/map';
import { Feature } from 'geojson';
import { useAppSelector } from 'hooks';
import { ReactComponent as ArtilleryIcon } from 'images/features/artillery.svg';
import { measurementSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/measurementSlice/selectors';
import { drawToolSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice/selectors';
import { MapMeasureTools } from 'types';

import { CustomMarker } from 'components/ui';

import { PolygonSource } from '../PolygonSource';

const DEFAULT_MEASURE_ARTILLERY = {
  name: '',
  range: [],
  position: [0, 0],
};

export const ArtillerySource = () => {
  const drawTool = useAppSelector(drawToolSelector);
  const measurement = useAppSelector(measurementSelector);

  const { name, range, position } =
    measurement?.type === MapMeasureTools.measure_artillery
      ? measurement.value
      : DEFAULT_MEASURE_ARTILLERY;

  const showArtillery =
    drawTool === MapMeasureTools.measure_artillery && name && range;

  const activeArtilleryCircles: Feature[] = useMemo(
    () =>
      range.map((range, idx) => {
        const activeCircle = turf.circle(position, range, circleOptions);

        activeCircle.id = idx;

        return activeCircle;
      }),
    [position, range]
  );

  if (!showArtillery) {
    return null;
  }

  return (
    <>
      {activeArtilleryCircles.map((circle) => (
        <PolygonSource
          key={`artillery-circle-${circle.id}`}
          id={`artillery-circle-${circle.id}`}
          data={circle}
        />
      ))}
      <CustomMarker
        longitude={position[0]}
        latitude={position[1]}
        icon={<ArtilleryIcon />}
      />
    </>
  );
};
