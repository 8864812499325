export enum reducersNames {
  MONITORING = 'monitoring',
  MAP = 'map',
  MAP_V2 = 'map_v2',
  AUTH = 'auth',
  SERVICE = 'service',
  COMMON = 'common',
  REPORTS = 'reports',
}

export enum asyncActionsNames {
  GET_FILTERS = 'getFilters',
  GET_LANG_CORPUS_TREE = 'getCorpusTree',
  SEARCH_POSTS = 'searchPosts',
  ADD_FEATURE = 'addFeature',
  EDIT_FEATURE = 'editFeature',
  DELETE_FEATURE = 'deleteFeature',
  GET_GEOSPOOF = 'getGeospoof',
  GET_LAYER_CHILDREN = 'getLayerChildren',
  ADD_LAYER = 'addLayer',
  EDIT_LAYER = 'editLayer',
  DELETE_LAYER = 'deleteLayer',
  DELETE_MAP_ENTITIES = 'deleteMapEntities',
  LOGIN = 'login',
  GET_PRIVILEGES = 'getPrivileges',
  DELETE_FEATURE_MEDIA = 'deleteFeatureMedia',
  ADD_FEATURE_MEDIA = 'addFeatureMedia',
  ADD_BATCH_FEATURES = 'addBatchFeatures',
  GET_SEARCH_HISTORY = 'getSearchHistory',
  GET_SEARCH_INFO = 'getSearchInfo',
  GET_PICTURES_UPLOAD_ID = 'getRequestIdUserPictures',
  GET_ENTITY_PICTURES = 'getLinksUserPictures',
  GET_USER_LOCATION = 'getUserLocation',
  DOWNLOAD_FEATURES = 'downloadFeatures',
  GET_LOADED_USER_LOCATION = 'getLoadedUserLocation',
  ADD_REPORT = 'addReport',
  ADD_CLIENT = 'addСlient',
  GET_ATTRIBUTES = 'getAttributes',
  GET_USER_TABLE = 'getUserTable',
  GET_REPORTS_TABLE = 'getReportsTable',
  GET_GC_RANGES = 'getGCRanges',
  GET_ALL_GC_OBJECTS = 'getAllGCObjects',
  GET_IMAGERY = 'getImagery',
  GET_IMAGERY_PREVIEW = 'getImageryPreview',
  UPDATE_IMAGERY = 'updateImagery',
  GET_PARSERS = 'getParsers',
  ADD_BOOKMARK = 'addBookmark',
  DELETE_BOOKMARK = 'deleteBookmark',
  GET_BOOKMARK_IDS = 'getBookmarkIds',
  GET_MAP_V3_STATE = 'getMapV3State',
  GET_MAP_V2_STATE = 'getMapV2State',
  GET_MAP_ENTITIES = 'getMapEntities',
  GET_MAP_FILTERED_ENTITIES = 'getMapFilteredEntities',
  GET_MAP_INSCRIBED_ENTITIES = 'getMapInscribedEntities',
  GET_MAP_ENTITY_CHILDREN = 'getMapEntityChildren',
  GET_MAP_ENTITY_COUNTERS = 'getMapEntityCounters',
  UPSERT_ENTITY = 'upsertEntity',
  DELETE_ENTITY = 'deleteEntity',
  ADD_MEDIA_TO_ENTITY = 'addMediaToEntity',
  RELINK_ENTITY = 'relinkEntity',
  GET_SELF_USER = 'getSelfUser',
  SEARCH_ENTITY_BY_ID = 'searchEntityById',
}
