import { SourceProps } from 'react-map-gl';
import { RasterLayerSpecification, StyleSpecification } from 'mapbox-gl';

import DARK_V11 from './dark-v11.json';
import STREETS_V12 from './streets-v12.json';

export const mapboxSatelliteSource: SourceProps = {
  type: 'raster',
  url: 'mapbox://mapbox.satellite',
  name: 'mapbox-satellite-source',
  id: 'mapbox-satellite-source',
  maxzoom: 20,
  tileSize: 256,
};
export const mapboxSatelliteLayer: RasterLayerSpecification = {
  id: 'mapbox-satellite-layer',
  source: 'mapbox-satellite-source',
  type: 'raster',
  minzoom: 0,
};
export const mapboxSatelliteStyle: StyleSpecification = {
  version: 8,
  sprite: `${window.location.origin}/images/sprites/map-icons-sprite`,
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {
    'mapbox-satellite-source': mapboxSatelliteSource,
  },
  layers: [mapboxSatelliteLayer],
};

const adjustStyleJson = (style: StyleSpecification) => {
  // set custom sprite url
  style.sprite = `${window.location.origin}/images/sprites/map-icons-sprite`;

  // remove label layers from style
  style.layers = style.layers.filter((l) => l.type !== 'symbol');

  return style;
};

export const loadVectorMapboxStyle = (
  name: 'dark-v11' | 'streets-v12'
): StyleSpecification => {
  switch (name) {
    case 'dark-v11':
      return adjustStyleJson(DARK_V11 as unknown as StyleSpecification);
    case 'streets-v12':
      return adjustStyleJson(STREETS_V12 as unknown as StyleSpecification);
  }
};
