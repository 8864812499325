import { Position } from '@turf/turf';
import { exportEntity, searchEntities } from 'api/entities';
import { mapEntityParams, ROOT_HIERARCHY_ID } from 'constants/entities';
import { errorMessages, infoMessages } from 'constants/errors';
import { FeatureTypes } from 'constants/map';
import { Hierarchy, IMediaFile, ISelectOption } from 'interfaces';
import {
  EntitiesMap,
  EntityCountersMap,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';
import { CameraBounds, MapEntity, MapEntityTypes } from 'types';

import {
  arrayIntersection,
  convertEntityWithRelationsToSelectOptions,
  downloadObject,
  notify,
  replaceOrAppendArrayValue,
} from 'utils';

import {
  Entity,
  EntityParameter,
  EntityParametersIdTitleMap,
  GeometryParameter,
  MediaParameter,
  NumberParameter,
  ParametersIdTitleMap,
  PredefinedTemplate,
  TextParameter,
} from '../types/entities';

import { processHierarchy } from './hierarchy';
import { getDefaultMediaFile } from './media';

export interface PossibleValuesMap {
  [key: number]: string[];
}

const getMapEntityPath = (
  entitiesMap: EntitiesMap,
  entityId: number
): number[] => {
  const entity = entitiesMap[entityId];
  const parentId = entity?.parentIDs?.[0];

  if (!entity || !parentId) {
    return [ROOT_HIERARCHY_ID];
  }

  return [...getMapEntityPath(entitiesMap, parentId), parentId];
};

export const getMapEntityFullPath = (
  entitiesMap: EntitiesMap,
  entityId: number
) => [...getMapEntityPath(entitiesMap, entityId), entityId];

const moveOldParentCounters = (
  id: number,
  count: number,
  movingCount: number,
  newParentPath: number[],
  isParentsRelated: boolean
) => {
  // as recursion goes from bottom to top need limit unrelated path ids change
  const movingDiff = newParentPath.includes(id) ? count : count - movingCount;

  return isParentsRelated ? movingDiff : count - movingCount;
};

const moveNewParentCounters = (
  id: number,
  count: number,
  movingCount: number,
  oldParentPath: number[],
  isParentsRelated: boolean
) => {
  // as recursion goes from bottom to top need limit unrelated path ids change
  const movingDiff = oldParentPath.includes(id) ? count : count + movingCount;

  return isParentsRelated ? movingDiff : count + movingCount;
};

export const extractParamPossibleValues = (
  entityRelatedParameters: Record<number, EntityParameter[]>
): PossibleValuesMap => {
  const possibleValuesMap: PossibleValuesMap = {};

  for (const key in entityRelatedParameters) {
    const paramsArray = entityRelatedParameters[key];

    if (paramsArray) {
      paramsArray.forEach((param) => {
        possibleValuesMap[param.id] = (param.settings.allowedValues || []).map(
          (value) => String(value)
        );
      });
    }
  }
  return possibleValuesMap;
};

export const getEntityParameterIdByTitle = (
  parameters: EntityParameter[],
  title: string
) => String(parameters.find((parameter) => parameter.title === title)?.id ?? 0);

export const getEntityParametersIdTitleMap = <T = EntityParametersIdTitleMap>(
  parameters: EntityParameter[],
  parameterTitles: string[],
  mode: 'straight' | 'reverse' = 'straight'
): T =>
  parameterTitles.reduce((acc, curr) => {
    const id = getEntityParameterIdByTitle(parameters, curr);

    if (mode === 'straight') {
      acc[id] = curr;
    }

    if (mode === 'reverse') {
      acc[curr] = id;
    }

    return acc;
  }, {} as any);

export const getEntityParameterValue = <T>(
  entity: Entity | null | undefined,
  parametersIdTitleMap: EntityParametersIdTitleMap,
  parameterTitle: string
): T | null =>
  parametersIdTitleMap
    ? entity?.parameters[parametersIdTitleMap[parameterTitle]]?.value
    : null;

export const updateHierarchyProperty = <T extends keyof Hierarchy>(
  hierarchy: Hierarchy,
  id: number,
  property: T,
  value: Hierarchy[T]
) =>
  processHierarchy(hierarchy, id, (hierarchy) => ({
    ...hierarchy,
    [property]: value,
  }));

export const processEntityCounters = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  parentId: number,
  processFunc: (id: number, count: number) => number
): EntityCountersMap => {
  const grandParentId = entitiesMap[parentId]?.parentIDs?.[0];
  const count = countersMap[parentId];

  return {
    [parentId]: processFunc(parentId, count),
    ...(grandParentId &&
      processEntityCounters(
        entitiesMap,
        countersMap,
        grandParentId,
        processFunc
      )),
  };
};

export const increaseEntityObjectCounters = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  entityId: number,
  layerTemplateId: number,
  objectTemplateId: number
) => {
  const entity = entitiesMap[entityId];
  const parentId = entity?.parentIDs?.[0];
  const shouldAddCounter =
    !countersMap[entityId] && entity?.entity?.templateID === layerTemplateId;
  const shouldIncreaseSingle =
    parentId && entity?.entity?.templateID === objectTemplateId;

  return {
    ...countersMap,
    ...(shouldAddCounter && { [entity?.entity?.id]: 0 }),
    ...(shouldIncreaseSingle &&
      processEntityCounters(
        entitiesMap,
        countersMap,
        parentId,
        (_id, value) => value + 1
      )),
  };
};

export const decreaseEntityObjectCounters = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  entityId: number,
  layerTemplateId: number,
  objectTemplateId: number
) => {
  const entity = entitiesMap[entityId];
  const parentId = entity?.parentIDs?.[0];
  const decreasedEntityId = String(entity?.entity?.id);
  const { [decreasedEntityId]: _removedCounter, ...restCounters } = countersMap;

  const shouldRemoveCounter =
    parentId && entity?.entity?.templateID === layerTemplateId;
  const shouldDecreaseSingle =
    parentId && entity?.entity?.templateID === objectTemplateId;
  const shouldDecreaseMultiple =
    parentId && entity?.entity?.templateID === layerTemplateId;

  const currentCountersMap = shouldRemoveCounter ? restCounters : countersMap;

  return {
    ...currentCountersMap,
    ...(shouldDecreaseSingle &&
      processEntityCounters(
        entitiesMap,
        currentCountersMap,
        parentId,
        (_id, value) => value - 1
      )),
    ...(shouldDecreaseMultiple &&
      processEntityCounters(
        entitiesMap,
        currentCountersMap,
        parentId,
        (_id, value) => value - countersMap[decreasedEntityId]
      )),
  };
};

export const moveMapEntityCounter = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  entityId: number,
  oldParentEntityId: number,
  newParentEntityId: number,
  layerTemplateId: number
) => {
  const entity = entitiesMap[entityId];
  const oldParentPath = getMapEntityFullPath(entitiesMap, oldParentEntityId);
  const newParentPath = getMapEntityFullPath(entitiesMap, newParentEntityId);
  const isParentsRelated =
    arrayIntersection(oldParentPath, newParentPath).length > 1;
  const movingCount =
    entity.entity.templateID === layerTemplateId ? countersMap[entityId] : 1;

  return {
    ...countersMap,
    ...processEntityCounters(
      entitiesMap,
      countersMap,
      oldParentEntityId,
      (_id, count) =>
        moveOldParentCounters(
          _id,
          count,
          movingCount,
          newParentPath,
          isParentsRelated
        )
    ),
    ...processEntityCounters(
      entitiesMap,
      countersMap,
      newParentEntityId,
      (id, count) =>
        moveNewParentCounters(
          id,
          count,
          movingCount,
          oldParentPath,
          isParentsRelated
        )
    ),
  };
};

export const getMapObjectEntityValues = (
  parametersMap: EntityParametersIdTitleMap,
  mapObjectEntity: Entity
) => {
  const name = mapObjectEntity.title;

  const getParameterWithSetEntityAndMap = <T>(parameter: mapEntityParams) =>
    getEntityParameterValue<T>(mapObjectEntity, parametersMap, parameter);

  const type = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.TYPE
  );

  const status = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.STATUS
  );

  const geometry = getParameterWithSetEntityAndMap<GeometryParameter>(
    mapEntityParams.GEOMETRY
  );

  const place = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.PLACE
  );

  const date = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.DATE
  );

  const description = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.DESCRIPTION
  );

  const media =
    getParameterWithSetEntityAndMap<MediaParameter>(
      mapEntityParams.MEDIA
    )?.map?.((file: IMediaFile) => getDefaultMediaFile(file)) ?? null;

  const color = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.COLOR
  );

  const opacity = getParameterWithSetEntityAndMap<NumberParameter>(
    mapEntityParams.OPACITY
  );

  const source = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.SOURCE
  );

  const reliability = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.RELIABILITY
  );

  const relevance = getParameterWithSetEntityAndMap<TextParameter>(
    mapEntityParams.RELEVANCE
  );

  return {
    name,
    type,
    status,
    geometry,
    place,
    date,
    description,
    media,
    color,
    opacity,
    source,
    reliability,
    relevance,
  };
};

export const updateMapEntity = (
  entitiesMap: EntitiesMap,
  entity: Entity
): EntitiesMap => ({
  ...entitiesMap,
  [String(entity.id)]: {
    ...entitiesMap[String(entity.id)],
    entity,
  },
});

export const moveMapEntity = (
  entitiesMap: EntitiesMap,
  entityId: number,
  oldParentEntityId: number,
  newParentEntityId: number
) => {
  const entity = entitiesMap[entityId];
  const oldParentEntity = entitiesMap[oldParentEntityId];
  const newParentEntity = entitiesMap[newParentEntityId];

  return {
    ...entitiesMap,
    ...(oldParentEntity && {
      [oldParentEntityId]: {
        ...oldParentEntity,
        childIDs: oldParentEntity.childIDs.filter((id) => id !== entityId),
      },
    }),
    ...(entity && {
      [entityId]: {
        ...entity,
        parentIDs: replaceOrAppendArrayValue(
          entity.parentIDs,
          newParentEntityId,
          (id) => id === oldParentEntityId
        ),
      },
    }),
    ...(newParentEntity && {
      [newParentEntityId]: {
        ...newParentEntity,
        childIDs: [...newParentEntity.childIDs, entityId],
      },
    }),
  };
};

export const getInitialLayerEntity = (
  mapLayerTemplateID: number,
  parentId?: number
): Entity => ({
  id: 0,
  templateID: mapLayerTemplateID,
  title: '',
  parentEntityID: parentId,
  createdBy: {},
  parameters: {},
});

export const getSelectOptionsFromSearch = async (
  mapObjectTemplateId: number,
  optionsCallback: (options: ISelectOption[]) => void,
  filterTemplateIDs?: number[],
  selectOptionsExcludeIDs?: number[],
  filterByReadwrite?: boolean
) => {
  await searchEntities({
    maxDepth: 9999,
    parentEntityID: 0,
    templateIDs: filterTemplateIDs || [],
  }).then((res) => {
    const options = convertEntityWithRelationsToSelectOptions(
      res.entities,
      mapObjectTemplateId,
      selectOptionsExcludeIDs || [],
      filterByReadwrite
    );
    optionsCallback(options);
  });
};

export const getSelectOptionsFromEnumParam = (
  param: EntityParameter,
  checkedValues?: string[]
): ISelectOption[] =>
  param.settings.allowedValues
    ? param.settings.allowedValues.map((v) => ({
        label: v,
        value: v,
        checked: !!checkedValues && checkedValues.includes(v),
      }))
    : [];

export const getSelectOptionsByMapEntityParam = (
  targetParam: mapEntityParams,
  mapObjectTemplate: PredefinedTemplate
) => {
  const param = mapObjectTemplate.parameters.find(
    (param) => param.title === targetParam
  );

  return param ? getSelectOptionsFromEnumParam(param) : [];
};

export const changeEntityParam = (
  mapEntity: MapEntity,
  paramIdMap: ParametersIdTitleMap,
  field: mapEntityParams,
  value: any
): MapEntity => ({
  ...mapEntity,
  entity: {
    ...mapEntity.entity,
    parameters: {
      ...mapEntity.entity.parameters,
      [paramIdMap[field]]: {
        value: value,
      },
    },
  },
});

export const exportEntityWrapper = async (id: number) => {
  notify.success(infoMessages.ENTITY_EXPORT_STARTED);
  await exportEntity({
    type: 'kmz',
    entityID: id,
  })
    .then((response) => {
      downloadObject(response.data, 'AstraExport.kmz');
    })
    .catch(() => notify.error(errorMessages.ENTITY_EXPORT_ERROR));
};

export const constructIdentifier = (id: number | string) =>
  String(id).padStart(5, '0');

export const getMapEntityChildrenId = (
  entitiesMap: EntitiesMap,
  entityId: number,
  filterFunc: (entity: MapEntity) => boolean
): number[] => {
  const entity: MapEntity | undefined = entitiesMap[entityId];

  if (!entity) {
    return [];
  }

  return entity.childIDs.reduce((acc, curr) => {
    const intermediateAcc = entityTypeFilterFunc(MapEntityTypes.LAYER)(
      entitiesMap[curr]
    )
      ? [...acc, ...getMapEntityChildrenId(entitiesMap, curr, filterFunc)]
      : acc;

    return intermediateAcc.concat(filterFunc(entitiesMap[curr]) ? [curr] : []);
  }, [] as number[]);
};

export const entityTypeFilterFunc =
  (entityType: MapEntityTypes) => (entity: MapEntity) =>
    entity.info.type === entityType;

const updateCurrentBoundary = (
  points: Position[],
  currentBoundary: CameraBounds
) =>
  points.reduce<CameraBounds>((pointAcc, pointCurr) => {
    const isWesternmostPoint = pointCurr[0] < pointAcc[0];
    const isSouthernmostPoint = pointCurr[1] < pointAcc[1];
    const isEasternmostPoint = pointCurr[0] > pointAcc[2];
    const isNorthernmostPoint = pointCurr[1] > pointAcc[3];
    return [
      isWesternmostPoint ? pointCurr[0] : pointAcc[0],
      isSouthernmostPoint ? pointCurr[1] : pointAcc[1],
      isEasternmostPoint ? pointCurr[0] : pointAcc[2],
      isNorthernmostPoint ? pointCurr[1] : pointAcc[3],
    ];
  }, currentBoundary);

export const getBounds = (
  entitiesMap: EntitiesMap,
  parametersIdTitleMap: ParametersIdTitleMap,
  objectIds: number[]
) =>
  objectIds.reduce(
    (acc, curr) => {
      const currEntity = entitiesMap[curr];
      const currGeometry: GeometryParameter | null = getEntityParameterValue(
        currEntity.entity,
        parametersIdTitleMap,
        mapEntityParams.GEOMETRY
      );
      if (!currGeometry) return acc;

      const currType = currGeometry.type;

      switch (currType) {
        case FeatureTypes.POINT:
          return updateCurrentBoundary(
            [currGeometry.coordinates] as Position[],
            acc
          );
        case FeatureTypes.LINE:
          return updateCurrentBoundary(
            currGeometry.coordinates as Position[],
            acc
          );
        case FeatureTypes.POLYGON:
          return updateCurrentBoundary(
            currGeometry.coordinates.flat() as Position[],
            acc
          );
        default:
          return acc;
      }
    },
    // [west, south, east, north]
    [180, 90, -180, -90] as CameraBounds
  );
