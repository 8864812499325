import { FC } from 'react';
import cn from 'classnames';

import { IObjectPopupProps, ObjectPopup } from 'components/Map/ObjectPopup';
type TObjectPreviewPopup = IObjectPopupProps;

export const ObjectPreviewPopup: FC<TObjectPreviewPopup> = ({
  children,
  className,
  ...props
}) => (
  <ObjectPopup
    anchor="bottom"
    tip={false}
    closeButton={false}
    keyboard
    maxWidth={props.maxWidth}
    style={{ width: props.maxWidth }}
    className={cn(
      'bg-dark border border-tpg_light border-solid rounded-[10px]',
      className
    )}
    {...props}
  >
    <div className="w-full overflow-hidden rounded-[inherit]">{children}</div>
  </ObjectPopup>
);
