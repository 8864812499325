import { FC, useCallback } from 'react';
import { DrawCreateEvent } from '@mapbox/mapbox-gl-draw';
import { MAP_CREATE_MODE_PREFIX } from 'constants/mapControl';
import { useMapControl } from 'hooks/useMapDraw';
import {
  UseMapboxDrawEventSubscriptionProps,
  useMapDrawEventSubscription,
} from 'hooks/useMapDrawEventsSubscription';
import { MapDrawTool } from 'types';

interface MapDrawControlProps extends UseMapboxDrawEventSubscriptionProps {
  mode: MapDrawTool;
}

// required to init map draw modes. Should be child of <Map/> component
export const MapDrawControl: FC<MapDrawControlProps> = ({
  mode,
  onCreate,
  onStop,
  onModeChange,
  onDragVertex,
  onDragFeature,
  onDragMove,
  onMouseMove,
  onClick,
  onTap,
}) => {
  const handleDrawCreate = useCallback(
    (e: DrawCreateEvent) =>
      mode.startsWith(MAP_CREATE_MODE_PREFIX) && onCreate?.(e),
    [onCreate, mode]
  );

  useMapControl();
  useMapDrawEventSubscription({
    onCreate: handleDrawCreate,
    onStop,
    onModeChange,
    onDragVertex,
    onDragFeature,
    onDragMove,
    onMouseMove,
    onClick,
    onTap,
  });

  return null;
};
