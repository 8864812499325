// TODO: unused until features are ready
import { FC } from 'react';
import { ReactComponent as ImportIcon } from 'images/newIcons/import.svg';
import { ReactComponent as ObjectIcon } from 'images/newIcons/objectsPassive.svg';
import { ReactComponent as ShareIcon } from 'images/newIcons/shareEntity.svg';

import { IconButton, IconButtonProps } from 'components/ui/IconButton';

interface IObjectPreviewFooter {
  handleShare: () => void;
  handleExport: () => void;
  handleRemove: () => void;
}

export const ObjectPreviewFooter: FC<IObjectPreviewFooter> = ({
  handleShare,
  handleExport,
  handleRemove,
}) => {
  const OBJECT_PREVEIW_FOOTER_BUTTONS: IconButtonProps[] = [
    {
      icon: <ImportIcon />,
      title: 'Скачать в KMZ',
      onClick: handleExport,
    },
    {
      icon: <ShareIcon />,
      title: 'Поделиться',
      onClick: handleShare,
    },
    {
      icon: <ObjectIcon className="w-[17px] h-[17px]" />,
      title: 'Скрыть с карты',
      onClick: handleRemove,
    },
  ];

  return (
    <div
      className={`flex justify-center gap-3 h-[32px]
  m-auto w-[calc(100%+2px)]
  bg-light border-0 border-t border-solid border-tpg_light 
  rounded-b-[inherit] tpg-c2 [&>svg]:w-4 [&>svg]:h-4`}
    >
      {OBJECT_PREVEIW_FOOTER_BUTTONS.map((props, idx) => (
        <IconButton key={idx} {...props} />
      ))}
    </div>
  );
};
