import { FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  BOUNDARY_GRID_CELL_LAYER,
  BOUNDARY_GRID_CELL_NUMBER_LAYER,
  BOUNDARY_GRID_SOURCE,
  MAP_MAX_ZOOM_LEVEL,
} from 'constants/map';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { FillLayerSpecification, SymbolLayerSpecification } from 'mapbox-gl';
import { boundaryGridMapSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice/selectors';
import { BoundaryGridCell } from 'types';

import { prepareFeatureCollection } from 'utils';

/*
 * originally baseTextSize * Math.sqrt(area) / Math.pow(2, maxZoom - zoom);
 * but first operand is relied on magic that helps to consider long labels (special case for 12x12 grid)
 */
const getStaticFontSize = (baseFontSize: number, zoom: number) => [
  '*',
  [
    '+',
    ['/', baseFontSize, ['get', 'size']],
    ['/', ['length', ['to-string', ['get', 'size']]], 2],
  ],
  ['/', ['sqrt', ['get', 'area']], ['^', 2, MAP_MAX_ZOOM_LEVEL - zoom]],
];

const boundaryGridCellLayerConfig: FillLayerSpecification = {
  id: BOUNDARY_GRID_CELL_LAYER,
  type: 'fill',
  source: BOUNDARY_GRID_SOURCE,
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['get', 'filled'], true],
      '#4281e6',
      'transparent',
    ],
    'fill-outline-color': '#053C92',
  },
};

const boundaryGridCellNumberLayerConfig = (
  zoom: number
): SymbolLayerSpecification => ({
  id: BOUNDARY_GRID_CELL_NUMBER_LAYER,
  type: 'symbol',
  source: BOUNDARY_GRID_SOURCE,
  layout: {
    'text-field': ['get', 'label'],
    'text-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      getStaticFontSize(8, zoom),
      5,
      getStaticFontSize(8, zoom),
      10,
      getStaticFontSize(8, zoom),
      15,
      getStaticFontSize(8, zoom),
      20,
      getStaticFontSize(8, zoom),
      25,
      getStaticFontSize(8, zoom),
    ],
    'text-allow-overlap': false,
  },
  paint: {
    'text-color': [
      'case',
      ['boolean', ['get', 'showLabel'], true],
      'white',
      'transparent',
    ],
  },
});

export const BoundaryGridSource: FC = () => {
  const mapRef = useMapRef();
  const boundaryGridMap = useAppSelector(boundaryGridMapSelector);

  const zoom = mapRef.mapRef.current?.getZoom?.() ?? 1;
  const visibleBoundaryGridData = useMemo(
    () =>
      prepareFeatureCollection(
        Object.values(boundaryGridMap).reduce(
          (acc, curr) => (curr.visible ? [...acc, ...curr.features] : acc),
          [] as BoundaryGridCell[]
        )
      ),
    [boundaryGridMap]
  );

  return (
    <Source
      id={BOUNDARY_GRID_SOURCE}
      type="geojson"
      data={visibleBoundaryGridData}
    >
      <Layer {...boundaryGridCellLayerConfig} />
      <Layer {...boundaryGridCellNumberLayerConfig(zoom)} />
    </Source>
  );
};
