import { MarkerEvent } from 'react-map-gl/dist/esm/types';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { ReactComponent as AimIcon } from 'images/newIcons/aim.svg';
import { MapTouchEvent } from 'mapbox-gl';
import { MapMouseEvent } from 'mapbox-gl';
import { showAimSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { CustomMarker } from 'components/ui';

interface AimSourceProps {
  onMapContextMenuOpen: (e: MapMouseEvent | MapTouchEvent) => void;
}

export const AimSource = ({ onMapContextMenuOpen }: AimSourceProps) => {
  const { mapRef } = useMapRef();
  const showAim = useAppSelector(showAimSelector);

  if (!showAim || !mapRef.current) {
    return null;
  }

  const mapCenter = mapRef.current.getCenter();

  const mapContainer = mapRef.current.getContainer();

  const setMapContextMenuWithDelay = (e: MapMouseEvent | MapTouchEvent) => {
    setTimeout(() => {
      onMapContextMenuOpen(e);
    }, 100);
  };

  const constructMapLayerEvent = (e: MarkerEvent<any, any>) =>
    ({
      ...e,
      lngLat: { lng: mapCenter.lng, lat: mapCenter.lat },
      point: {
        x: mapContainer.clientWidth / 2,
        y: mapContainer.clientHeight / 2,
      },
    } as MapMouseEvent | MapTouchEvent);

  return (
    <CustomMarker
      longitude={mapCenter.lng}
      latitude={mapCenter.lat}
      icon={<AimIcon />}
      onClick={(e) => setMapContextMenuWithDelay(constructMapLayerEvent(e))}
    />
  );
};
