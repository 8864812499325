import { FC, memo, useMemo, useState } from 'react';
import { OpenMap } from 'react-arborist/dist/module/state/open-slice';
import {
  AccessRuleCode,
  MAP_ENTITY_HIERARCHY_NEST_LEVEL,
} from 'constants/entities';
import { ENTITY_DETAILS_MODAL, ENTITY_PREVIEW_MODAL } from 'constants/modals';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import {
  deleteEntityThunk,
  getMapEntityChildrenThunk,
  relinkEntityThunk,
  upsertEntityThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { sharedEntityIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import {
  EntitiesMap,
  EntityCountersMap,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { EntityParametersIdTitleMap, PredefinedTemplate } from 'types/entities';
import { EntityStorages, MapEntity, MapEntityTypes } from 'types/map';
import useResizeObserver from 'use-resize-observer';

import { AccessControlDetailsProps } from 'components/Access/AccessControlDetails';
import { IContextMenuTooltipItem } from 'components/ContextMenuTooltip';
import { LayerDetailsProps } from 'components/Map/LayerDetails';
import { Tree, TTreeProps } from 'components/Tree';
import { Node } from 'components/Tree/Node';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import { calculateGeometryCenter, copyShareLinkToBuffer } from 'utils';
import {
  entityTypeFilterFunc,
  exportEntityWrapper,
  getMapEntityChildrenId,
  getMapEntityFullPath,
  getMapObjectEntityValues,
} from 'utils/entity';

import { ImportDetailsProps } from '../../ImportDetails/ImportDetails';
import { UpdateLayerChildrenDetailsProps } from '../../UpdateLayerChildrenDetails/UpdateLayerChildrenDetails';

import { renderNodeArrow } from './NodeArrow';
import { renderNodeCounter } from './NodeCounter';
import { renderNodeIcon } from './NodeIcon';
import { renderNodeName } from './NodeName';
import { renderNodePrefix } from './NodePrefix';
import {
  EntityNode,
  EntityNodeData,
  EntityNodeInfo,
  EntityNodeMoveData,
  EntityNodeState,
} from './types';
import {
  getEntityChildrenWithUpdatedState,
  getNodeDropDisabled,
  NODE_INDENT,
} from './utils';

interface EntitiesTreeProps
  extends TTreeProps<MapEntity, MapEntity, EntityNodeInfo, EntityNodeState> {
  data: EntityNodeData[];
  objectTemplate?: PredefinedTemplate;
  entitiesMap: EntitiesMap;
  entityCountersMap: EntityCountersMap;
  objectParametersMap: EntityParametersIdTitleMap;
  search: string;
  onChangeLayerDetailsProps: (props: LayerDetailsProps | undefined) => void;
  onChangeAccessControlDetailsProps: (
    props: AccessControlDetailsProps | undefined
  ) => void;
  onChangeImportDetailsProps: (props: ImportDetailsProps | undefined) => void;
  onChangeUpdateLayerChildrenDetailsProps: (
    props: UpdateLayerChildrenDetailsProps | undefined
  ) => void;
}

export const EntitiesTree: FC<EntitiesTreeProps> = memo(
  ({
    data,
    objectTemplate,
    entitiesMap,
    entityCountersMap,
    objectParametersMap,
    search,
    onChangeLayerDetailsProps: setLayerDetailsProps,
    onChangeAccessControlDetailsProps: setAccessControlDetailsProps,
    onChangeImportDetailsProps: setImportDetailsProps,
    onChangeUpdateLayerChildrenDetailsProps: setUpdateLayerChildrenDetailsProps,
    ...props
  }) => {
    const { ref, height } = useResizeObserver();
    const { mapRef } = useMapRef();
    const entityId = useAppSelector(sharedEntityIdSelector);
    const [processedNode, setProcessedNode] = useState<EntityNode | null>(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const dispatch = useAppDispatch();

    const hasData = !!data.length;

    /* *** Common handlers *** */

    const handleDeleteModalClose = () => {
      setProcessedNode(null);
      setDeleteModalOpen(false);
    };

    const handleLayerModalClose = () => {
      setProcessedNode(null);
      setLayerDetailsProps(undefined);
      setAccessControlDetailsProps(undefined);
      setImportDetailsProps(undefined);
      setUpdateLayerChildrenDetailsProps(undefined);
    };

    const zoomToObject = (node: EntityNode) => {
      const { geometry } = getMapObjectEntityValues(
        objectParametersMap,
        node.data.entity.entity
      );

      if (!mapRef.current || !geometry) {
        return;
      }

      const zoom = mapRef.current?.getZoom() ?? 8;
      const center = calculateGeometryCenter(geometry) as [number, number];

      mapRef.current.flyTo({
        zoom: zoom,
        center: center,
        essential: true,
      });
    };

    const handleExportEntity = async (node: EntityNode) => {
      if (!node.data.entity.entity.id) {
        return;
      }
      await exportEntityWrapper(node.data.entity.entity.id);
    };

    /* *** Folder handlers *** */

    const onFolderOpen = (isOpen: boolean, node: EntityNode) => {
      const shouldGetEntityChildren = !!(
        node.data.info.level &&
        !((node.data.info.level + 1) % (MAP_ENTITY_HIERARCHY_NEST_LEVEL - 1)) &&
        node.data.state.storage === EntityStorages.NONE
      );

      shouldGetEntityChildren &&
        dispatch(
          getMapEntityChildrenThunk({
            parentEntityID: Number(node.id),
            query: search,
            storage: EntityStorages.PARTIAL,
            keepState: true,
          })
        );
    };

    const handleFolderSelect = async (e: unknown, node: EntityNode) => {
      const shouldGetEntityChildren =
        node.data.state.storage !== EntityStorages.FULL;

      if (shouldGetEntityChildren) {
        dispatch(
          getMapEntityChildrenThunk({
            parentEntityID: Number(node.id),
            maxDepth: 9999,
            query: search,
            storage: EntityStorages.FULL,
          })
        );
      } else {
        const selectedEntities = getEntityChildrenWithUpdatedState(
          entitiesMap,
          node.id,
          { active: true }
        );

        dispatch(
          mapEntitiesActions.setEntitiesMap({
            ...entitiesMap,
            ...selectedEntities,
          })
        );
      }
    };

    const handleFolderDeselect = (e: unknown, node: EntityNode) => {
      const deselectedEntities = getEntityChildrenWithUpdatedState(
        entitiesMap,
        node.id,
        { active: false }
      );

      dispatch(
        mapEntitiesActions.setEntitiesMap({
          ...entitiesMap,
          ...deselectedEntities,
        })
      );
    };

    const handleFolderEditingChange = (editing: boolean, node: EntityNode) =>
      editing ? node.edit() : node.reset();

    const handleCreateFolder = (node: EntityNode) => {
      setProcessedNode(node);
      setLayerDetailsProps({
        onClose: handleLayerModalClose,
        parentId: Number(node.data.id),
      });
    };

    const handleEditFolderDetails = (node: EntityNode) => {
      setProcessedNode(node);
      setLayerDetailsProps({
        onClose: handleLayerModalClose,
        layer: node,
      });
    };

    const handleFolderClick = async (e: unknown, node: EntityNode) => {
      if (node.data.state.selected) {
        handleFolderDeselect(e, node);
      } else {
        handleFolderSelect(e, node);
      }
    };

    const handleImportToFolder = (node: EntityNode) => {
      setProcessedNode(node);
      setImportDetailsProps({
        parentEntityID: Number(node.data.id),
        onClose: handleLayerModalClose,
      });
    };

    const handleEditFolderChildren = (node: EntityNode) => {
      setProcessedNode(node);
      setUpdateLayerChildrenDetailsProps({
        entityID: Number(node.data.id),
        onClose: handleLayerModalClose,
      });
    };

    /* *** Object handlers *** */

    const handleObjectSelect = (e: unknown, node: EntityNode) => {
      const selectedEntity = entitiesMap[node.id];

      zoomToObject(node);
      dispatch(
        mapEntitiesActions.setEntitiesMap({
          ...entitiesMap,
          [node.id]: {
            ...selectedEntity,
            state: { ...selectedEntity.state, active: true },
          },
        })
      );
    };

    const handleObjectDeselect = (e: unknown, node: EntityNode) => {
      const deselectedEntity = entitiesMap[node.id];

      dispatch(
        mapEntitiesActions.setEntitiesMap({
          ...entitiesMap,
          [node.id]: {
            ...deselectedEntity,
            state: { ...deselectedEntity.state, active: false },
          },
        })
      );

      dispatch(modalsActions.removeModal(ENTITY_PREVIEW_MODAL));
      dispatch(modalsActions.removeModal(ENTITY_DETAILS_MODAL));
    };

    const handleObjectEditingEnable = (node: EntityNode) => node.edit();
    const handleObjectEditingReset = (node: EntityNode) => node.reset();

    const handleEditObjectDetails = (node: EntityNode) => {
      const mapEntity = entitiesMap[node.data.id];

      if (mapEntity) {
        dispatch(modalsActions.removeModal(ENTITY_PREVIEW_MODAL));
        dispatch(
          modalsActions.addModal({
            id: ENTITY_DETAILS_MODAL,
            isOpen: true,
            props: mapEntity,
          })
        );
      }
    };

    const handleObjectClick = (e: unknown, node: EntityNode) =>
      node.data.state.selected
        ? handleObjectDeselect(e, node)
        : handleObjectSelect(e, node);

    const handleObjectDoubleClick = (e: unknown, node: EntityNode) => {
      handleObjectSelect(e, node);
      handleEditObjectDetails(node);
    };

    /* *** Node handlers *** */

    const onNodeOpen = (isOpen: boolean, node: EntityNode) => {
      if (!node.data.isFolder) {
        return;
      }

      onFolderOpen(isOpen, node);
    };

    const handleNodeClick = (e: unknown, node: EntityNode) =>
      node.data.isFolder
        ? handleFolderClick(e, node)
        : handleObjectClick(e, node);

    const handleNodeDoubleClick = (e: unknown, node: EntityNode) =>
      node.data.isFolder ? undefined : handleObjectDoubleClick(e, node);

    const handleNodeToggle = (node: EntityNode) => handleNodeClick(null, node);

    const handleNodeEditingChange = (editing: boolean, node: EntityNode) => {
      if (node.data.isFolder) {
        handleFolderEditingChange(editing, node);

        return;
      }

      node.isEditing && handleObjectEditingReset(node);
    };

    const handleNodeEdit = (value: string, node: EntityNode) => {
      const editedEntity = node.data.entity.entity;

      dispatch(upsertEntityThunk({ ...editedEntity, title: value }));
    };

    const handleNodeMove = (data: EntityNodeMoveData) => {
      const oldParentEntityId = Number(data.dragNodes[0].parent?.id) || 0;
      const newParentEntityId = Number(data.parentId) || 0;

      const targetEntity = data.dragNodes[0];
      if (
        !targetEntity?.id ||
        targetEntity.data.entity.accessType < AccessRuleCode.READWRITE
      ) {
        return;
      }

      const relinkParams = {
        entityId: Number(targetEntity.id),
        oldParentEntityId: oldParentEntityId,
        newParentEntityId: newParentEntityId,
      };

      dispatch(relinkEntityThunk(relinkParams));
    };

    const handleNodeDelete = async () => {
      if (!processedNode) {
        return null;
      }

      const entityId = Number(processedNode.data.id);
      await dispatch(deleteEntityThunk(entityId));

      setDeleteModalOpen(false);
    };

    const handleDeleteModalOpen = (node: EntityNode) => {
      setProcessedNode(node);
      setDeleteModalOpen(true);
    };

    const handleAccessControl = (node: EntityNode) => {
      setProcessedNode(node);
      setAccessControlDetailsProps({
        entity: node.data.entity,
        onClose: handleLayerModalClose,
      });
    };

    const handleChangeDefaultProject = (node: EntityNode) => {
      isDefaultProject(node)
        ? localStorage.removeItem('default_project')
        : localStorage.setItem('default_project', node.data.id);
    };

    const isDefaultProject = (node: EntityNode) => {
      return String(node.data.id) === localStorage.getItem('default_project');
    };

    const handleShareModalOpen = (node: EntityNode) => {
      copyShareLinkToBuffer(parseInt(node.data.id));
    };

    const filterAdminContextMenuOptions =
      (node: EntityNode) => (option: { requiresAdminAccess?: boolean }) =>
        node.data.entity.isUserBranchAdmin || !option.requiresAdminAccess;

    const filterWriteContextMenuOptions =
      (node: EntityNode) => (option: { requiresWriteAccess?: boolean }) =>
        node.data.entity.accessType > AccessRuleCode.READONLY ||
        !option.requiresWriteAccess;

    const getFolderContextMenu = (node: EntityNode) => {
      const contextMenuOptions = [
        {
          title: 'Скачать в KMZ',
          onClick: () => handleExportEntity(node),
        },
        {
          title: 'Импортировать данные',
          onClick: () => handleImportToFolder(node),
          requiresWriteAccess: true,
        },
        {
          title: `${
            isDefaultProject(node) ? 'Убрать' : 'Сделать'
          } по умолчанию`,
          onClick: () => handleChangeDefaultProject(node),
        },
        {
          title: 'Массовое изменение',
          onClick: () => handleEditFolderChildren(node),
          requiresWriteAccess: true,
        },
        {
          title: 'Редактировать',
          onClick: () => handleEditFolderDetails(node),
          requiresWriteAccess: true,
        },
        {
          title: 'Создать подпроект',
          onClick: () => handleCreateFolder(node),
          requiresWriteAccess: true,
        },
        {
          title: 'Управление доступом',
          onClick: () => handleAccessControl(node),
          requiresAdminAccess: true,
        },
        {
          title: 'Удалить',
          onClick: () => handleDeleteModalOpen(node),
          requiresWriteAccess: true,
        },
        {
          title: 'Поделиться',
          onClick: () => handleShareModalOpen(node),
        },
      ];

      return contextMenuOptions
        .filter(filterAdminContextMenuOptions(node))
        .filter(filterWriteContextMenuOptions(node));
    };

    const getObjectContextMenu = (node: EntityNode) => {
      const contextMenuOptions = [
        {
          title: 'Скачать в KMZ',
          onClick: () => handleExportEntity(node),
        },
        {
          title: 'Переименовать',
          onClick: () => handleObjectEditingEnable(node),
          requiresWriteAccess: true,
        },
        {
          title: 'Редактировать',
          onClick: () => handleEditObjectDetails(node),
          requiresWriteAccess: true,
        },
        {
          title: 'Удалить',
          onClick: () => handleDeleteModalOpen(node),
          requiresWriteAccess: true,
        },
        {
          title: 'Поделиться',
          onClick: () => handleShareModalOpen(node),
        },
      ];

      return contextMenuOptions.filter(filterWriteContextMenuOptions(node));
    };

    const handleNodeContextMenu = (
      node: EntityNode
    ): IContextMenuTooltipItem[] => {
      if (node.data.isFolder) {
        return getFolderContextMenu(node);
      }

      return getObjectContextMenu(node);
    };

    const initialOpenState: OpenMap = useMemo(() => {
      if (!entityId) return {};
      const parents = getMapEntityFullPath(entitiesMap, Number(entityId));
      const childrenLayers = getMapEntityChildrenId(
        entitiesMap,
        Number(entityId),
        entityTypeFilterFunc(MapEntityTypes.LAYER)
      );

      return Object.values(entitiesMap).reduce((acc, val) => {
        return {
          ...acc,
          [String(val.entity.id)]:
            parents.includes(val.entity.id) ||
            childrenLayers.includes(val.entity.id),
        };
      }, {} as OpenMap);
    }, [entityId]);

    return (
      <>
        <div ref={ref} className="w-full h-full pl-4 overflow-auto">
          {hasData ? (
            <Tree<MapEntity, MapEntity, EntityNodeInfo, EntityNodeState>
              data={data}
              height={height}
              indent={NODE_INDENT}
              disableEdit={false}
              disableDrag={false}
              disableDrop={getNodeDropDisabled}
              onMove={handleNodeMove}
              initialOpenState={initialOpenState}
              {...props}
            >
              {(nodeRendererProps) => (
                <Node<MapEntity, MapEntity, EntityNodeInfo, EntityNodeState>
                  renderArrow={renderNodeArrow}
                  renderIcon={renderNodeIcon}
                  renderName={renderNodeName}
                  renderCounter={(node) =>
                    renderNodeCounter(node, () => handleNodeToggle(node))
                  }
                  renderPostfix={renderNodePrefix}
                  getContextMenu={handleNodeContextMenu}
                  onOpen={onNodeOpen}
                  onEditingChange={handleNodeEditingChange}
                  onEdit={handleNodeEdit}
                  onClick={handleNodeClick}
                  onDoubleClick={handleNodeDoubleClick}
                  className="w-full h-full pr-4 flex items-center"
                  {...nodeRendererProps}
                />
              )}
            </Tree>
          ) : (
            <span className="tpg-c1 text-tpg_base">
              {NOTHING_WAS_FOUND_MESSAGE}
            </span>
          )}
        </div>
        {isDeleteModalOpen && (
          <ConfirmModal
            title="Вы уверены, что хотите удалить?"
            description="Вы можете потерять ценные данные."
            onConfirm={handleNodeDelete}
            onClose={handleDeleteModalClose}
          />
        )}
      </>
    );
  }
);

EntitiesTree.displayName = 'EntitiesTree';
