import {
  GeocoderSearchRequest,
  GeocoderSearchResponse,
} from 'interfaces/geocoder';
import axios from 'services/axios/geocoder';

export const search = <T extends GeocoderSearchResponse>(
  request: GeocoderSearchRequest
) =>
  axios.get<T>('/search', {
    params: request,
    headers: { 'accept-language': 'ru-RU' },
  });
