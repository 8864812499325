import { FC, memo, PropsWithChildren } from 'react';
import { Marker, MarkerProps } from 'react-map-gl';

const MarkerWithTouch: FC<PropsWithChildren<MarkerProps>> = ({
  children,
  ...props
}) => (
  <div onTouchEnd={(e) => e.stopPropagation()}>
    <Marker {...props}>{children}</Marker>
  </div>
);

export default memo(MarkerWithTouch);
