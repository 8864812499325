import { FC, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useEnterDownControlled } from 'hooks/useEnterDown';
import { useEscapeDownControlled } from 'hooks/useEscapeDown';

import { Control } from './types';

const keyDownFallback = () => null;

export const ToggleControl: FC<Control> = ({
  icon,
  isActive,
  className,
  onToggle,
  onEscapeDown,
  onEnterDown,
}) => {
  const [subscribeToEscapeDown, unsubscribeFromEscapeDown] =
    useEscapeDownControlled(onEscapeDown ?? keyDownFallback);

  const [subscribeToEnterDown, unsubscribeFromEnterDown] =
    useEnterDownControlled(onEnterDown ?? keyDownFallback);

  const handleEscapeDown = useCallback(() => {
    if (!onEscapeDown) {
      return;
    }

    if (isActive) {
      subscribeToEscapeDown();
    } else {
      unsubscribeFromEscapeDown();
    }
  }, [isActive, subscribeToEscapeDown, unsubscribeFromEscapeDown]);

  const handleEnterDown = useCallback(() => {
    if (!onEnterDown) {
      return;
    }

    if (isActive) {
      subscribeToEnterDown();
    } else {
      unsubscribeFromEnterDown();
    }
  }, [isActive, subscribeToEnterDown, unsubscribeFromEnterDown]);

  useEffect(handleEscapeDown, [handleEscapeDown]);
  useEffect(handleEnterDown, [handleEnterDown]);

  return (
    <div
      role="button"
      className={cn(
        'flex align-center cursor-pointer',
        isActive && 'text-bright_product',
        className
      )}
      onClick={onToggle}
    >
      {icon}
    </div>
  );
};
