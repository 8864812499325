import {
  CSSProperties,
  FC,
  KeyboardEvent as ReactKeyboardEvent,
  memo,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import { ElementThemeUnion } from 'constants/routes';

import './style.scss';

interface TextAreaProps {
  label?: string;
  value: string;
  readOnly?: boolean;
  onChange?: (e: string) => void;
  onClick?: (e: ReactMouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
  onKeyDown?: (e: ReactKeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (e: ReactKeyboardEvent<HTMLTextAreaElement>) => void;
  autoResize?: boolean;
  rows: number;
  placeholder?: string;
  style?: CSSProperties;
  classNames?: { body?: string; text?: string };
  theme?: ElementThemeUnion;
}

const TextArea: FC<PropsWithChildren<TextAreaProps>> = ({
  label,
  onChange,
  children,
  theme = 'light',
  classNames,
  autoResize,
  ...props
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    onChange?.(e.currentTarget.value);
  };

  useEffect(() => {
    if (autoResize && textAreaRef.current) {
      textAreaRef.current.style.overflowY = 'hidden';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [textAreaRef.current?.scrollHeight]);

  return (
    <label className={cn('textarea', classNames?.body)}>
      {label}
      <div
        className={cn('w-full flex flex-row rounded-md', {
          'bg-dark': theme === 'dark',
          'bg-light': theme === 'light',
        })}
      >
        <textarea
          ref={textAreaRef}
          className={cn(
            'rounded-md',
            {
              'bg-dark': theme === 'dark',
              'bg-light': theme === 'light',
              '!resize-none': autoResize,
            },
            classNames?.text
          )}
          {...props}
          onChange={handleChange}
        />
        {children && (
          <div className="textarea__children icon-container">{children}</div>
        )}
      </div>
    </label>
  );
};

export default memo(TextArea);
