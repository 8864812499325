import { LayerProps } from 'react-map-gl';
import { labelConfig, labelLayout, labelPaint } from 'configs/map/common';

const zoomBasedLayoutConfig = {
  layout: {
    ...labelLayout,
    'text-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      ['literal', [0, 1.6]],
      10,
      ['literal', [0, 1.8]],
      17.5,
      ['literal', [0, 2.4]],
    ],
  },
};

const highlightedLabelPaintConfig = (id?: number) => ({
  paint: {
    ...labelPaint,
    'text-halo-color': [
      'case',
      ['==', ['id'], id ?? null],
      '#053C92',
      '#0A0C17',
    ],
  },
});

export const getPointLabelConfig = (id?: number) =>
  ({
    ...labelConfig,
    ...{
      ...zoomBasedLayoutConfig,
      ...highlightedLabelPaintConfig(id),
    },
  } as LayerProps);

export const getLabelConfig = (id?: number) =>
  ({
    ...labelConfig,
    ...highlightedLabelPaintConfig(id),
  } as LayerProps);

export const getZoomBasedPointConfig = (id?: number) =>
  ({
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['id'], id ?? null],
          'circle-blue-confirmed-active',
          'circle-blue-confirmed',
        ],
        11,
        [
          'case',
          ['==', ['id'], id ?? null],
          'pin-blue-confirmed-active',
          'pin-blue-confirmed',
        ],
        17.5,
        [
          'case',
          ['==', ['id'], id ?? null],
          'radius-blue-active',
          'radius-blue',
        ],
      ],
      'icon-allow-overlap': true,
    },
    filter: ['!', ['has', 'point_count']],
    type: 'symbol',
  } as LayerProps);
