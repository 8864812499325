import { Feature, FeatureCollection, Point, Polygon } from 'geojson';

export type GeocoderFeature =
  | GeocoderPlaceFeature
  | GeocodeCoordinatesFeature
  | GeocodeSquareFeature;

export type GeocoderPlaceFeature<T extends Point | Polygon = Point> = Feature<
  T,
  GeocoderGeoJSONFeatureProperties & {
    subtype: GeocoderFeatureSubtype.PLACE;
  }
>;

export type GeocodeCoordinatesFeature<T extends Point | Polygon = Point> =
  Feature<
    T,
    GeocoderGeoJSONFeatureProperties & {
      subtype: GeocoderFeatureSubtype.COORDINATES;
    }
  >;

export type GeocodeSquareFeature<T extends Point | Polygon = Point> = Feature<
  T,
  GeocoderGeoJSONFeatureProperties & {
    subtype: GeocoderFeatureSubtype.SQUARE;
    feature: Feature<Polygon>;
  }
>;

export enum GeocoderFeatureSubtype {
  PLACE = 'place',
  COORDINATES = 'coordinates',
  SQUARE = 'square',
}

export interface GeocoderGeoJSONFeatureProperties {
  place_id: number;
  osm_type: string;
  osm_id: string;
  name: string;
  display_name: string;
  category: string;
  type: string;
  importance: number;
  place_rank: number;
}

// see https://nominatim.org/release-docs/develop/api/Search/
export interface GeocoderSearchRequest {
  q: string;
  format?: 'xml' | 'json' | 'jsonv2' | 'geojson' | 'geocodejson';
  addressdetails?: 0 | 1;
  polygon_geojson?: 0 | 1;
  limit?: number;
  countrycodes?: string;
  viewbox?: string;
  bounded?: 0 | 1;
}

// see https://nominatim.org/release-docs/develop/api/Output/
export type GeocoderSearchResponse = GeocodeSearchGeoJSONResponse;

export type GeocodeSearchGeoJSONResponse<T extends Point | Polygon = Point> =
  FeatureCollection<T, GeocoderGeoJSONFeatureProperties>;
