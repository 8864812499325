import { convertCoordinates } from 'api/converter';
import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { CoordinateSystems } from 'types';

import { copyToClipboard } from 'utils';
import { formatWgsCoords, formatXYCoords } from 'utils/coordinates';

const getCustomCrsCoords = async (
  targetCrs: CoordinateSystems.SK42 | CoordinateSystems.USK2000,
  latitude: number,
  longitude: number
): Promise<{ x: number; y: number } | undefined> => {
  const data = await convertCoordinates(CoordinateSystems.WGS, targetCrs, {
    b: latitude,
    l: longitude,
    h: DEFAULT_CONVERTER_HEIGHT,
  });

  if (data.to === targetCrs) {
    return { x: data.payload.x, y: data.payload.y };
  }
};

export const copyCoordinates = async (
  coordsMode: CoordinateSystems,
  latitude: number,
  longitude: number
) => {
  if (
    coordsMode === CoordinateSystems.SK42 ||
    coordsMode === CoordinateSystems.USK2000
  ) {
    const convertedCoords = await getCustomCrsCoords(
      coordsMode,
      latitude,
      longitude
    );

    if (convertedCoords) {
      copyToClipboard(formatXYCoords(convertedCoords.x, convertedCoords.y));
    }

    return;
  }

  copyToClipboard(formatWgsCoords(latitude, longitude));
};
