import {
  MapboxDrawConstants,
  MapboxDrawCustomEvents,
  MapboxDrawLib,
  MapboxDrawModes,
} from 'constants/mapDraw';
import { DrawFeatureSubtypes } from 'types';
import {
  DrawCustomModeOverride,
  DrawModeCircleFeature,
  DrawModeCommonFeature,
} from 'types';

import { createSupplementaryPointsForCircle } from './utils';

const SimpleSelectModeOverride = Object.assign(
  {},
  MapboxDrawModes.simple_select
) as DrawCustomModeOverride;

const { createSupplementaryPoints, moveFeatures } = MapboxDrawLib;

// based on https://github.com/iamanvesh/mapbox-gl-draw-circle
SimpleSelectModeOverride.dragMove = function (state, e) {
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };

  moveFeatures(this.getSelected(), delta);

  this.getSelected()
    .filter(
      (feature) => feature?.properties?.subtype === DrawFeatureSubtypes.CIRCLE
    )
    .map((circle) => circle?.properties?.center)
    .forEach((center) => {
      center[0] += delta.lng;
      center[1] += delta.lat;
    });

  state.dragMoveLocation = e.lngLat;

  const features = this.getSelected().map((selected) => selected.toGeoJSON());

  this.map.fire(MapboxDrawCustomEvents.DRAG_MOVE, {
    ...e,
    features,
  });
};

SimpleSelectModeOverride.toDisplayFeatures = function (
  state,
  drawFeature: DrawModeCommonFeature | DrawModeCircleFeature,
  display
) {
  drawFeature.properties.active = this.isSelected(drawFeature.properties.id)
    ? MapboxDrawConstants.activeStates.ACTIVE
    : MapboxDrawConstants.activeStates.INACTIVE;

  display(drawFeature);

  this.fireActionable();

  if (
    drawFeature.properties.active !== MapboxDrawConstants.activeStates.ACTIVE ||
    drawFeature.geometry.type === MapboxDrawConstants.geojsonTypes.POINT
  ) {
    return;
  }

  const supplementaryPoints =
    drawFeature.properties.user_subtype === DrawFeatureSubtypes.CIRCLE
      ? createSupplementaryPointsForCircle(drawFeature)
      : createSupplementaryPoints(drawFeature);

  supplementaryPoints?.forEach(display);
};

export { SimpleSelectModeOverride };
