import { createSlice } from '@reduxjs/toolkit';
import { MapDrawModes } from 'types';

import { TOOLS_REDUCER_NAMES } from '../constants';

import { SetDrawToolAction, StateState } from './types';

const initialState: StateState = {
  drawTool: MapDrawModes.simple_select,
};

const stateSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.STATE,
  initialState: initialState,
  reducers: {
    setDrawTool(state, action: SetDrawToolAction) {
      state.drawTool = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: stateActions, reducer: stateReducer } = stateSlice;
